import Axios from 'axios';
const MAIN_NAMESPACE = "api/action-plans"
const SUB_NAMESPACE = "api/action-plans/"
const SUB_NAMESPACE_2 = "api/action-plans/plan/test"
const SUB_NAMESPACE_3 = "api/action-plans/employee/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_API_URL}`
const SUB_API_URL_2 = `/${SUB_NAMESPACE_2}`
const SUB_API_URL_3 = `/${SUB_NAMESPACE_3}`



class ActionPlanService {

    async getAll() {
        return await Axios.get(MAIN_API_URL, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async getEmployee(id, performance_period) {
        return await Axios.get(SUB_API_URL_3  + `${id}/`+`${performance_period}`, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async getEmployee2(id, performance_period) {
        return await Axios.get(`api/action-plans/employee2/`  + `${id}/`+`${performance_period}`, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }





    async getAllObjectsActionPlansWithArray(){

        return await Axios.get(SUB_API_URL_2 , {}).then((response)=>{
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getOne(id){
        return await Axios.get(SUB_API_URL + `${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getOneById(id){
        return await Axios.get(SUB_API_URL + `show/${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async updateOne(taskObj){
        return Axios.put(SUB_NAMESPACE + taskObj.id, {task_name: taskObj.task_name , task_priority:taskObj.task_priority , due_date:taskObj.due_date , task_status:taskObj.task_status, progress:taskObj.progress}, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
}    

export default new ActionPlanService