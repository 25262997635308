import Axios from 'axios';
const MAIN_NAMESPACE = "api/actuals"
const SUB_NAMESPACE = "api/actuals/"
const SUB_NAMESPACE_2 = "api/actuals-individual/"
const SUB_NAMESPACE_6 = "api/actuals-individual-disapprove/"
const SUB_NAMESPACE_3 = "api/actuals-designation/"
const DISSAPROVE_DESIGNATION = "api/actuals-designation-disapprove/"
const SUB_NAMESPACE_4 = "api/actuals-department/"
const DISAPPROVE_DEPARTMENT = "api/actuals-department-disapprove/"
const SUB_NAMESPACE_5 = "api/actuals-organizational/"
const DISAPPROVE_ORGANZATION = "api/actuals-organizational-dissapprove/"
// const SUB_NAMESPACE_2 = "api/actuals/plan/test"
// const SUB_NAMESPACE_3 = "api/actuals/employee/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const SUB_API_URL_2 = `/${SUB_NAMESPACE_2}`
const SUB_API_URL_6 = `/${SUB_NAMESPACE_6}`
const SUB_API_URL_3 = `/${SUB_NAMESPACE_3}`
const DISSAPROVE_DESIGNATION_URL = `/${DISSAPROVE_DESIGNATION}`
const SUB_API_URL_4 = `/${SUB_NAMESPACE_4}`
const DISAPPROVE_DEPARTMENT_URL = `/${DISAPPROVE_DEPARTMENT}`
const SUB_API_URL_5 = `/${SUB_NAMESPACE_5}`
const DISSAPPROVE_ORGABIZATION_URL = `/${DISAPPROVE_ORGANZATION}`

// const SUB_API_URL_2 = `/${SUB_NAMESPACE_2}`
// const SUB_API_URL_3 = `/${SUB_NAMESPACE_3}`



class ActualService {

    async getAll() {
        return await Axios.get(MAIN_API_URL, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    // async getEmployee(id) {
    //     return await Axios.get(SUB_API_URL_3  + `${id}`, {

    //     }).then((response) => {
    //         return response.data
    //     }).catch((error) => {
    //         return error
    //     });
    // }





    async getAllObjectsActualsWithArray(){

        return await Axios.get(SUB_API_URL , {}).then((response)=>{
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getOne(id, perfomance_period_id){
        return await Axios.get(SUB_API_URL + `${id}`+`/`+`${perfomance_period_id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async getEmployeeOne(id){
        return await Axios.get("api/actuals-employee/" + `${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async getEmployeeOneSubordinate(id){
        return await Axios.get("api/actuals-employee-admin/" + `${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }


    async updateIndividual(id){
        return await Axios.put( "api/actuals/" + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 
    }
    async updatedisapproveIndividual(id){
        return await Axios.put(SUB_API_URL_6  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 
    }
    async updateDesignation(id){
        return await Axios.put(SUB_API_URL_3  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 
    }
  
    async updateDisapprovalDesignation(id){
        return await Axios.put(DISSAPROVE_DESIGNATION_URL  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 
    }
    async updateDepartment(id){
        return await Axios.put(SUB_API_URL_4  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 
    }
    async updateDisapproveDepartment(id){
        return await Axios.put(DISAPPROVE_DEPARTMENT_URL  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 
    }

    async updateOrganizational(id){

        return await Axios.put(SUB_API_URL_5  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 

    }

    async updateDisapproveOrganizational(id){

        return await Axios.put(DISSAPPROVE_ORGABIZATION_URL  + `${id}`, {}).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        }); 

    }
}    

export default new ActualService