import Axios from 'axios';
const MAIN_NAMESPACE = "api/objective"
const SUB_NAMESPACE = "api/objective/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`



class ObjectiveService {

    async getAll() {
        return await Axios.get(MAIN_API_URL, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getOneById(id){
        return await axios.get(SUB_API_URL + `show/${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async createOne(objectiveObj) {
        return Axios.post(API_URL + 'store', objectiveObj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async  updateOne(objective){
        return await Axios.put(SUB_API_URL + `${objective.id}`, objective).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    
    async create(objectiveObj) {
        return Axios.post(MAIN_NAMESPACE, objectiveObj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
}    

export default new ObjectiveService