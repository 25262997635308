<template>
  <div>
    <!-- Add Performance Indicator Modal -->
    <div
      v-show="modalActive"
      id="add_indicator"
      class="modal custom-modal fade"
      role="dialog"
      data-bs-dismiss="modal"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Set New Indicator</h5>
            <button
              type="button"
              @click="$.emit('close')"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Position</label>
                    <select class="select form-control" id="designation">
                      <option disabled selected>Select Position</option>
                      <option
                        v-for="item in designations"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.description }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <h4 class="modal-sub-title">Technical</h4>
                  <div class="form-group">
                    <label class="col-form-label">Customer Experience</label>
                    <select id="customer_experience" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                      <!-- <option>Beginner</option>
													<option>Intermediate</option>
													<option>Advanced</option>
													<option>Expert / Leader</option> -->
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Marketing</label>
                    <select class="select" id="marketing">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Management</label>
                    <select id="management" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Administration</label>
                    <select id="administration" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Presentation Skill</label>
                    <select class="select" id="presentation_skills">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Quality Of Work</label>
                    <select class="select" id="quality_of_work">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Efficiency</label>
                    <select id="efficiency" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-6">
                  <h4 class="modal-sub-title">Organizational</h4>
                  <div class="form-group">
                    <label class="col-form-label">Integrity</label>
                    <select id="integrity" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Professionalism</label>
                    <select id="professionalism" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Team Work</label>
                    <select id="team_work" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Critical Thinking</label>
                    <select id="critical_thinking" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Conflict Management</label>
                    <select id="conflict_management" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Attendance</label>
                    <select id="attendence" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Ability To Meet Deadline</label>
                    <select id="ability_to_meet_deadline" class="select">
                      <option v-for="(item, index) in options" :key="index" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select id="status" class="select">
                      <option v-for="(item, index) in status" :key="index" :value="item">
                        {{ item }}
                      </option>
                      >
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button
                  class="btn btn-primary submit-btn"
                  @click="submitPerformance($event)"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Performance Indicator Modal -->
  </div>
</template>
<script>
import { Vue, toRaw, ref } from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["designations"],

  data() {
    return {
      performance_indicator: {
        designation_id: null,
        customer_experience: "",
        marketing: "",
        management: "",
        administration: "",
        presentation_skill: "",
        qualifty_of_work: "",
        efficiency: "",
        integrity: "",
        professionalism: "",
        team_work: "",
        critical_thinking: "",
        conflict_management: "",
        ability_to_meet_deadline: "",
        status: "active",
      },

      options: ["None", "Beginner", "Intermediate", "Advanced", "Expert/Leader"],
      status: ["active", "Inactive"],
    };
  },
  methods: {
    ...mapActions("performance", ["addPerformanceIndicator"]),

    submitPerformance(e) {
      e.preventDefault();
      console.log("Here we go");
      const performance_indicator = toRaw(this.performance_indicator);

      console.log(performance_indicator);

      this.addPerformanceIndicator(performance_indicator).then(() => {
        // $('#add_indicator').modal('hide');
        this.$.emit("close");
      });
    },
  },
  components: {},

  mounted() {
    //CDrop d
    $("#customer_experience").change(
      function () {
        this.performance_indicator.customer_experience = $("#customer_experience").val();
      }.bind(this)
    );
    $("#marketing").change(
      function () {
        this.performance_indicator.marketing = $("#marketing").val();
      }.bind(this)
    );
    $("#management").change(
      function () {
        this.performance_indicator.management = $("#management").val();
      }.bind(this)
    );
    $("#administration").change(
      function () {
        this.performance_indicator.administration = $("#administration").val();
      }.bind(this)
    );
    $("#efficiency").change(
      function () {
        this.performance_indicator.efficiency = $("#efficiency").val();
      }.bind(this)
    );
    $("#presentation_skills").change(
      function () {
        this.performance_indicator.presentation_skill = $("#presentation_skills").val();
      }.bind(this)
    );
    $("#quality_of_work").change(
      function () {
        this.performance_indicator.qualifty_of_work = $("#quality_of_work").val();
      }.bind(this)
    );
    $("#integrity").change(
      function () {
        this.performance_indicator.integrity = $("#integrity").val();
      }.bind(this)
    );
    $("#professionalism").change(
      function () {
        this.performance_indicator.professionalism = $("#professionalism").val();
      }.bind(this)
    );
    $("#team_work").change(
      function () {
        this.performance_indicator.team_work = $("#team_work").val();
      }.bind(this)
    );
    $("#conflict_management").change(
      function () {
        this.performance_indicator.conflict_management = $("#conflict_management").val();
      }.bind(this)
    );
    $("#attendence").change(
      function () {
        this.performance_indicator.attendence = $("#attendence").val();
      }.bind(this)
    );
    $("#ability_to_meet_deadline").change(
      function () {
        this.performance_indicator.ability_to_meet_deadline = $(
          "#ability_to_meet_deadline"
        ).val();
      }.bind(this)
    );

    $("#critical_thinking").change(
      function () {
        this.performance_indicator.critical_thinking = $("#critical_thinking").val();
      }.bind(this)
    );
    $("#status").change(
      function () {
        this.performance_indicator.status = $("#status").val();
      }.bind(this)
    );
    $("#designation").change(
      function () {
        this.performance_indicator.designation_id = $("#designation").val();
      }.bind(this)
    );

    // Select 2
    if ($(".select").length > 0) {
      $(".select").select2({
        minimumResultsForSearch: -1,
        width: "100%",
      });
    }
  },
};
</script>
