<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-hover table-bordered review-table mb-0 datatable" id="competencies">
          <thead>
            <tr>
              <th>Position</th>
              <th>Job Description</th>
              <th class="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getDesignationComputed" :key="item.id">
              <td>{{ item.description }}</td>
              <td>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="viewModal($event, item)"
                  >View Description</a
                >
              </td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a
                    href="javascript:void(0)"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="material-icons">more_vert</i></a
                  >
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="editModelEvent($event, item)"
                      ><i class="fa fa-pencil m-r-5"></i> Edit</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="$.emit('deleteModal', item)"
                      ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="viewModal($event, item)"
                      ><i class="fa fa-eye m-r-5"></i>view</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import performanceappraisal from '../../../../assets/json/performanceappraisal.json';
import axios from "axios";
import util from "../../../../assets/utils/util";
const images = require.context("../../../../assets/img/profiles", false, /\.png$|\.jpg$/);
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["designations", "performance", "designationCompetencies"],
  data() {
    return {
      performanceappraisal: [],
    };
  },
  created() {},
  methods: {
    ...mapActions("performance", ["setPerformanceIndicator"]),
    ...mapActions("competenciesDesignations", [
      "selectCompetency",
      "getCompetenciesMethod",
    ]),
    editModelEvent(e, item) {
      e.preventDefault();

      // this.setPerformanceIndicator(item);

    
      this.selectCompetency(item).then(() => {
        this.getCompetenciesMethod(item).then(() => {
          this.$emit("editModal", item.id);
        });
      });
    },
    viewModal(e, item) {
      e.preventDefault();
      this.selectCompetency(item).then(() => {
        this.getCompetenciesMethod(item).then(() => {
          this.$emit("viewModal", item.id);
        });
      });
    },
    loadImg(imgPath) {
      return images("./" + imgPath).default;
    },
  },
  computed: {
    ...mapGetters("performance", ["selectedPerformanceIndicator"]),
    getDesignationComputed() {
      let comp = this.designationCompetencies;
      let arrayVal = [];
      comp.map((data) => {
        arrayVal.push(data);
      });

      return arrayVal;
    },
  },
  mounted() {
    util.datatable("#competencies");
  },
};
</script>
