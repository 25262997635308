<template>
  <!-- Page Header -->
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">Perspectives</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/index">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active">Perspectives</li>
        </ul>
      </div>
      <div class="col-auto float-end ms-auto">
        <a href="javascript:void(0)" :hidden=" getPerspective >= 100" @click="displayModal()" class="btn add-btn">
          <i class="fa fa-plus"></i> Add Perspective
        </a>
      </div>
    </div>
  </div>
  <!-- /Page Header -->
</template>
<script>
 import { mapActions, mapGetters } from 'vuex';
export default {
  name : "perspective header",
  computed : { 
    ...mapGetters('perspectives2',['allPerspectives']) ,

    getPerspective(){
              let perspectiveList = this.allPerspectives;

            
              const totalAmount = perspectiveList.reduce((accumulator, item) => {
                    return accumulator + Number(item.weight);
                    }, 0);  

            
              return totalAmount 

             }




  },
  methods:{
            displayModal(){
              console.log("Here")
                this.$.emit('openPerspectiveModal')
            }
  }


 


}

</script>