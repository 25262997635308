import Axios from 'axios';
const MAIN_NAMESPACE = "api/bank"
const SUB_NAMESPACE = "api/bank/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class BankService {  
    
    
    async getOneById(id) {
   
    return await Axios.get(SUB_API_URL + `${id}`, {
    }).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    });
}

async createOne(bankObj) {
    return Axios.post( MAIN_API_URL , bankObj, {

    }).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    });
}
  

}

export default new BankService
