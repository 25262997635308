
import EmergencyService from "../../services/emergency.service";

const state = () => ({
    emergencycontacts: [],
    error: null,
    loading: false,
});

const mutations = {
    SET_CONTACTS(state, list){
        state.emergencycontacts = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state,error){
        state.error = error
    }
};


const actions = {
    
    getbyId({commit},id){
        commit("SET_LOADING", true);
        return EmergencyService.getOneById(id).then(
            async (contactObj) => {
                const res = await Promise.resolve(contactObj);
                commit("SET_LOADING", false);
                commit("SET_CONTACTS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );
    },

    addEmergencyContact({commit},emergencyObject){
        commit("SET_LOADING", true);
        return EmergencyService.addEmergencyContact(emergencyObject).then(
            async(emergency)=>{
                const res = await Promise.resolve(emergency);
                commit("SET_LOADING", false);
                commit("SET_CONTACTS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    }
}

const getters = {
    getAll(state) {
        return state.emergencycontacts;
    },
    loading(state) {
        return state.loading;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
