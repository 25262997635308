import ProfileService  from '../../services/profile.service'

const state = () => ({
    profile: {},
    error: null,
    loading: false,
});


const mutations = {
    SET_EMPLOYEE_PROFILE(state, list) {
        state.profile = list;
    },
    SET_LOADING(state, status) {
        state.loading = status
    },
    SET_ERROR(state,error){
        state.error = error
    }
};

const actions = {
    getAll({ commit }) {
        commit("SET_LOADING", true);
        return ProfileService.getAll().then(
            async (empObj) => {
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", err);
            }
        );
    },

    getOne({commit},id){
        commit("SET_LOADING", true);
        return ProfileService.getOneById(id).then(
            async (empObj) => {
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", err);
            }

        );
    },
    update({commit},profileObj){
        commit("SET_LOADING", true);
        return ProfileService.updateProfile(profileObj).then(
            async (profileObj)=>{
                const res = await Promise.resolve(profileObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", err);
            }

        )
    }
};

const getters = {
    getAll(state) {
        return state.profile;
    },
    getOneProfile(state, id){ //find the profile using Id 
        return state.profile.find(pro => pro.id === id)
    },
    loading(state) {
        return state.loading;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
