import Axios from 'axios';
const MAIN_NAMESPACE = "api/widget"
const SUB_NAMESPACE = "api/widget/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class DashBoardService {

    async getCounter() {
        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

}


export default new DashBoardService