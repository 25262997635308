<template>
  <!-- Add Designation Modal -->
  <div id="add_designation" class="modal custom-modal fade" role="dialog"   data-bs-backdrop="static">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Position</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="onSubmit">
            <div class="form-group">
              <label>Position<span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="description" />
            </div>
            <div class="form-group">
              <label>Department <span class="text-danger">*</span></label>
              <select class="select form-control" v-model="department">
                <option
                  v-for="department in allDepartments"
                  :key="department.id"
                  :value="department"
                >
                  {{ department.description }}
                </option>
              </select>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Designation Modal -->

  <!-- Edit Designation Modal -->
  <!-- <div id="edit_designation" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Designation</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Designation Name <span class="text-danger">*</span></label>
              <input class="form-control" value="Operations Manager" type="text" />
            </div>
            <div class="form-group">
              <label>Designation <span class="text-danger">*</span></label>
              <select class="select">
                <option
                  v-for="item in Designations"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.description }}
                </option>
              </select>
              <v-select
                class="select"
                placeholder="Designation"
                label="Designation"
                :options="Designations"
              ></v-select>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <!-- /Edit Designation Modal -->


</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["designation"],
  data() {
    return {
      description: "",
      department: {},
    };
  },
  methods: {
    ...mapActions(["fetchDepartments", "addDesignation", "fetchDesignations"]),
    onSubmit(e) {
      e.preventDefault();
      const designation = {
        description: this.description,
        department_id: this.department.id,
        department: this.department.description,
      };
      this.addDesignation(designation).then(() => {
        $("#add_designation").modal("hide");
        this.fetchDesignations();
      });
    },
  },
  computed: mapGetters(["allDepartments"]),
  created() {
    this.fetchDepartments();
  },
};
</script>
