import Axios from 'axios';
const MAIN_NAMESPACE = "api/addemployee-family"
// const SUB_NAMESPACE = "api/employee-family/"
const MAIN_NAMESPACE_2 = "api/employee-individual-family"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_2 = `/${MAIN_NAMESPACE_2}`


class FamilyService {
    async addEmployeeFamily(familyObject){
        return await Axios.post(MAIN_API_URL, familyObject).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    
    }

    async  deleteEmployeeFamily(id){

        return await Axios.delete("api/addemployee-family/" + `${id}`).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })
    }


    async getEmployeeFamily(obj){

        return await Axios.post(MAIN_API_URL_2, obj).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    }

}

export default new FamilyService