
import LeaveService from "../../services/leave.service";
const state = () => ({
    leave: [],
    filteredLeave : [],
    leaveSubordinates : [],
    leaveEmployee : [],
    error: null,
    loading: false,
});

const mutations = {

    SET_LEAVES(state, status){
        state.leave = status;
    },
    SET_LEAVE_SUBORDINATES(state, status){
        state.leaveSubordinates = status;
    },
    SET_LEAVE_EMPLOYEE(state, status){
        state.leaveEmployee = status;
    },
    SET_LEAVE_EMPLOYEE_ADD(state, status){
        state.leaveEmployee.push(status);;
    },
    SET_LEAVE(state, status){
        state.leave.push(status);
    },

    SET_LOADING(state, status) {
        state.loading = status;
    },

    SET_ERROR(state,error){
        state.error = error
    },
    UPDATE_LEAVE(state,leaveObj){
        const index = state.leave.findIndex(emp => emp.id === leaveObj.id )
        if (index == -1){
            state.leave.splice(index,1,leaveObj)
         
        }
    },

    SEARCH_FILTERED_LIST(state, search){

        if (!search || search.trim() === "") {
            state.filteredLeave = [];
            return;
          }

        const filteredList = state.leave.filter(leave => {
            return leave.employee.name.toLowerCase().includes(search.toLowerCase()) ||
              leave.employee.surname.toLowerCase().includes(search.toLowerCase()) ||
              leave.reason.toLowerCase().includes(search.toLowerCase()) ||
              leave.start_date.toLowerCase().includes(search.toLowerCase()) ||
              leave.end_date.toLowerCase().includes(search.toLowerCase()) ||
              leave.status.toLowerCase().includes(search.toLowerCase());
          });
        
          state.filteredLeave = filteredList.length > 0 ? filteredList : []; 
    },

    INITIALIZE_FILTER(state){
        state.filteredLeave = []

    }


}
const actions = {

    getAllLeaves({ commit }) {
        commit("SET_LOADING", true);
        return LeaveService.getAllLeaves().then(async (obj)=>{
            const res = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_LEAVES", res);

        },
         async(error)=>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
         }
        )

    },

    getAllLeavesForSubordinates({ commit }, object) {
        commit("SET_LOADING", true);
        return LeaveService.leavSubordinates(object).then(async (obj)=>{
            const res = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_LEAVE_SUBORDINATES", res);

        },
         async(error)=>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
         }
        )

    },

    getAllLeavesForEmployee({ commit }, object) {
        commit("SET_LOADING", true);
        return LeaveService.leaveEmployee(object).then(async (obj)=>{
            const res = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_LEAVE_EMPLOYEE", res);

        },
         async(error)=>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
         }
        )

    },


    addLeave({commit},obj){ 
        return LeaveService.addLeave(obj).then(
            async (response)=>{
                const res = await Promise.resolve(response);
                commit("SET_LOADING", false);
                commit("SET_LEAVE", res);
            },
            async(error)=>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )

},
    addEmployeeLeave({commit},obj){ 
        return LeaveService.addEmployeeLeave(obj).then(
            async (response)=>{
                const res = await Promise.resolve(response);
                commit("SET_LOADING", false);
                commit("SET_LEAVE_EMPLOYEE_ADD", res);
            },
            async(error)=>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )

},
    updateStatus({commit},obj){ 
        return LeaveService.updateStatus(obj).then(
            async (response)=>{
                const res = await Promise.resolve(response);
                commit("SET_LOADING", false);
                commit("UPDATE_LEAVE", res);
            },
            async(error)=>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )

},
searchfilterEmployeeLeaves({commit}, search){
    commit("SET_LOADING", true);
    
    commit("SEARCH_FILTERED_LIST", search);
    commit("SET_LOADING", false);
    

},
initializeFilter({commit}){
    commit('INITIALIZE_FILTER');
},
}

const getters = {
    allLeaves(state) {
        return state.leave;
    },
    allLeavesSubordinates(state) {
        return state.leaveSubordinates;
    }, 
    allLeavesEmployee(state) {
        return state.leaveEmployee;
    }, 
    allFilteredLeaves(state){
        return state.filteredLeave ;
    }

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
