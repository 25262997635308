import Axios from 'axios';
const MAIN_NAMESPACE = "api/objectives-info"
const API_URL_DEPARTMENT = `${MAIN_NAMESPACE}/department`
const API_URL_POSITION = `${MAIN_NAMESPACE}/designation`

class PerspectiveInformationService{

    async getDepartmentObjectiveInfo(id){

        return await Axios.post(API_URL_DEPARTMENT,{objective_id : id}).then((response)=>{
            return response.data
        }).catch((error) => {
            console.log("There was an error updating")
            return error
        });

    }
    async getDesignationObjectiveInfo(id){

        return await Axios.post(API_URL_POSITION,{objective_id : id}).then((response)=>{
            return response.data
        }).catch((error) => {
            console.log("There was an error updating")
            return error
        });

    }
}

export default new PerspectiveInformationService
