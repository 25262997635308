import Axios from 'axios';
const MAIN_NAMESPACE = "api/performance-appraisals"
const SUB_NAMESPACE = "api/performance-appraisal/"
const SUB_NAMESPACE2 = "api/performance-appraisal"
const SUB_NAMESPACE3 = "api/performance-appraisal/competencies"
const SUB_NAMESPACE4 = "api/performance-appraisal/review"
const SUB_NAMESPACE5 = "api/performance-appraisal/reviewed-employees"
const SUB_NAMESPACE6 = "api/performance-appraisal/review-report"
const SUB_NAMESPACE10 = "api/performance-appraisal/review-report-performancemonth"
const SUB_NAMESPACE7 = "api/performance-appraisal/review-report/employee"
const SUB_NAMESPACE8 = "api/performance-appraisal/reviewed-month-employees"
const SUB_NAMESPACE9 = "api/performance-appraisal/review-report/employee/filter"
const SUB_NAMESPACE11 = "api/performance-appraisal/review-report/employee/validate"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const SUB_API_URL2 = `/${SUB_NAMESPACE2}` 
const SUB_API_URL3 = `/${SUB_NAMESPACE3}` 
const SUB_API_URL4 = `/${SUB_NAMESPACE4}` 
const SUB_API_URL5 = `/${SUB_NAMESPACE5}` 
const SUB_API_URL6 = `/${SUB_NAMESPACE6}` 
const SUB_API_URL7 = `/${SUB_NAMESPACE7}` 
const SUB_API_URL8 = `/${SUB_NAMESPACE8}` 
const SUB_API_URL9 = `/${SUB_NAMESPACE9}` 
const SUB_API_URL10 = `/${SUB_NAMESPACE10}` 
const SUB_API_URL11= `/${SUB_NAMESPACE11}` 

class PerformanceAppraisalService {  

    async getPerformanceAppraisal() {
    
        return await Axios.get(MAIN_API_URL ,{}).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });


    }


  async addPerformanceAppraisal(appraisal){
    return await Axios.post(SUB_API_URL2 ,appraisal).then((response)=>{
        return response.data
    }).catch((error)=>{
        return error
    });


  }


 async getPerformanceAppraisalWithCompetency(obj){
      
    return await Axios.post(SUB_API_URL3 ,obj).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    });

}

async addPerformanceReview(obj){

    return await Axios.post(SUB_API_URL4 ,obj).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    });

}

async getAllReviewedEmployees(){
    return await Axios.post(SUB_API_URL5,{}).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    });

}
async getAllReviewedEmployeesFilter(obj){
    return await Axios.post(SUB_API_URL8,obj).then((response) => {
        return response.data
    }).catch((error) => {
        return error
    });

}

async getEmployeeReviewReport(obj){
    return await Axios.post(SUB_API_URL6, obj).then((response) =>{
        return response.data
    }).catch((error)=>{
        return error
    })
}
async getEmployeeReviewReportWithPerformancePeriod(obj){
    return await Axios.post(SUB_API_URL10, obj).then((response) =>{
        return response.data
    }).catch((error)=>{
        return error
    })
}
async getEmployeeReviewReportViaEmail(obj){
    return await Axios.post(SUB_API_URL7, obj).then((response) =>{
        return response.data
    }).catch((error)=>{
        return error
    })
}
async getEmployeeReviewReportViaEmailwithFilter(obj){
    return await Axios.post(SUB_API_URL9, obj).then((response) =>{
        return response.data
    }).catch((error)=>{
        return error
    })
}

}



export default new  PerformanceAppraisalService 