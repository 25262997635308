import ReportsToService  from "../../services/reportsto.service";

const state = () => ({
    subordinates: [],
    superviser: {},
    error: null,
    loading: false,
});

const mutations = {


    SET_SUBORDINATES(state, list) {
        state.subordinates = list;
    },
    SET_SUPERVISER(state, object) {
        state.superviser = object;
    },
    SET_LOADING(state, status) {
        state.companyinfo = status
    },
    SET_ERROR(state,err){
        state.error = err
    }
}
const actions = {

    getSubordinates({ commit} , email){
        commit("SET_LOADING", true);
        return ReportsToService.getAllSsubOrdinates(email).then(async (depObj) => {
            const resp = await Promise.resolve(depObj);
            commit("SET_SUBORDINATES", resp);
            commit("SET_LOADING", false);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        );
    },

    getSuperviser({ commit} , id){
        commit("SET_LOADING", true);
        return ReportsToService.getSuperviser(id).then(async (depObj) => {
            const resp = await Promise.resolve(depObj);
            commit("SET_SUPERVISER", resp);
            commit("SET_LOADING", false);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        );
    }
}
const getters = {
    allSubOrdinates(state) {
        return state.subordinates;
    },
    Superviser(state) {
        return state.superviser;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
