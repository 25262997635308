
import EmployeeService from "../../services/employee.service";
import ProfileService from "../../services/profile.service";
import EmergencyService from "../../services/emergency.service";
import BankService from "../../services/banking.service";
import FamilyService from "../../services/family.service";
import ScoreCardService from "../../services/scorecard.service";
import DepartmentGoalsService from "../../services/departmentgoals.service";
import DesignationGoalsService from "../../services/designationgoals.service";
import EmployeeScoreCardService from "../../services/employeescorecard.service";

const state = {
    employees: [],
    filteredEmployees: [],
    emergencycontacts: [],
    familyMembers : [],
    employee : {},
    personalInfo : {},
    bankingDetails : {},
    ScorecardObjective : [],
    DepartmentGoalsObjective : [],
    DesignationGoalsObjective : [],
    employeeScorecard : [],
    error: null,
    loading: false,
};

const mutations = {
    SET_EMPLOYEES(state, list){
        state.employees = list;
    },
    SET_EMPLOYEE(state, obj){
        state.employee = obj;
        
    },
    SET_EMPLOYEE_PROFILE(state,obj){
        state.personalInfo = obj ;
    },

    UPDATE_EMPLOYEE(state,updatedEmployee){
        const index = state.employees.findIndex(emp => emp.id === updatedEmployee.id )
        if (index == -1){
            state.employees.splice(index,1,updatedEmployee)
            state.employee = updatedEmployee;
        }
    },

    SET_EMERGENCY_CONTACT(state,list){
        state.emergencycontacts = list
    }
    ,
    SET_BANK(state,obj){
        state.bankingDetails = obj
    }
    ,
    SET_SCORECARD(state,list){
        state.ScorecardObjective = list
    }
    ,
    SET_DEPARTMENTGOALS(state,list){
        state.DepartmentGoalsObjective = list
    }
    ,
    SET_DESIGNATIONGOALS(state,list){
        state.DesignationGoalsObjective = list
    }
    ,
    SET_EMPLOYEESCORECARD(state,list){
        state.employeeScorecard = list
    }
    ,
    SET_LOADING(state, status){
        state.loading = status;
    },
    
    SET_FAMILY(state,list){
        state.familyInfo = list;
    }
    ,
    SET_ERROR(state,error){
        state.error = error
    },
    SET_FILTERED_LIST(state, designation_id){
            const filteredList = state.employees.filter(obj =>{
                return obj.designation_id == designation_id
            })
            state.filteredEmployees = filteredList 
    },

    SEARCH_FILTERED_LIST(state, search){

        const filteredList  = state.employees.filter(employee =>{
          return  employee.name.toLowerCase().includes(search.toLowerCase()) || employee.surname.toLowerCase().includes(search.toLowerCase()) 
        })
        state.filteredEmployees = filteredList 
    },

    INITIALIZE_FILTER(state){
        state.filteredEmployees = []

    }
    
}

const actions = {
    getEmployees({ commit }) {
        return EmployeeService.getAll().then(
            async(empObj)=>{
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEES", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    },

    getEmployeesDepartmentId({ commit }, id) {
        return EmployeeService.getAllDepartmentFilter(id).then(
            async(empObj)=>{
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEES", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    },





    filterEmployeeOnDesignation({commit}, desigation_id){
        commit("SET_LOADING", true);
        
        commit("SET_FILTERED_LIST", desigation_id);
        commit("SET_LOADING", false);
        

    }
    ,
    searchfilterEmployeeOnDesignation({commit}, search){
        commit("SET_LOADING", true);
        
        commit("SEARCH_FILTERED_LIST", search);
        commit("SET_LOADING", false);
        

    }
    ,

    initializeFilter({commit}){
        commit('INITIALIZE_FILTER');
    },

    getOne({commit},id){
        commit("SET_LOADING", true);
        return EmployeeService.getOne(id).then(
            async (empObj) => {
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );
    },
    
    deleteEmployee({commit}, id){

        commit("SET_LOADING", true);
        return EmployeeService.delete(id).then(
            async (empObj) => {
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );
    }
    ,

    UpdateEmployee({commit},empObj){
        commit("SET_LOADING", true);
        return EmployeeService.update(empObj).then(
            async (empObj) => {
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("UPDATE_EMPLOYEE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    }
    ,
 

    getEmployeePersonalInfo({commit},id){
        commit("SET_LOADING", true);
        return ProfileService.getOneById(id).then(
            async (empObj) => {
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_Error", err);
            }

        );

    },
    addEmployeePersonalInfo({commit},personalInfo){ 
            return ProfileService.createOne(personalInfo).then(
                async (empObj)=>{
                    const res = await Promise.resolve(empObj);
                    commit("SET_LOADING", false);
                    commit("SET_EMPLOYEE_PROFILE", res);
                },
                async(error)=>{
                    const err = await Promise.reject(error);
                    commit("SET_LOADING", false);
                    commit("SET_ERROR", err);
                }
            )

    },
    addEmployeePersonalInfoWithoutDocument({commit},personalInfo){ 
            return ProfileService.createOneNoDocument(personalInfo).then(
                async (empObj)=>{
                    const res = await Promise.resolve(empObj);
                    commit("SET_LOADING", false);
                    commit("SET_EMPLOYEE_PROFILE", res);
                },
                async(error)=>{
                    const err = await Promise.reject(error);
                    commit("SET_LOADING", false);
                    commit("SET_ERROR", err);
                }
            )

    }
    ,
    updateEmployeePersonalInfo({commit},personalInfo){
        return ProfileService.update(personalInfo).then(
            async (empObj)=>{
                const res = await Promise.resolve(empObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_PROFILE", res);
            },
            async(error)=>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        ) 

    }

    ,

    
    addPersonalInfo({commit}){
        
    }
    ,


    getEmergencyContact({commit},id){
        commit("SET_LOADING", true);
        return EmergencyService.getOneById(id).then(
            async (contactObj) => {
                const res = await Promise.resolve(contactObj);
                commit("SET_LOADING", false);
                commit("SET_EMERGENCY_CONTACT", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );
    },

     addEmergencyContact({commit},emergencyObject){
        commit("SET_LOADING", true);
        return EmergencyService.addEmergencyContact(emergencyObject).then(
            async(emergency)=>{
                const res = await Promise.resolve(emergency);
                commit("SET_LOADING", false);
                commit("SET_EMERGENCY_CONTACT", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    }
    ,


    //Add Banking details

    getBankingDetails({commit},id){
        commit("SET_LOADING", true);
        return BankService.getOneById(id).then(
            async(bankingDetails)=>{
                const res = await Promise.resolve(bankingDetails);
                commit("SET_LOADING", false);
                commit("SET_BANK", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    addBankingDetails({commit},Bankobj){
        commit("SET_LOADING", true);
        return BankService.createOne(Bankobj).then(
            async(bankingDetails)=>{
                const res = await Promise.resolve(bankingDetails);
                commit("SET_LOADING", false);
                commit("SET_BANK", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    
    },


 //Add Scorecard details

 getScorecardObjective({commit},id){
    commit("SET_LOADING", true);
    return ScoreCardService.getOneById(id).then(
        async(ScorecardObjective)=>{
            const res = await Promise.resolve(ScorecardObjective);
            commit("SET_LOADING", false);
            commit("SET_SCORECARD", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
 
    )
},

//Add Department Scorecard details

getDepartmentScorecardObjective({commit},id){
    commit("SET_LOADING", true);
    return DepartmentGoalsService.getOneById(id).then(
        async(DepartmentGoalsObjective)=>{
            const res = await Promise.resolve(DepartmentGoalsObjective);
            commit("SET_LOADING", false);
            commit("SET_DEPARTMENTGOALS", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
 
    )
},
//Add Designation Scorecard details

getDesignationScorecardObjective({commit},id){
    commit("SET_LOADING", true);
    return DesignationGoalsService.getOneById(id).then(
        async(DesignationGoalsObjective)=>{
            const res = await Promise.resolve(DesignationGoalsObjective);
            commit("SET_LOADING", false);
            commit("SET_DESIGNATIONGOALS", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
 
    )
},



 //Add Employee Scorecard details

 getEmployeeScorecard({commit},id){
    commit("SET_LOADING", true);
    return EmployeeScoreCardService.getOneById(id).then(
        async(employeeScorecard)=>{
            const res = await Promise.resolve(employeeScorecard);
            commit("SET_LOADING", false);
            commit("SET_EMPLOYEESCORECARD", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }

    )
},



    //Family

    getEmployeeFamilyInfo({commit},id){
        commit("SET_LOADING", true);
        return FamilyService.getOneById(id).then(
            async(familyDetails)=>{
                const res = await Promise.resolve(familyDetails);
                commit("SET_LOADING", false);
                commit("SET_FAMILY", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },

    addEmployeeFamilyInfo({commit},familyInfo){ 
        return FamilyService.createOne(familyInfo).then(
            async (familyDetails)=>{
                const res = await Promise.resolve(familyDetails);
                commit("SET_LOADING", false);
                commit("SET_FAMILY", res);
            },
            async(error)=>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )

}
,

updateEmployeeFamilyInfo({commit},familyInfo){
    return FamilyService.update(familyInfo).then(
        async (familyDetails)=>{
            const res = await Promise.resolve(familyDetails);
            commit("SET_LOADING", false);
            commit("SET_FAMILY", res);
        },
        async(error)=>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
    ) 

}

,

}

const getters = {
    allEmployees(state) {
        return state.employees;
    },
    Employee(state, id){ //find the profile using Id 
        // return state.employees.find(pro => pro.id === id)
        return state.employee
    },
    personalInfo(state){
        return state.personalInfo;
    },
    emergencyContact(state){
        return state.emergencycontacts;
    },
    bankDetails(state){
        return state.bankingDetails;
    },
    scoreCards(state){
        return state.ScorecardObjective;
    },
    departmentGoals(state){
        return state.DepartmentGoalsObjective;
    },
    designationGoals(state){
        return state.DesignationGoalsObjective;
    },
    employeeScoreCards(state){
        return state.employeeScorecard ;
    },
    familyInfo(state){
        return state.familyInfo ;
    },

    allFilteredEmployees(state){
            return state.filteredEmployees
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};