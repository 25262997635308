import Axios from 'axios';
const MAIN_NAMESPACE = "api/status"
const SUB_NAMESPACE = "api/status/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class StatusService {

    async getCounterStatus() {
        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

}


export default new StatusService