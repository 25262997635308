<template>
    <div id="app">
         <router-view />
    </div>
</template>


<script>
import { onMounted } from 'vue'


export default {
    setup() {
        onMounted(() => {
        
        })
    }
}
</script>