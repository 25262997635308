// objectives module
import axios from 'axios';

const resource_uri = "api/objective/";

const state = {
    objectives: []
};

const mutations = {
    setObjectives: (state, objectives) => state.objectives = objectives,
    //newObjective: (state, objective) => state.objectives.push(objective),
    updObjective: (state, updatedObjective) => {
        const index = state.objectives.findIndex(t => t.id === updatedObjective.id);
        if(index !== -1) {
            state.objectives.splice(index, 1, updatedObjective);
        }        
    },
    deleteObjective: (state, objective) => state.objectives = state.objectives.filter(t => objective.id !== t.id),
};

const actions = {
    async fetchObjectives({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setObjectives', response.data);
    },
    async addObjective( { commit }, objective) {
        const response = await axios.post(resource_uri, objective);
        commit('newObjective', response.data.objective);
    },
    async updateObjective( { commit }, objective) {
        const response = await axios.put(`${resource_uri}${objective.id}`, objective);
        commit('updObjective', response.data);
    },
    async removeObjective( { commit }, objective) {
        const response = await axios.delete(`${resource_uri}${objective.id}`);
        commit('deleteObjective', response);
    }
};

const getters = {
    allObjectives: state => state.objectives,
    depObjectives: state => state.objectives,
    desObjectives: state => state.objectives
};

export default {
    namespaced: true,
    state, 
    getters, 
    actions, 
    mutations
}
