import Axios from 'axios';
const MAIN_NAMESPACE = "api/perspective"
const SUB_NAMESPACE = "api/perspective/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class PerspectiveService {
    async getAllPerspectives() {
        return await Axios.get(MAIN_API_URL, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async getOneById(id){
        return await axios.get(SUB_API_URL + `show/${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async createOne(perspectiveObj) {
        return Axios.post(API_URL + 'store', perspectiveObj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async updateOne(perspectiveObj){
        return Axios.put(SUB_NAMESPACE + perspectiveObj.id,{name: perspectiveObj.name , weight:perspectiveObj.weight}, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    
    async create(perspectiveObj) {
        return Axios.post(MAIN_NAMESPACE, perspectiveObj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }


    async deletePerspective(obj){
        return await Axios.delete(SUB_API_URL +`${obj.id}`, ).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }


}
export default new PerspectiveService