import Axios from 'axios';

const MAIN_NAMESPACE = "api/login"
const SUB_NAMESPACE = "api/login/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class AuthService {

    async login(obj){
        return await Axios.post(MAIN_API_URL , obj).then((response) => {

            let data = response.data;
            if(response.data.user){
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data
        }).catch((error) => {
            // console.log("There was an error", error.response.data)
            return error.response.data
        });

    }

    async logout(token){

        let TheUser = JSON.parse(localStorage.getItem('user'));
        return Axios.post('api/logout',{},{headers:{'Authorization': `Bearer ${TheUser.token}`}}).then((response)=>{
            localStorage.removeItem('user');
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });
    }

}

export default new AuthService