import DashBoardService from "../../services/dashboard.service";

const state = () => ({
    counter: [],
})



const mutations = {
    SET_COUNTER(state, list){
        state.counter = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state, err) {
        state.error = err;
    },

}


const actions = {
    getAllCounters({commit}){
        commit("SET_COUNTER", true);
        return DashBoardService.getCounter().then(async (compObj)=>{

            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            commit("SET_COUNTER", resp);   
        },

        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        
        )

    },
}

const getters = {
    getCounter(state) {
        return state.counter;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};