<template>
  <div class="tab-content">
    <!-- Profile Info Tab -->
    <div id="emp_profile" class="pro-overview tab-pane fade show active">
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body">
              <h3 class="card-title">
                Personal Informations
                <a class="edit-icon" @click="toggleModal()"><i class="fa fa-pencil"></i></a>
              </h3>

              <div v-if="
                personalInfo.passport_no == null 
              ">
                Nothing to show
              </div>
              <ul v-else class="personal-info">
                <li>
                  <div class="title">ID No:</div>
                  <div class="text">{{ personalInfo.passport_no }}</div>
                </li>
                <!-- <li>
                  <div class="title">Passport Exp Date</div>
                  <div class="text">{{ personalInfo.passport_expry_date }}</div>
                </li> -->

                <li>
                  <div class="title">Nationality:</div>
                  <div class="text">{{ personalInfo.nationality }}</div>
                </li>

                <li>
                  <div class="title">Marital Status:</div>
                  <div class="text" v-if="personalInfo.martital_status != null">
                    {{ personalInfo.martital_status }}
                  </div>
                </li>
                <li>
                  <div class="title">Identity Document</div>
                  <div v-if="personalInfo.document_path !== ''">
                  
                    <a class="btn btn-light-download" @click="downloadIdentity($event)">Download</a>
                  </div>
                  <div v-else>
                    <span> No ID document found </span>
                  </div>

                </li>

                <li>
                  <div class="title">Children:</div>
                  <div class="text">{{ personalInfo.no_of_children }}</div>
                </li>
                <li>
                  <div class="title">Tel:</div>
                  <div class="text">{{ personalInfo.tel }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="col-md-6 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body">
              <h3 class="card-title">
                Bank information
                <a class="edit-icon" @click="toggleBankModal()"><i class="fa fa-pencil"></i></a>
              </h3>
              <ul class="personal-info">
                <li>
                  <div class="title">Bank name</div>
                  <div class="text">{{ bankDetails.bank_name }}</div>
                </li>
                <li>
                  <div class="title">Bank account No.</div>
                  <div class="text">{{ bankDetails.bank_account }}</div>
                </li>
                <li>
                  <div class="title">Branch</div>
                  <div class="text">{{ bankDetails.ifsc_code }}</div>
                </li>
                <li>
                  <div class="title">Account Type</div>
                  <div class="text">{{ bankDetails.pan_no }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

    

      </div>


      <div class="row">
       
        <div class="col-md-12 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body">
              <h3 class="card-title">
                Family Informations
                <a class="edit-icon" @click="toggleFamilyModal()"><i class="fa fa-pencil"></i></a>
              </h3>
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Relationship</th>
                      <th>Date of Birth</th>
                      <th>Phone</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>


                    <div v-if="getfamilyInfo.length == 0 &&
                      familyInfo.phone == null">
                      <tr>
                        <td>No Family Members Added</td>
                      </tr>
                    </div>

                    <tr v-for="item in getfamilyInfo" :key="item.id">
                      <td>{{ item.name }}</td>
                      <td>{{ item.relationship }}</td>
                      <td>{{ item.date_of_birth }}</td>
                      <td>{{ item.phone }}</td>
                      <td class="text-end">
                        <div class="dropdown dropdown-action">
                          <a aria-expanded="false" data-bs-toggle="dropdown" class="action-icon dropdown-toggle"
                            href="javascript:void(0)"><i class="material-icons">more_vert</i></a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <!-- <a href="javascript:void(0)" class="dropdown-item"><i class="fa fa-pencil m-r-5"></i> Edit</a> -->
                            <a href="javascript:void(0)" @click="deleteFamilyMember(item)" class="dropdown-item"><i class="fa fa-trash-o m-r-5"></i>
                              Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body">
              <h3 class="card-title">
                Education Informations
                <a href="javascript:void(0)" class="edit-icon" @click="toggleModalEduction()"><i
                    class="fa fa-pencil"></i></a>
              </h3>
              <div class="experience-box">
                <ul class="experience-list">
                  <li v-for="(item, index) in getEductionStuff " :key="index">
                    <div class="experience-user">
                      <div class="before-circle"></div>
                    </div>
                    <div class="experience-content">
                      <div class="timeline-content">
                        <a href="#/" class="name">{{ item.institution }}</a>
                        <div>{{ item.qualification }}</div>
                        <span class="time">{{ item.start_date }} - {{ item.end_date }}</span>
                        <a href="javascript:void(0)" @click="downloadQualification($event,item)">Download Document</a>
                      </div>
                    </div>
                   
                  </li>
                  <!-- <li>
                    <div class="experience-user">
                      <div class="before-circle"></div>
                    </div>
                    <div class="experience-content">
                      <div class="timeline-content">
                        <a href="#/" class="name"
                          >International College of Arts and Science (UG)</a
                        >
                        <div>Bsc Computer Science</div>
                        <span class="time">2000 - 2003</span>
                      </div>
                    </div>
                  </li> -->
                  <!-- <li>
                    <div class="experience-user">
                      <div class="before-circle"></div>
                    </div>
                    <div class="experience-content">
                      <div class="timeline-content">
                        <a href="#/" class="name"
                          >International College of Arts and Science (PG)</a
                        >
                        <div>Msc Computer Science</div>
                        <span class="time">2000 - 2003</span>
                      </div>
                    </div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-flex">
          <div class="card profile-box flex-fill">
            <div class="card-body">
              <h3 class="card-title">
                Experience
                <a href="javascript:void(0)" class="edit-icon" @click="togglemodalExperiences()"><i
                    class="fa fa-pencil"></i></a>
              </h3>
              <div class="experience-box">
                <ul class="experience-list">
                  <li v-for="(item, index) in getExperience " :key="index">
                    <div class="experience-user">
                      <div class="before-circle"></div>
                    </div>
                    <div class="experience-content">
                      <div class="timeline-content">
                        <a href="#/" class="name">{{ item.position }} at {{ item.company }}</a>
                        <div>{{ item.country }}</div>
                        <span class="time">{{ item.startdate }} - {{ item.enddate }}</span>
                      </div>
                    </div>
                  </li>
                  <!-- <li>
                    <div class="experience-user">
                      <div class="before-circle"></div>
                    </div>
                    <div class="experience-content">
                      <div class="timeline-content">
                        <a href="#/" class="name"
                          >International College of Arts and Science (UG)</a
                        >
                        <div>Bsc Computer Science</div>
                        <span class="time">2000 - 2003</span>
                      </div>
                    </div>
                  </li> -->
                  <!-- <li>
                    <div class="experience-user">
                      <div class="before-circle"></div>
                    </div>
                    <div class="experience-content">
                      <div class="timeline-content">
                        <a href="#/" class="name"
                          >International College of Arts and Science (PG)</a
                        >
                        <div>Msc Computer Science</div>
                        <span class="time">2000 - 2003</span>
                      </div>
                    </div>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Profile Info Tab -->

    <!-- Projects Tab -->
    <div class="tab-pane fade" id="emp_projects">
      <div class="row">
        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown profile-action">
                <a aria-expanded="false" data-bs-toggle="dropdown" class="action-icon dropdown-toggle"
                  href="javascript:void(0)"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a data-bs-target="#edit_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                  <a data-bs-target="#delete_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                </div>
              </div>
              <h4 class="project-title">
                <router-link to="/project-view">AHF Project</router-link>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">1</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">9</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                When an unknown printer took a galley of type and scrambled it...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">15 Dec 2022</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Manager :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Nombulelo Simelane"><img alt=""
                        src="../../../assets/img/profiles/avatar-16.jpg" /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Ayanda Khumalo"><img alt=""
                        src="../../../assets/img/profiles/avatar-02.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Charles Nkuna"><img alt=""
                        src="../../../assets/img/profiles/avatar-09.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="John Smith"><img alt=""
                        src="../../../assets/img/profiles/avatar-10.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Mike Litorus"><img alt=""
                        src="../../../assets/img/profiles/avatar-05.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="all-users">+15</a>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">40%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div style="width: 40%" title="" data-bs-toggle="tooltip" role="progressbar"
                  class="progress-bar bg-success" data-original-title="40%"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown profile-action">
                <a aria-expanded="false" data-bs-toggle="dropdown" class="action-icon dropdown-toggle"
                  href="javascript:void(0)"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a data-bs-target="#edit_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                  <a data-bs-target="#delete_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                </div>
              </div>
              <h4 class="project-title">
                <router-link to="/project-view">Buhleni Palace Installation</router-link>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">2</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">5</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                When an unknown printer took a galley of type and scrambled it...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">15 Dec 2022</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Manager :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Nombulelo Simelane"><img alt=""
                        src="../../../assets/img/profiles/avatar-16.jpg" /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Ayanda Khumalo"><img alt=""
                        src="../../../assets/img/profiles/avatar-02.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Charles Nkuna"><img alt=""
                        src="../../../assets/img/profiles/avatar-09.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="John Smith"><img alt=""
                        src="../../../assets/img/profiles/avatar-10.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Mike Litorus"><img alt=""
                        src="../../../assets/img/profiles/avatar-05.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="all-users">+15</a>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">40%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div style="width: 40%" title="" data-bs-toggle="tooltip" role="progressbar"
                  class="progress-bar bg-success" data-original-title="40%"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown profile-action">
                <a aria-expanded="false" data-bs-toggle="dropdown" class="action-icon dropdown-toggle"
                  href="javascript:void(0)"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a data-bs-target="#edit_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                  <a data-bs-target="#delete_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                </div>
              </div>
              <h4 class="project-title">
                <router-link to="/project-view">ICC Installation</router-link>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">3</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">3</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                When an unknown printer took a galley of type and scrambled it...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">15 Dec 2022</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Manager :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Nombulelo Simelane"><img alt=""
                        src="../../../assets/img/profiles/avatar-16.jpg" /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Ayanda Khumalo"><img alt=""
                        src="../../../assets/img/profiles/avatar-02.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Charles Nkuna"><img alt=""
                        src="../../../assets/img/profiles/avatar-09.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="John Smith"><img alt=""
                        src="../../../assets/img/profiles/avatar-10.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Mike Litorus"><img alt=""
                        src="../../../assets/img/profiles/avatar-05.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="all-users">+15</a>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">40%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div style="width: 40%" title="" data-bs-toggle="tooltip" role="progressbar"
                  class="progress-bar bg-success" data-original-title="40%"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="dropdown profile-action">
                <a aria-expanded="false" data-bs-toggle="dropdown" class="action-icon dropdown-toggle"
                  href="javascript:void(0)"><i class="material-icons">more_vert</i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a data-bs-target="#edit_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-pencil m-r-5"></i> Edit</a>
                  <a data-bs-target="#delete_project" data-bs-toggle="modal" href="javascript:void(0)"
                    class="dropdown-item"><i class="fa fa-trash-o m-r-5"></i> Delete</a>
                </div>
              </div>
              <h4 class="project-title">
                <router-link to="/project-view">ESCCOM Installation</router-link>
              </h4>
              <small class="block text-ellipsis m-b-15">
                <span class="text-xs">12</span>
                <span class="text-muted">open tasks, </span>
                <span class="text-xs">4</span>
                <span class="text-muted">tasks completed</span>
              </small>
              <p class="text-muted">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                When an unknown printer took a galley of type and scrambled it...
              </p>
              <div class="pro-deadline m-b-15">
                <div class="sub-title">Deadline:</div>
                <div class="text-muted">15 Dec 2022</div>
              </div>
              <div class="project-members m-b-15">
                <div>Project Manager :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Nombulelo Simelane"><img alt=""
                        src="../../../assets/img/profiles/avatar-16.jpg" /></a>
                  </li>
                </ul>
              </div>
              <div class="project-members m-b-15">
                <div>Team :</div>
                <ul class="team-members">
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Ayanda Khumalo"><img alt=""
                        src="../../../assets/img/profiles/avatar-02.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Charles Nkuna"><img alt=""
                        src="../../../assets/img/profiles/avatar-09.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="John Smith"><img alt=""
                        src="../../../assets/img/profiles/avatar-10.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" data-bs-toggle="tooltip" title="Mike Litorus"><img alt=""
                        src="../../../assets/img/profiles/avatar-05.jpg" /></a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="all-users">+15</a>
                  </li>
                </ul>
              </div>
              <p class="m-b-5">
                Progress <span class="text-success float-end">40%</span>
              </p>
              <div class="progress progress-xs mb-0">
                <div style="width: 40%" title="" data-bs-toggle="tooltip" role="progressbar"
                  class="progress-bar bg-success" data-original-title="40%"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Projects Tab -->

    <!-- Bank Statutory Tab -->
    <div class="tab-pane fade" id="bank_statutory">
      <div class="card">
        <div class="card-body">
          <h3 class="card-title">Basic Salary Information</h3>
          <form>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Salary basis <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Select salary basis type</option>
                    <option>Hourly</option>
                    <option>Daily</option>
                    <option>Weekly</option>
                    <option>Monthly</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Salary amount <small class="text-muted">per month</small></label>
                  <div class="input-group">
                    <span class="input-group-text">$</span>
                    <input type="text" class="form-control" placeholder="Type your salary amount" value="0.00" />
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Payment type</label>
                  <select class="select">
                    <option>Select payment type</option>
                    <option>Bank transfer</option>
                    <option>Check</option>
                    <option>Cash</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
            <h3 class="card-title">PF Information</h3>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">PF contribution</label>
                  <select class="select">
                    <option>Select PF contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">PF No. <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Select PF contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Employee PF rate</label>
                  <select class="select">
                    <option>Select PF contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Additional rate <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Select additional rate</option>
                    <option>0%</option>
                    <option>1%</option>
                    <option>2%</option>
                    <option>3%</option>
                    <option>4%</option>
                    <option>5%</option>
                    <option>6%</option>
                    <option>7%</option>
                    <option>8%</option>
                    <option>9%</option>
                    <option>10%</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Total rate</label>
                  <input type="text" class="form-control" placeholder="N/A" value="11%" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Employee PF rate</label>
                  <select class="select">
                    <option>Select PF contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Additional rate <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Select additional rate</option>
                    <option>0%</option>
                    <option>1%</option>
                    <option>2%</option>
                    <option>3%</option>
                    <option>4%</option>
                    <option>5%</option>
                    <option>6%</option>
                    <option>7%</option>
                    <option>8%</option>
                    <option>9%</option>
                    <option>10%</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Total rate</label>
                  <input type="text" class="form-control" placeholder="N/A" value="11%" />
                </div>
              </div>
            </div>

            <hr />
            <h3 class="card-title">ESI Information</h3>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">ESI contribution</label>
                  <select class="select">
                    <option>Select ESI contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">ESI No. <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Select ESI contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Employee ESI rate</label>
                  <select class="select">
                    <option>Select ESI contribution</option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Additional rate <span class="text-danger">*</span></label>
                  <select class="select">
                    <option>Select additional rate</option>
                    <option>0%</option>
                    <option>1%</option>
                    <option>2%</option>
                    <option>3%</option>
                    <option>4%</option>
                    <option>5%</option>
                    <option>6%</option>
                    <option>7%</option>
                    <option>8%</option>
                    <option>9%</option>
                    <option>10%</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Total rate</label>
                  <input type="text" class="form-control" placeholder="N/A" value="11%" />
                </div>
              </div>
            </div>

            <div class="submit-section">
              <button class="btn btn-primary submit-btn" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- /Bank Statutory Tab -->
    <modalpersonalInfo @close="toggleModal" :profile="profile" :personalInfo="personalInfo" :modalActive="modalActive"
      :employeeId="employeeId" />
    <modalEmergencyInfo @closeEmergency="toggleEmergencyModal" :modal-active="toggleEmergencyModal"
      :emergencycontacts="emergencycontacts" :profile="profile" />
    <modalFamilyInfo @closeFamily="toggleFamilyModal" :modal-active="toggleFamilyModal" :employeeId="employeeId" />
    <modalBank @closeBankModal="toggleBankModal()" />
    <modalEducation :modalActive="modalEducationActive" @closeEduction="toggleModalEduction" :employeeId="employeeId" />

    <!-- experince -->
    <modalExperiences :modalActive="modalExperiencesActive" 
    @closeExperience="togglemodalExperiences"
      :employeeId="employeeId"
      :expObj="actualData" />
  </div>
</template>
<script>
import { computed, defineProps, toRefs, onMounted, ref, toRaw } from "vue";
import { useStore, mapGetters, mapActions } from "vuex";
import modalpersonalInfo from "./modalpersonalInfo.vue";
import modalEmergencyInfo from "./modalEmergencyInfo.vue";
import modalFamilyInfo from "./familyInfo.vue";
import modalBank from "./modalbank.vue";
import modalEducation from "./modalEducation.vue";
import modalExperiences from "./modalExperiences.vue";
import EmployeeEducationService from "../../services/employee-eductation";
import EmployeeService from "../../services/employee.service";

// const props = defineProps({
//   employeeId: String
// })

// const store = useStore()

// const profile = computed(() => store.getters["profile/getAll"]) ;
// const emergency = computed(() => store.getters["emergency/getAll"]) ;

// const getProfile = (id) => {
//   // console.log("Ready to get profile", props.employeeId);
//   store.dispatch("profile/getOne",id);

// }
// const getContacts = (id)=>{
//   store.dispatch("emergency/getbyId",id);
// }

// onMounted(()=>{
//   console.log(props.employeeId);
//   getProfile(props.employeeId);
//   getContacts(props.employeeId);

// })

export default {
  name: "profilecontent",
  components: { modalpersonalInfo, modalEmergencyInfo, modalBank, modalFamilyInfo, modalEducation, modalExperiences },
  props: ["personalInfo",  "emergencycontacts","allActuals", "bankDetails", "familyMembers", "profile", "employeeId"],

  data() {
    return {};
  },
  methods: {
    
    // ...mapActions("employees2", ['getEmployeeFamilyInfo']),
    ...mapActions("EmployeeExperience", ['getEmployeeExperience']),
    ...mapActions("EmployeeExperience", ['getEmployeeExperience']),

    togglemodalExperiences(data) {
      console.log("inner coponent", data);
      let obj = toRaw(data);
      this.$emit("add_actual_modal", obj);
    },
  },



  computed: {
    ...mapGetters('employees2', ['Employee', 'personalInfo',  'emergencyContact', 'bankDetails', 'allEmployees']),
    ...mapGetters('EmployeeEducation', ['getAllEmployeeEducation']),
    ...mapGetters('EmployeeExperience', ['getAll']),
    ...mapGetters('EmployeeFamily', ['getAllEmployeeFamily']),
    getPersonalInfoDetails() {
      let personalInfoObject = Object.assign({}, this.personalInfo);
      return personalInfoObject;
    },
    getfamilyInfo() {
      let FamilyInfoObject = Object.assign({}, this.getAllEmployeeFamily)
      return FamilyInfoObject;
    },
    getEductionStuff() {
      let educationList = Object.assign([], this.getAllEmployeeEducation);
      return educationList;
    },
    getExperience() {
      let experienceList = Object.assign([], this.getAll);
      return experienceList;
    }

  },
  methods: {
    ...mapActions('EmployeeEducation', ['getEmployeeEducation']),
    ...mapActions('EmployeeExperience', ['getEmployeeExperience']),
    ...mapActions('EmployeeFamily', ['getEmployeeFamily', 'deleteFamilyMemberM']),
    downloadQualification(e,item){
      e.preventDefault();
      console.log(item);
      EmployeeEducationService.downloadDocument(item.id).then((response)=>{
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // console.log(link);

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let filelink = document.createElement('a');
        filelink.href = fileURL;
        filelink.setAttribute('download', 'qualifications.pdf')
        document.body.appendChild(filelink);
        filelink.click()
       
      })
    },
    downloadIdentity(e){
      e.preventDefault();
      let id = this.employeeId;
      let payload = {id : id}

      EmployeeService.downloadIdentityDocument(payload).then(response=>{
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let filelink = document.createElement('a');
        filelink.href = fileURL;
        filelink.setAttribute('download', 'identity.pdf')
        document.body.append(filelink)
        filelink.click();          
      })
      


    }
    ,
    deleteFamilyMember(familyMember){
     

     let family =  toRaw(familyMember);
     console.log("Delete this family member",family.id)
     this.deleteFamilyMemberM(family['id']).then(()=>{
         this.getEmployeeFamily({ employeeId:this.employeeId});
     })


    }
  }
  ,
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(user);
    let employeeId = user.user.id;
    this.getEmployeeExperience(employeeId)

    // secondaryContact = rawEmergencyArray[1]

    // let user = JSON.parse(localStorage.getItem("user"));

    // let employeeId = user.user.id;
    // this.getEmployeeFamilyInfo(employeeId)
  },
  created() {
    // let rawEmergencyArray =  toRaw(this.emergencycontacts)
    // console.log(rawEmergencyArray)
    //  this.primaryContact= rawEmergencyArray[0]
    // console.log(this.primaryContact)
    // this.getEmployeeEducation({employeeId :this.employeeId});
  },

  setup() {
    const modalActive = ref(false);
    const modalEmergencyActive = ref(false);
    const modalBankActive = ref(false);
    const modalFamilyActive = ref(false);
    const modalEducationActive = ref(false);
    const modalExperiencesActive = ref(false);
    const actualData = ref();


    const toggleModal = () => {
      // e.preventDefault();
      modalActive.value = !modalActive.value;
      if (modalActive.value == true) {
        $("#personal_info_modal").modal("show");
      } else {
        $("#personal_info_modal").modal("hide");
      }
    };
    const toggleFamilyModal = () => {
      // e.preventDefault();
      modalFamilyActive.value = !modalFamilyActive.value;
      if (modalFamilyActive.value == true) {
        $("#family_info_modal").modal("show");
      } else {
        $("#family_info_modal").modal("hide");
      }
    };
    const toggleEmergencyModal = () => {
      // e.preventDefault();
      modalEmergencyActive.value = !modalEmergencyActive.value;
      if (modalEmergencyActive.value == true) {
        $("#emergency_contact_modal").modal("show");
      } else {
        $("#emergency_contact_modal").modal("hide");
      }
    };

    const toggleBankModal = () => {
      modalBankActive.value = !modalBankActive.value;
      if (modalBankActive.value == true) {
        $("#bank_modal").modal("show");
      } else {
        $("#bank_modal").modal("hide");
      }
    };

    const toggleModalEduction = () => {

      modalEducationActive.value = !modalEducationActive.value;
      if (modalEducationActive.value == true) {
        $("#education_info").modal("show");
      } else {
        $("#education_info").modal("hide");
      }


    };

    const togglemodalExperiences = () => {
      // console.log(obj);
      // actualData.value = obj;
      modalExperiencesActive.value = !modalExperiencesActive.value;
      if (modalExperiencesActive.value == true) {
        $("#experience_info").modal("show");
      } else {
        $("#experience_info").modal("hide");
      }


    }

    return {
      modalActive,
      toggleModal,
      modalEmergencyActive,
      toggleEmergencyModal,
      modalBankActive,
      toggleBankModal,
      toggleFamilyModal,
      modalEducationActive,
      toggleModalEduction,
      modalExperiencesActive,
      togglemodalExperiences,
      actualData
    };
  },
};
</script>
