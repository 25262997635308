<template>
  <div>
    
<!-- Profile Modal -->
       
<!-- /Profile Modal -->
        
        <!-- Personal Info Modal -->
        
        <!-- /Personal Info Modal -->
        
        <!-- Family Info Modal -->
  
        <!-- /Family Info Modal -->
        
       <!--Emergency-->
       <!--End of emergency-->
        
        <!-- Education Modal -->
        <!-- <div id="education_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"> Education Informations</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-scroll">
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Education Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Oxford University" class="form-control floating">
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Computer Science" class="form-control floating">
                            
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon custompicker">
                            <datepicker v-model="startdate1"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                            </div>
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon custompicker">
                              <datepicker v-model="enddate1"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                              </div>
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="BE Computer Science" class="form-control floating">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Grade A" class="form-control floating">
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Education Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Oxford University" class="form-control floating">
                              <label class="focus-label">Institution</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Computer Science" class="form-control floating">
                              <label class="focus-label">Subject</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon custompicker">
                            <datepicker v-model="startdate2"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                            </div>
                           
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon custompicker">
                            <datepicker v-model="enddate2"  class="picker" 
                               :editable="true"
                               :clearable="false" />
                               </div>
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="BE Computer Science" class="form-control floating">
                              <label class="focus-label">Degree</label>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" value="Grade A" class="form-control floating">
                              <label class="focus-label">Grade</label>
                            </div>
                          </div>
                        </div>
                        <div class="add-more">
                          <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <!-- /Education Modal -->

        
        
        <!-- Experience Modal -->
        <!-- <div id="experience_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Experience Informations</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-scroll">
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Digital Devlopment Inc">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="United States">
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Operations Manager">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon custompicker">
                          <datepicker v-model="startdate3"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                      </div>
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon custompicker">
                          <datepicker v-model="enddate3"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                     </div>
                         
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i class="fa fa-trash-o"></i></a></h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Digital Devlopment Inc">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="United States">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <input type="text" class="form-control floating" value="Operations Manager">
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon custompicker">
                          <datepicker v-model="startdate4"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                      </div>
                            
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus">
                              <div class="cal-icon custompicker">
                          <datepicker v-model="enddate4"  class="picker" 
                          :editable="true"
                          :clearable="false" />
                     </div>
                           
                            </div>
                          </div>
                        </div>
                        <div class="add-more">
                          <a href="javascript:void(0);"><i class="fa fa-plus-circle"></i> Add More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> -->
        <!-- /Experience Modal -->
        </div>
        
</template>

<script >
import { computed,defineProps,toRefs,onMounted,ref, onBeforeMount,onUpdated, onBeforeUpdate } from "vue";
import { useStore } from "vuex";
import ModalpersonalInfo from "./modalpersonalInfo.vue";

  // const currentDate = ref(new Date())
  // const currentDate1 = ref(new Date())
  // const currentDate2 = ref(new Date())
  // const currentDate3 = ref(new Date())
  // const currentDate4 = ref(new Date())
  // const currentDate5 = ref(new Date())
  // const currentDate6 = ref(new Date())
  // const currentDate7 = ref(new Date())
  // const currentDate8 = ref(new Date())
  // const currentDate9 = ref(new Date())
  // const profile = ref({employee_id:'', employment_of_spouse :'', id : '', martital_status: '', nationality:'',no_of_children: '', 
  // passport_expry_date: '',passport_no:'',tel:''})


//   const props = defineProps({
//   employeeId: String
// })
// const store = useStore()
// let profileObj = ref({})


//Stores


// const profile = computed(() => store.getters["profile/getAll"]) ;



// let PassportExpr = ref(null);

// const submitPersonalInfo =(event)=>{
//   event.preventDefault();
//   console.log("PROFILEOBJ", profileObj.value)

  

// }
// console.log(profile.value.passport_expry_date);
// onUpdated(() => {
//       console.log('updated() val: ' + profile.value)
//       profileObj.value = profile.value;
//       console.log("PROFILEOBJ", profileObj.value)
//     }) 
// onMounted(()=>{
  
//   if(profile.value){
//     profileObj.value = profile.value ;
//   }
//   console.log("Props In the Modal controller",profileObj.passport_expry_date);
  
  // let dbdate = profile.passport_expry_date
  // const [year, month, day] = [...dbdate.split("-")];
  // const monthIndex = month - 1;
  // const savedPassportExpiryDate = new Date(year, monthIndex, day);
  // PassportExpr = ref(savedPassportExpiryDate);

  // profile.value = store.getters["profile/getAll"];
  
  // console.log(store.getters["profile/getAll"]);

  
  
// })

export default {
    props: ['profile'],
    components: {
   
    },
    mounted() {
      if ($('.select').length > 0) {
                $('.select').select2({
                    minimumResultsForSearch: -1,
                    width: '100%'
                });
            }
      }
  
  }


</script>