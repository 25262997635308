// Employee module
import axios from 'axios';

const resource_uri = "api/employee/";

const state = {
    employees: []
};

const mutations = {
    setEmployees: (state, employees) => state.employees = employees,
    newEmployee: (state, employee) => state.employees.push(employee),
    updEmployee: (state, updatedEmployee) => {
        const index = state.employees.findIndex(t => t.id === updatedEmployee.id);
        if(index !== -1) {
            state.employees.splice(index, 1, updatedEmployee);
        }        
    },
    deleteEmployee: (state, employee) => state.employees = state.employees.filter(t => employee.id !== t.id),
};

const actions = {
    async fetchEmployees({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setEmployees', response.data);
    },
    async addEmployee( { commit }, employee) {
        const response = await axios.post(resource_uri, employee);
        commit('newEmployee', response.data.employee);
    },
    async updateEmployee( { commit }, employee) {
        const response = await axios.put(`${resource_uri}${employee.id}`, employee);
        commit('updEmployee', response.data);
    },
    async removeEmployee( { commit }, employee) {
        const response = await axios.delete(`${resource_uri}${employee.id}`);
        commit('deleteEmployee', employee);
    }
};

const getters = {
    allEmployees: state => state.employees
};

export default {
    state, 
    getters, 
    actions, 
    mutations
}