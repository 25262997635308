import CompetenciesDesignationService from "../../services/competenciedesignation.services.js";

const state = () => ({
    competenciesDesignation: [],
    selectedComtenceDesignation: {},
    competencies : [],
    error: null,
    loading: false,
});


const mutations = {
    SET_COMPETENCIES_DESIGNATION(state, list){
        state.competenciesDesignation = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_SINGLE_DESIGNATION_COMPETENCY(state,obj){
    
        state.selectedComtenceDesignation = obj
    },
    SET_COMPETENCIES(state,list){
        state.competencies = list
    }
    ,
    SET_ERROR(state, err) {
        state.error = err;
    },

}

const actions = {
    add({commit},competencyObj){
        commit("SET_LOADING", true);
        return CompetenciesDesignationService.addCompDesignation(competencyObj).then(async (compObj)=>{
                
            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            // commit("SET_COMPETENCIES_DESIGNATION", resp);   
            // this.getAllDesignationsComp();   

        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        
        )
    },
    update({commit},competencyObj){
        commit("SET_LOADING", true);
        return CompetenciesDesignationService.updateCompDesignation(competencyObj).then(async (compObj)=>{
                
            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            // commit("SET_COMPETENCIES_DESIGNATION", resp);   
            // this.getAllDesignationsComp();   

        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        
        )
    }
    
    ,

    getAllDesignationsComp({commit}){
        commit("SET_LOADING", true);
        return CompetenciesDesignationService.getAllCompDesignations().then(async (compObj)=>{

            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            commit("SET_COMPETENCIES_DESIGNATION", resp);   
        },

        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        
        )

    },
        deleteDesignationsComp({commit}, obj){
                console.log("THE DELETED item", obj)
            return CompetenciesDesignationService.deleteCompDesignations(obj).then( async ()=>{
                  
            },
            async(error)=>{
                const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
            }
            
            )
        }
    ,

    getCompetenciesMethod({commit},obj){
        return CompetenciesDesignationService.getCompetencies(obj).then(async (compObj)=>{

            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            commit("SET_COMPETENCIES", resp);   
        },

        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        
        )

    },



    selectCompetency({commit}, obj){
        commit("SET_LOADING", true);
        commit("SET_SINGLE_DESIGNATION_COMPETENCY", obj);   

    }

    

}
const getters = {

    allCompetenciesDesignation(state) {
        return state.competenciesDesignation;
    },
    CompetenciesDesignation(state){
        return state.selectedComtenceDesignation
    }
    ,
    loading(state){
        return state.loading;
    },
    error(state){
        return state.error  ;
    },

    competenciesState(state){
        return state.competencies ;
    }


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
