import Axios from 'axios';

const MAIN_NAMESPACE = "api/emergency-contacts"
const SUB_NAMESPACE = "api/emergency-contacts/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const NEW_API_URL = `/${SUB_NAMESPACE}contacts`

class EmergencyService {

    async getOneById(id) {
        return await Axios.get(SUB_API_URL + `${id}`, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async addEmergencyContact(emergencyObject){
        return await Axios.post(NEW_API_URL, emergencyObject).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    
    }
}    
export default new EmergencyService