<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-bordered review-table mb-0 datatable"
          id="goaltypecontent"
        >
          <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th>Type</th>
              <th>Description</th>
              <th>Status</th>
              <th class="text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in allGoalTypes" :key="item.id">
              <td>{{ $index }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.description }}</td>
              <td>
                {{ item.status }}
              </td>
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a
                    href="javascript:void(0)"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="material-icons">more_vert</i></a
                  >
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                    @click=" openEditModelEvent(e,item)"
                    ><i class="fa fa-pencil m-r-5"></i> Edit</a
                    >
                    <a
                      @click="deleteGoalType(item)"
                      class="dropdown-item"
                      href="javascript:void(0)"
                      ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                    >
                    
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import util from "../../../../assets/utils/util";
export default {
 
  methods: {
    ...mapActions(["fetchGoalTypes", "removeGoalType"]),
    ...mapActions('goaltypes2',['setEditGoalType']),
    deleteGoalType(goal_type) {
      if (confirm("Are you sure to delete this goal type ?")) {
        this.removeGoalType(goal_type);
      }
    },
    openEditModelEvent(e,item){
      // e.preventDefault()
      console.log("Ready to Edit", item)

      this.setEditGoalType(item).then(()=>{
        this.$emit('openEditGoalTypeModal',item)
      });

      
    }
  },
  computed: mapGetters(["allGoalTypes"]),
  created() {
    this.fetchGoalTypes();
  },
  components: {},
  mounted() {
    util.datatable("#goaltypecontent");
  },

};
</script>