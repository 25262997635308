<template>
  
    <!-- Add Perspective Modal -->
    <div id="add_perspective" class="modal custom-modal fade" role="dialog"  
    data-bs-backdrop="static" >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Perspective</h5>
            <button type="button" class="close"   @click="$emit('closeModalPerspective')" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit="onSubmit">
              <div class="form-group">
                <label>Perspective Name <span class="text-danger">*</span></label>
                <input class="form-control" v-model="name" type="text"/>
              </div>
              <div class="form-group">
                <label>Weight <span class="text-danger">*not more than {{ 100 - getPerspective }}%</span></label>
                <input class="form-control" v-model="weight" type="number"/>
              </div>
              <div class="submit-section">
                <button type="submit"   @click="submitWeight($event)" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Perspective Modal -->

    <!-- Edit Perspective Modal -->
    <!-- <div id="edit_perspective" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Perspective</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>Perspective Name <span class="text-danger">*</span></label>
                <input class="form-control" type="text" />
              </div>
              <div class="submit-section">
                <button type="submit" class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Edit Perspective Modal -->

    <!-- Delete Perspective Modal -->
 
  
  <!-- /Delete Perspective Modal -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  
    name : "perspective modal",
    data() {
        return {
            name: "",
            weight: ""
        }
    },
    methods: {
        ...mapActions('perspectives2',['add', 'getAllPerspectives']),
        submitWeight(e) {
          e.preventDefault();
       
        
            
          if( this.getPerspective + Number(this.weight) > 100){
            console.log("This weight is greater :",Number(this.weight), "The total : ", this.getPerspective );
          
            return

          }
           
            const perspective = {
                name: this.name,
                weight: this.weight
            }
            this.add(perspective).then(()=>{
              this.getAllPerspectives();  
              this.name = null
              this.weight = null
            // $("#add_perspective").modal("hide");
            this.$emit('closeModalPerspective')
          });
        },
        updatePerspective() {
          const updatedPerspective = {
              id: this.perspective.id,
              name: this.perspective.name,
              weight: this.perspective.weight
          };
          this.updatePerspective(updatedPerspective);
        },
    
        deletePerspective() {
          // this.removePerspective(this.perspective);
          console.log(this.perspective)
        }
    },

    computed : {
             ...mapGetters('perspectives2',['allPerspectives']) ,

             getPerspective(){
              let perspectiveList = this.allPerspectives;

            

              // perspectiveList.forEach(item=>{
              //   totalAmount = totalAmount + Number( item.weight) 
              // })
              const totalAmount = perspectiveList.reduce((accumulator, item) => {
                    return accumulator + Number(item.weight);
                    }, 0);  



            
              return totalAmount 

             }
        }
    
};
</script>
