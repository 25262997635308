<template>
  <div>
    <form>
      <div class="row mb-4">
        <div class="col-md-3">
          <input
            type="text"
            class="form-control"
            name="competency"
            v-model="competencyObj.compentency"
            required=""
            placeholder="Competency"
          />
        </div>
        <div class="col-md-6">
          <textarea
            style="height: 44px"
            rows="4"
            cols="20"
            v-model="competencyObj.definition"
            class="form-control"
            name="definition[]"
            placeholder="Definition"
            required=""
          ></textarea>
        </div>
        <div class="col-md-3">
          <button
            class="btn btn-primary submit-btn"
            type="submit"
            @click="addCompetency($event)"
            v-if="update == false"
            id="create_offers_submit"
          >
            Save Competence
          </button>
          <button
            class="btn btn-primary submit-btn"
            type="submit"
            @click="addCompetency($event)"
            v-if="update == true"
            id="create_offers_submit"
          >
            Update
          </button>
        </div>
      </div>
    </form>
    <table class="table table-bordered review-table table-hover">
      <thead>
        <tr>
          <th style="width: 250px">Competency</th>
          <th>Definition</th>
          <th style="width: 80px; text-align: center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in allCompetencies" :key="item.id">
          <td>{{ item.compentency }}</td>
          <td>{{ item.definition }}</td>
          <td class="text-center">
            <a
              href="javascript:void(0);"
              @click="editCompetencies($event, item)"
              class="text-warning"
              data-original-title="Edit"
              ><i class="fa fa-pencil"></i
            ></a>
            <a
              @click="deleteCompetencyEvent($event, item)"
              class="text-danger"
              title=""
              data-toggle="ajaxModal"
              data-original-title="Delete"
              ><i class="fa fa-times"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Competency table",
  data() {
    return {
      competencyObj: {
        id: null,
        compentency: null,
        definition: null,
      },
      update: false,
    };
  },
  computed: {
    ...mapGetters("competencies", ["allCompetencies"]),
  },
  methods: {
    ...mapActions("competencies", [
      "add",
      "getAll",
      "deleteCompetency",
      "updateCompetency",
    ]),
    addCompetency(e) {
      e.preventDefault();
      if (
        this.competencyObj.definition == null ||
        this.competencyObj.compentency == null
      ) {
        return;
      }

      if (
        this.competencyObj.definition !== "" &&
        this.competencyObj.compentency !== "" &&
        this.update == false
      ) {
        this.add(this.competencyObj).then(() => {
          this.competencyObj.definition = null;
          this.competencyObj.compentency = null;
          this.update = false;
        });
      } else if (
        this.competencyObj.definition !== "" &&
        this.competencyObj.compentency !== "" &&
        this.update == true
      ) {
        this.updateCompetency(this.competencyObj).then(() => {
          this.competencyObj.definition = null;
          this.competencyObj.compentency = null;
          this.update = false;
        });
      }
    },

    deleteCompetencyEvent(e, item) {
      e.preventDefault();

      this.deleteCompetency(item).then(() => {
        console.log("Item Deleted");
      });
    },
    editCompetencies(e, item) {
      e.preventDefault();
      this.update = true;
      console.log("Ready to edit component", item);
      this.competencyObj.id = item.id;
      this.competencyObj.definition = item.definition;
      this.competencyObj.compentency = item.compentency;

      // if( this.competencyObj.definition !=='' && this.competencyObj.compentency !=='' && this.update == true){

      // this.updateCompetency(this.competencyObj).then(()=>{
      //     this.competencyObj.definition = '';
      // this.competencyObj.compentency = '';
      // })
    },
    // updateCompetency(e){
    //     e.preventDefault() ;
    // }
  },
  mounted() {
    this.getAll();
  },
};
</script>
