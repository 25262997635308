<template>
  <ul class="nav nav-tabs nav-tabs-bottom">
    <!-- <li class="nav-item active">
      <a class="nav-link active" href="#okr_tab" data-bs-toggle="tab">OKRs</a>
    </li> -->
    <li class="nav-item active">
      <a class="nav-link active" href="#compentency_tab" data-bs-toggle="tab"
        >Competency-based</a
      >
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#smart_goals_tab" data-bs-toggle="tab"
        >Perfomance Periods</a
      >
    </li>
  </ul>
</template>