import CompetenciesService from "../../services/competencies.service.js";

const state = () => ({
    competencies: [],
    competency : {},
    error: null,
    loading: false,
});

const mutations = {
    SET_COMPETENCIES(state, list){
        state.competencies = list;
    },
    SET_COMPETENCY(state,obj){
        state.competencies.push(obj);
    },
    SELECT_COMPETENCY(state,list){
        state.competency = list;
    },

    REMOVE_COMPETENCY(state,obj){
    
            state.competencies = state.competencies.filter(competency => competency.id != obj.id)
    },
    UPDATE_COMPETENCY(state,obj){
            const index = state.competencies.findIndex(competency=> competency.id === obj.id)

            if(index !== -1){
                state.competencies.splice(index,1,obj)
            }
    }
    ,
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state, err) {
        state.error = err;
    },

}

const actions = {
    getAll({ commit }){
        commit("SET_LOADING", true);
        return CompetenciesService.getAll().then(async (depObj) => {

            const resp = await Promise.resolve(depObj);
            commit("SET_LOADING", false);
            commit("SET_COMPETENCIES", resp);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        );
    },
    add({commit},competencyObj){
        commit("SET_LOADING", true);
        return  CompetenciesService.createOne(competencyObj).then( async (compObj)=>{
                
            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            commit("SET_COMPETENCY", resp);      

        }, 
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }

        )
    },
    deleteCompetency({commit},competency){
        commit("SET_LOADING", true);
        return CompetenciesService.deleteOne(competency.id).then(async (compObj)=>{
            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            commit("REMOVE_COMPETENCY", competency);     

        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    },

    updateCompetency({commit},competency){
        commit("SET_LOADING", true);
        return CompetenciesService.updateOne(competency).then(async (compObj)=>{
            const resp = await Promise.resolve(compObj);
            commit("SET_LOADING", false);
            commit("UPDATE_COMPETENCY", resp);     

        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    }


}


const getters = {
    allCompetencies(state) {
        return state.competencies;
    },
    loading(state){
        return state.loading;
    },
    competency(state){
        return state.competency  ;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};