// actionplans module
import axios from 'axios';

const resource_uri = "api/action-plans/";
const resource_uri_2 = "api/action-plans/plan/test";

const state = {
    actionplans: []
};

const mutations = {
    setActionPlans: (state, actionplans) => state.actionplans = actionplans,
    newActionPlan: (state, actionplan) => state.actionplans.push(actionplan),
    updActionPlan: (state, updatedActionplan) => {
        const index = state.actionplans.findIndex(t => t.id === updatedActionplan.id);
        if(index !== -1) {
            state.actionplans.splice(index, 1, updatedActionplan);
        }        
    },
    deleteActionPlan: (state, actionplan) => state.actionplans = state.actionplans.filter(t => actionplan.id !== t.id),
};

const actions = {
    async fetchActionPlans({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setActionPlans', response.data);
    },
    async fetchActionPlans2({ commit }) {
        const response = await axios.get(resource_uri_2);    
        commit('setActionPlans', response.data);
    },
    async addActionPlan( { commit }, actionplan) {
        const response = await axios.post(resource_uri, actionplan);
        commit('newActionPlan', response.data.actionplan);
    },
    async updateActionPlan( { commit }, actionplan) {
        const response = await axios.put(`${resource_uri}${actionplan.id}`, actionplan);
        commit('updActionPlan', response.data);
    },
    async removeActionPlan( { commit }, actionplan) {
        const response = await axios.delete(`${resource_uri}${actionplan.id}`);
        // commit('deleteActionPlan', actionplan);
    },
};

const getters = {
    allActionplans: state => state.actionplans
};

export default {
    namespaced: true,
    state, 
    getters, 
    actions, 
    mutations
}