<template>
  <li class="nav-item dropdown has-arrow main-drop me-4">
    <a
      href="javascript:void(0)"
      class="dropdown-toggle nav-link"
      data-bs-toggle="dropdown"
    >
      <span class="user-img me-1"
        ><img src="../../assets/img/profiles/avatar-21.jpg" alt="" />
        <span class="status online"></span
      ></span>
      <!-- <span class="adminclass">Admin</span> -->
      <span v-if="user_email !== ''" class="adminclass">{{ user_name }}</span>
      <span v-else class="adminclass"></span>
    </a>
    <div class="dropdown-menu">
      <!-- <router-link class="dropdown-item" to="/profile">My Profile</router-link> -->
      <router-link class="dropdown-item" to="/settings">Settings</router-link>
      <!-- <router-link class="dropdown-item" to="/">Logout</router-link> -->
      <div class="dropdown-item" @click="logoutUser($event)">Logout</div>
    </div>
  </li>
</template>
<script>
import { router } from "../router";
import { useStore, mapGetters, mapActions } from "vuex";
export default {
  props: ["user"],
  data() {
    return {
      user_email: "",
      user_name: "",
      user_id: "",
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.user_email = user.user.email;
    this.user_name = user.user.name;
    this.user_id = user.user.id;
  },
  computed: { ...mapGetters("user", ["getUser"]) },
  methods: {
    ...mapActions("user", ["logOut"]),
    async logoutUser(e) {
      e.preventDefault();
      let token = await this.getUser.token;

      this.logOut(token).then(() => {
        router.push("/");
        // console.log("token should be deleted");
      });
    },
  },
};
</script>
