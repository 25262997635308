import Axios from 'axios';
const MAIN_NAMESPACE = "api/employee"
const MAIN_NAMESPACE_1 = "api/employee-department"
const SUB_NAMESPACE = "api/employee/"
const SUB_DOWNLOAD = "api/personal-info/download"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_1 = `/${MAIN_NAMESPACE_1}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const SUB_API_URL_DOWNLAOD = `/${SUB_DOWNLOAD}`


class EmployeeService{

   async getAll(){
        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            console.log("THE EMPLOYEE SERVICE")
            return response.data
        }).catch((error) => {
            return error
        });
    }
   async getAllDepartmentFilter(id){
        return await Axios.post(MAIN_API_URL_1, {department_id : id
        }).then((response) => {
            console.log("THE EMPLOYEE  filter SERVICE")
            return response.data
        }).catch((error) => {
            return error
        });
    }





    async getOne(id){

        return await Axios.get(SUB_API_URL + `${id}` , {
        }).then((response) => {
            
            return response.data
        }).catch((error) => {
            return error
        });

    }


    async deleteEmployee(id){
        return await Axios.delete(SUB_API_URL + `${id}` , {
        }).then((response) => {
            
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async createOne(employeeObj) {
        return Axios.post(MAIN_API_URL, employeeObj, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async update(updateObj){
        return await Axios.put(SUB_API_URL + `${updateObj.id}`,updateObj,{
        }).then((response)=>{
            return response.data
        }).catch((error)=>{
            return error ;
        })
    }

    async delete(id){
        return await Axios.delete(SUB_API_URL + `${id}`).then(
            (response)=>{
                return response.data
            }).catch((error)=>{
                return error ;
            })

    }


    async downloadIdentityDocument(payload){
        return await Axios.post(SUB_API_URL_DOWNLAOD,payload, { responseType: "blob"}).then(
            (response)=>{
                return response
            }  
        ).catch((error) => {
            return error
        })

    }
}

export default new EmployeeService