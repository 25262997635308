import PerformanceService from "../../services/performance_services";


const state = {
    performanceIndicator: [],
    selectedPerformaceIndicator : {} ,
    error: null,
    loading: false,

}    

const mutations = {
    SET_PERFORMANCE_INDICATOR(state, obj){
        state.performanceIndicator.push(obj);
    }, 
    SET_PERFORMANCE_INDICATORS(state, list){
        state.performanceIndicator = list;
    }, 
    SET_ERROR(state, error){
        state.error = error;
    }, 
    SET_LOADING(state, status){
        state.loading = status;
    }, 

    SET_SELECTED_INDICATOR(state, obj) {
        state.selectedPerformaceIndicator = obj ;
    }

}


const actions = {

    addPerformanceIndicator({commit},pfObj){
        commit("SET_LOADING", true);
        return PerformanceService.addPerformanceIndicator(pfObj).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                console.log(res);
                commit("SET_LOADING", false);
                commit("SET_PERFORMANCE_INDICATOR", res);
            }
        ).catch(async (error)=>{
            const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
        })
    },
    getPerformanceIndicator({commit}){
        commit("SET_LOADING", true);
        return PerformanceService.getPerformanceIndicator().then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_PERFORMANCE_INDICATORS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },

    setPerformanceIndicator({commit},item){
        commit("SET_LOADING", true);

      let theObj = state.performanceIndicator.filter( obj =>{ return   obj.id == item.id})

        commit("SET_SELECTED_INDICATOR", theObj[0])

        commit("SET_LOADING", false);
    },

    getSelectedPerformanceIndicator({ commit }, degsination_id) {
        commit("SET_LOADING", true);
        return PerformanceService.getSelectedPerfomanceIndicator(degsination_id).then(
            async (indicator) => {
                const res = await Promise.resolve(indicator);
                commit("SET_LOADING", false);
                commit("SET_SELECTED_INDICATOR", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    }

}


const getters = {
    allPerformanceIndicator(state) {
        return state.performanceIndicator;
    },
    getSelectedPerformanceIndicator(state) {
        return state.selectedPerformaceIndicator;
    },
    loading(state) {
        return state.loading;
    },
    error(state) {
        return state.error;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};