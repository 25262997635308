<template>
  <div class="tab-pane" id="smart_goals_tab">
    <form>
      <div class="row mb-4">
        <div class="col-md-3">
          <input
            type="text"
            class="form-control"
            required
            placeholder="Perfomance Period"
            v-model="performance_period"
          />
        </div>
        <div class="col-md-3">
          <!-- <div class="cal-icon custompicker"> -->
            <!-- <datepicker class="picker" :editable="true" :clearable="false" :modelValue="start_date" /> -->
            <input type="date" class="picker"  v-model="start_date" >
          <!-- </div> -->
        </div>
        <div class="col-md-3">
          <!-- <div class="cal-icon custompicker"> -->
            <!-- <datepicker class="picker" :editable="true" :clearable="false" /> -->
            <input type="date" class="picker"  v-model="end_date" >
          <!-- </div> -->
        </div>
        <div class="col-md-3">
          <button
          v-if ="update == false"
            class="btn btn-primary submit-btn create_goal_configuration_submit"
            type="submit"
            @click="savePerformancePeriods($event)"
          >Save</button>

          <div class="row">
             <div class="col-12">
              <button
           v-if ="update == true"
            class="btn btn-primary submit-btn create_goal_configuration_submit"
          
            @click="updatePerformancePeriods($event)"
          >Update</button>
              
             </div>
             <div class="col-12">
              <button
           v-if ="update == true"
            class="btn btn-light submit-btn create_goal_configuration_submit"
           
            @click="cancelUpdate($event)"
          >Cancel</button>
             </div>
          </div>
         
         


        </div>
      </div>
    </form>
    <table class="table table-bordered table-hover review-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Perfomance Period</th>
          <th>Start</th>
          <th>End</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item,index) in getPerformancePeriod" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.performance_period }}</td>
          <td>{{ item.start_date }}</td>
          <td>{{ item.end_date }}</td>
          <!-- <td>{{ item.status }}</td> -->
          <td>
            <div class="dropdown action-label">
                          <a class="btn btn-white btn-sm btn-rounded dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-dot-circle-o text-success" v-if="item.status =='Active'"></i> 
                            <i class="fa fa-dot-circle-o text-danger"   v-if="item.status =='Deactivated'"></i> {{item.status}}
                          </a>
                          <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="javascript:void(0)" @click="activate($event,item)"><i class="fa fa-dot-circle-o text-success"></i>Activate</a>
                            <a class="dropdown-item" href="javascript:void(0)"  @click="deactivate($event,item)"><i class="fa fa-dot-circle-o text-danger"></i> Deactivate</a>
                          
                          </div>
                </div>
          </td>
          <td style="width: 80px; text-align: center">
            <a href="javascript:void(0);" class="text-warning" data-original-title="Edit" @click="editPerformancePeriod(item)">
              <i class="fa fa-pencil"></i>
            </a>
            <a
              class="text-danger"
              @click="deletePerformancePeriod(item)"
              data-original-title="Delete"
            >
              <i class="fa fa-times"></i>
            </a>
          </td>
        </tr>
        <!-- <tr>
          <td>1</td>
          <td>2023</td>
          <td>01 Jan 2023</td>
          <td>31 Dec 2023</td>
          <td>Active</td>
          <td style="width: 80px; text-align: center">
            <a href="javascript:void(0);" class="text-warning" data-original-title="Edit">
              <i class="fa fa-pencil"></i>
            </a>
            <a class="text-danger" data-toggle="ajaxModal" data-original-title="Delete">
              <i class="fa fa-times"></i>
            </a>
          </td>
        </tr>-->
      </tbody>
    </table>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "performance Periods",
  data() {
    return {
      performance_period: null,
      start_date: null,
      end_date: null,
      status: "Deactivated", 
      update : false,
      id : null
    };
  },
  methods: {
    ...mapActions("PerformancePeriod", [
      "getPerformancePeriods",
      "addPerformancePeriods",
      "deletePerformancePeriodM",
      "updateStatus",
      "updateDeactivateStatus",
      "updatePerformancePeriodM"
    ]),
    savePerformancePeriods(e) {
      e.preventDefault();
      let obj = {
        performance_period: this.performance_period,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status
      };
      this.addPerformancePeriods(obj).then(() => {
        this.getPerformancePeriods();
        this.start_date = null
        this.end_date = null
        this.performance_period = null
      });
    },
    deletePerformancePeriod(item) {
      // e.preventDefault();

      let theItem = toRaw(item);
   
      // let id = obj.id;
      // console.log("ID IS", id);
      this.deletePerformancePeriodM( theItem.id).then(() => {
        this.getPerformancePeriods();
      });
    },

    editPerformancePeriod(item){
      this.update = true
         let performancePeriod = toRaw(item)
        console.log('edit this performance period', performancePeriod)
        this.performance_period = performancePeriod.performance_period
        this.start_date = performancePeriod.start_date
        this.end_date = performancePeriod.end_date
        this.status = performancePeriod.status
        this.id = performancePeriod.id

    },

    cancelUpdate(){
        this.update = false
        this.performance_period = null
        this.start_date = null
        this.end_date =  null
    },
    updatePerformancePeriods(event){
      event.preventDefault();
     

    
        let obj = {
        performance_period: this.performance_period,
        start_date: this.start_date,
        end_date: this.end_date,
        status: this.status,
        id : this.id
      };
        this.updatePerformancePeriodM(obj).then(() => {
        this.getPerformancePeriods();
        this.start_date = null
        this.end_date = null
        this.performance_period = null
        this.update= false
        this.id = null
      });
    },
    activate(e,item){
          e.preventDefault();
          this.updateStatus(item.id)
    },
    deactivate(e,item){
      e.preventDefault();
          console.log("ASKDL;ASDL", item)
          this.updateDeactivateStatus(item.id)

    }
  },
  computed: {
    ...mapGetters("PerformancePeriod", ["allPerformancePeriods"]),
    getPerformancePeriod() {
      let performancePeriodList = Object.assign([], this.allPerformancePeriods);
      return performancePeriodList;
    }
  },
  mounted() {
    this.getPerformancePeriods();
  }
};
</script>
