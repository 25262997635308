import Axios from 'axios';
const MAIN_NAMESPACE = "api/password/reset";
const MAIN_NAMESPACE_1 = "api/password/email";
const MAIN_API_RESET = `/${MAIN_NAMESPACE_1}`;
const MAIN_API_EMAIL = `/${MAIN_NAMESPACE}`;

class EmailResetApi {

    async restPasswordLink(emailObject){

        return await Axios.post(MAIN_API_RESET, emailObject).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })
    }
    async restPass(emailObject){

        return await Axios.post(MAIN_API_EMAIL, emailObject).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })
    }



}

export default new EmailResetApi