import Axios from 'axios';
const MAIN_NAMESPACE = "api/personal-info"
const SUB_NAMESPACE = "api/personal-info/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class ProfileService {
    async getAll() {
        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    
    async createOne(employeeObj) {
        return Axios.post(MAIN_API_URL, employeeObj, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async createOneNoDocument(employeeObj) {
        return Axios.post("api/personal-info-no-document", employeeObj, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getOneById(id) {
        return await Axios.get(SUB_API_URL + `${id}`, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async update(updateObj){
        return await Axios.put(SUB_API_URL + `${updateObj.employee_id}`,updateObj,{
        }).then((response)=>{
            return response.data
        }).catch((error)=>{
            return error ;
        })
    }



}

export default new ProfileService