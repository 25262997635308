import EmailResetApi from "../../services/emailrest.service";

const state = () => ({
    emailLink : {},
    error: null,
    loading: false,
});

const mutations = {
    SET_EMAIL_LINK(state, link){
        state.emailLink = link;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state,error){
        state.error = error
    }
};


const actions = {
    createLink({commit}, obj){
        commit("SET_LOADING", true);
        return EmailResetApi.restPasswordLink(obj).then(
            async (contactObj) => {
                const res = await Promise.resolve(contactObj);
                commit("SET_LOADING", false);
                commit("SET_CONTACTS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );

    },
    resetPassword({commit}, obj){
        commit("SET_LOADING", true);
        return EmailResetApi.restPass(obj).then(
            async (contactObj) => {
                const res = await Promise.resolve(contactObj);
                commit("SET_LOADING", false);
                commit("SET_CONTACTS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );

    }
}

const getters = {

    getLink(state) {
        return state.emailLink;
    },
    loading(state) {
        return state.loading;
    },
    error(state) {
        return state.error;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};