
import userService from "../../services/auth_service";


const state = () => ({
    user: {},
    error: null,
    loading: false,
});

const mutations = {
    SET_USER(state, list) {
        state.user = list;
    },
    SET_LOADING(state, status) {
        state.loading = status
    },
    SET_ERROR(state,err){
        state.error = err
    }
}


const actions = {
    loginUser({commit},obj){
        commit("SET_LOADING", true);
        return userService.login(obj).then(async(userObject)=>{
            const resp = await Promise.resolve(userObject);
            commit("SET_LOADING", false);
            commit("SET_USER", resp);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }

        ).catch(async error =>{{
                    console.log("Catching the error while here", error);
                    const err = await Promise.reject(error);
                    commit("SET_LOADING", false);
                    commit("SET_ERROR", err);
        }})
        
    },

    logOut({commit},token){
        commit("SET_LOADING", true);
       return userService.logout(token).then(async(userObject)=>{
            const resp = await Promise.resolve(userObject);
            commit("SET_LOADING", false);
            commit("SET_USER", {});
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_USER", err);
        }
        )
    }
}

const getters = {
    getUser : (state)=> state.user ,
    loading :(state)=> {
        state.loading;
   },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};