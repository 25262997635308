import PerformanceAppraisalService from "../../services/performance_appraisal.service";


const state = {
    performanceAppraisal: [],
    performanceAppraisalCompetencies: [],
    employeeCompetencies: [],
    selectedPerformanceAppraisal : {} ,
    selectedEmployeeAppraisal : {},
    reviewReport : [],
    error: null,
    loading: false,

}    


const mutations = {
    SET_APPRAISALS(state, list){
        state.performanceAppraisal = list;
    }, 
    SET_APPRAISALS_COMPETENCY(state, list){
        state.performanceAppraisalCompetencies = list;
    }, 
    SET_NEW_APPRAISAL(state, obj){
        state.performanceAppraisal.push(obj);
    }, 
    SET_ERROR(state, error){
        state.error = error;
    }, 
    SET_LOADING(state, status){
        state.loading = status;
    }, 
    SET_EMPLOYEE_COMPLIANCE(state,list){
            state.employeeCompetencies = list ;
    },

    SET_EMPLOYEE_APPRAISAL(state, obj){
            state.selectedEmployeeAppraisal = obj
    },
    SET_REVIEW_REPORT(state, list){
            state.reviewReport = list
    }




}   


const actions = {

    getPerformanceAppraisals({commit}){
        commit("SET_LOADING", true);
        return PerformanceAppraisalService.getPerformanceAppraisal().then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_APPRAISALS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },

    getPerformanceAppraisalsWithCompetency({commit}, appraisalObj){
        commit("SET_LOADING", true);
        return PerformanceAppraisalService.getPerformanceAppraisalWithCompetency(appraisalObj).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_APPRAISALS_COMPETENCY", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },
    addPerformanceReview({commit}, appraisalObj){
        commit("SET_LOADING", true);
        return PerformanceAppraisalService.addPerformanceReview(appraisalObj).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                // commit("SET_APPRAISALS_COMPETENCY", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )

    },



    getAllReviewedEmployees({commit}){
        commit("SET_LOADING", true);
        return PerformanceAppraisalService.getAllReviewedEmployees().then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_COMPLIANCE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },


    // Get All reviewed Employees with filter

    getFilteredReviewedEmployees({commit}, obj){
        commit("SET_LOADING", true);
        return PerformanceAppraisalService.getAllReviewedEmployeesFilter(obj).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_COMPLIANCE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    }


    ,


    addPerformanceAppraisal({commit},appraisal){
        return PerformanceAppraisalService.addPerformanceAppraisal(appraisal).then(
            async (appraisalObj)=>{
                
                const res = await Promise.resolve(appraisalObj);
                commit("SET_LOADING", false);
                commit("SET_NEW_APPRAISAL", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    }, 

    getCompetenceReviewReport({commit}, obj){
        return PerformanceAppraisalService.getEmployeeReviewReport(obj).then(
           
            async (appraisalObj)=>{
                console.log('Get the performance ', appraisalObj)
                const res = await Promise.resolve(appraisalObj);
                commit("SET_LOADING", false);
                commit("SET_REVIEW_REPORT", res);
            },
            async (error) =>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },
    getCompetenceReviewReportWithPerformancePeriod({commit}, obj){
        return PerformanceAppraisalService.getEmployeeReviewReportWithPerformancePeriod(obj).then(
            async (appraisalObj)=>{

                const res = await Promise.resolve(appraisalObj);
                commit("SET_LOADING", false);
                commit("SET_REVIEW_REPORT", res);
            },
            async (error) =>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },
    getCompetenceReviewReportViaEmail({commit}, obj){
        return PerformanceAppraisalService.getEmployeeReviewReportViaEmail(obj).then(
            async (appraisalObj)=>{

                const res = await Promise.resolve(appraisalObj);
                commit("SET_LOADING", false);
                commit("SET_REVIEW_REPORT", res);
            },
            async (error) =>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },
    getCompetenceReviewReportViaEmailWithFilter({commit}, obj){
        return PerformanceAppraisalService.getEmployeeReviewReportViaEmailwithFilter(obj).then(
            async (appraisalObj)=>{

                const res = await Promise.resolve(appraisalObj);
                commit("SET_LOADING", false);
                commit("SET_REVIEW_REPORT", res);
            },
            async (error) =>{
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },



    setEmployee({commit},obj){
        commit("SET_LOADING", true);
        commit("SET_EMPLOYEE_APPRAISAL", obj)
        commit("SET_LOADING", false);
    }

}

const getters = {
    allAppraisals(state) {
        return state.performanceAppraisal;
    },
   
    loading(state) {
        return state.loading;
    },
    error(state) {
        return state.error;
    },

    allAppraisalsWithCompetency(state){
        return state.performanceAppraisalCompetencies
    },

    allReviewedEmployees(state){
            return state.employeeCompetencies
    },

    SelectedEmployee(state){
        return state.selectedEmployeeAppraisal
    },
    getReviewReport(state){
        return state.reviewReport
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};