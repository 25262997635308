import Axios from 'axios';
const MAIN_NAMESPACE = "api/task-comments"
const SUB_NAMESPACE = "api/task-comments/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class TaskCommentServices {

    async addComment(obj) {
        return Axios.post( MAIN_API_URL , obj, {
    
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async getComment(id){

        return Axios.get( SUB_API_URL  + `${id}` , {
    
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
        
    }

}

export default new TaskCommentServices