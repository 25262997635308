import PerformancePeriodService from "../../services/performance_period.service";

const state = {
    performancePeriod: [],
    activePeriod: {},
    error: null,
    loading: false,
}

const mutations = {
    SET_PERFORMANCE_PERIOD(state, list){
        state.performancePeriod = list;
    }, 
    ADD_PERFORMANCE_PERIOD(state,obj){
            state.performancePeriod.push(obj)
    },
    SET_ERROR(state, error){
        state.error = error;
    }, 
    SET_LOADING(state, status){
        state.loading = status;
    },
    SET_ACTIVE_PERFORMANCE_PERIOD(state){
       

       let activePeriod = state.performancePeriod.find(obj =>{return  obj.status === 'Active' })

        state.activePeriod = activePeriod ;

    }

}

const actions = {
    getPerformancePeriods({commit}){
        commit("SET_LOADING", true);
        return PerformancePeriodService.getPerformancePeriod().then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_PERFORMANCE_PERIOD", res);
                commit("SET_ACTIVE_PERFORMANCE_PERIOD")
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },

   

    addPerformancePeriods({commit}, obj){
        commit("SET_LOADING", true);
        return PerformancePeriodService.addPerformancePeriod(obj).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("ADD_PERFORMANCE_PERIOD", res);
                
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },


    deletePerformancePeriodM({commit}, id){
        commit("SET_LOADING", true);
        return PerformancePeriodService.deleteOne(id).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
        
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        
        )

    },

    updatePerformancePeriodM({commit}, obj){

        commit("SET_LOADING", true);
        return PerformancePeriodService.updatePerformancePeriod(obj).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("ADD_PERFORMANCE_PERIOD", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },

    updateStatus({commit}, id){
        commit("SET_LOADING", true);
        return PerformancePeriodService.updateStatus(id).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_PERFORMANCE_PERIOD", res);
                commit("SET_LOADING", false);
               
        
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        
        )

    },
    updateDeactivateStatus({commit}, id){
        console.log("Deactivated");
        commit("SET_LOADING", true);
        return PerformancePeriodService.updateDeactivateStatus(id).then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_PERFORMANCE_PERIOD", res);
                commit("SET_LOADING", false);
               
        
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        
        )

    }

}


const getters = {
    allPerformancePeriods(state) {
        return state.performancePeriod;
    },
    activePerformancePeriods(state) {
        return state.activePeriod;
    },

    
    loading(state) {
        return state.loading;
    },
    error(state) {
        return state.error;
    },

}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


