<template>
  <div class="card tab-box">
    <div class="row user-tabs">
      <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
        <ul class="nav nav-tabs nav-tabs-bottom">
          <li class="nav-item">
            <a href="#emp_profile" data-bs-toggle="tab" class="nav-link active"
              >Profile</a
            >
          </li>
          <!-- <li class="nav-item"><a href="#emp_projects" data-bs-toggle="tab" class="nav-link">Projects</a></li>
                  <li class="nav-item"><a href="#bank_statutory" data-bs-toggle="tab" class="nav-link">Bank & Statutory <small class="text-danger">(Admin Only)</small></a></li> -->
        </ul>
      </div>
    </div>
  </div>
</template>
