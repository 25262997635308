
import FamilyService from "../../services/family.service";

const state = () => ({
    employeeFamily: [],
    error: null,
    loading: false,
});

const mutations = {
    SET_EMPLOYEE_FAMILY(state, list){
        state.employeeFamily = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state,error){
        state.error = error
    }
}


const actions = {
    addEmployeeFamily({commit},familyObject){
        commit("SET_LOADING", true);
        return FamilyService.addEmployeeFamily(familyObject).then(
            async(family)=>{
                const res = await Promise.resolve(family);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_FAMILY", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },
    

    getEmployeeFamily({commit}, employeeId){
        commit("SET_LOADING", true);
        return FamilyService.getEmployeeFamily(employeeId).then(
            async(family)=>{
                const res = await Promise.resolve(family);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_FAMILY", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            })

    },

    deleteFamilyMemberM({commit}, employeeId){

        commit("SET_LOADING", true);
        return FamilyService.deleteEmployeeFamily(employeeId).then(
            async(family)=>{
                
             
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    }
}

const getters = {
    getAllEmployeeFamily(state) {
        return state.employeeFamily;
    },
    loading(state) {
        return state.loading;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

