<template>
  <table class="table table-hover table-bordered review-table mb-0 datatable" id="Perspectivetable">
    <thead>
      <tr>
        <!-- <th style="width: 30px">#</th> -->
        <th>Perspective Name</th>
        <th>Weight</th>
        <th class="text-end">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in allPerspectives" :key="item.id">
        <!-- <td>{{ $index }}</td> -->
        <td>{{ item.name }}</td>
        <td>{{ item.weight }}%</td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" @click="openEditModelEvent(e, item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
                @click="deletePerspective(item)"
                class="dropdown-item"
                href="javascript:void(0)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
              <!-- <DeletePerspective :perspective="item" /> -->
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import util from '../../../../assets/utils/util';
export default {
 
  methods: {
    ...mapActions(["fetchPerspectives", "removePerspective"]),
    ...mapActions('perspectives2',['setEditPerspective', 'getAllPerspectives']),
    deletePerspective(perspective) {
      this.$emit('deletePerspective', perspective)
      // if (confirm("Are you sure to delete this perspective ?")) {
        this.removePerspective(perspective)
        // .then(()=>{
        //   this.getAllPerspectives();
        // })
      // }
    },

    openEditModelEvent(e,item){
      // e.preventDefault()
      console.log("Ready to Edit", item)

      this.setEditPerspective(item).then(()=>{
        this.$emit('openEditPerspectiveModal',item)
      });

      
    }
    },

  
  computed:{
    ...mapGetters('perspectives2',['allPerspectives'])
  },
  created() {
    this.fetchPerspectives();
  },
  mounted() {
    util.datatable('#Perspectivetable')
  },
};
</script>