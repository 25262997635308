<template>
  <!-- Add Strategy Modal -->
  <div id="add_objective" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Strategic Objective</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="onSubmit">
            <div class="form-group">
              <label>Objective <span class="text-danger">*</span></label>
              <input class="form-control" type="text" v-model="description" />
            </div>
            <div class="form-group">
              <label>Perspective <span class="text-danger">*</span></label>
              <select class="select form-control" v-model="perspective">
                <option value="Financial">Financial</option>
                <option value="Customer">Customer</option>
                <option value="Internal Processes">Internal Processes</option>
                <option value="Learning and Growth">Learning and Growth</option>
              </select>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" type="submit">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Strategy Modal -->

  <!-- Edit Strategy Modal -->
  <!-- <div id="edit_strategy" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Strategy</h5>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Strategy Name <span class="text-danger">*</span></label>
              <input class="form-control" value="Operations Manager" type="text" />
            </div>
            <div class="form-group">
              <label>Strategy <span class="text-danger">*</span></label>
              <select class="select">
                <option
                  v-for="item in Strategys"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.description }}
                </option>
              </select>
              <v-select
                class="select"
                placeholder="Strategy"
                label="Strategy"
                :options="Strategys"
              ></v-select>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <!-- /Edit Strategy Modal -->

  <!-- Delete Strategy Modal -->
  <div class="modal custom-modal fade" id="delete_strategy" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Strategic Objective</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Strategy Modal -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["strategy"],
  data() {
    return {
      description: "",
      perspective: {},
    };
  },
  methods: {
    ...mapActions(["fetchPerspectives", "addStrategy"]),
    onSubmit(e) {
      e.preventDefault();
      const strategy = {
        description: this.description,
        perspective_id: this.perspective.id,
        perspective: this.perspective.description,
      };
      this.addStrategy(strategy);
    },
  },
  computed: mapGetters(["allPerspectives"]),
  created() {
    this.fetchPerspectives();
  },
};
</script>