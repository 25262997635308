
import EmployeeEducationService from "../../services/employee-eductation";

const state = () => ({
    employeeEduction: [],
    error: null,
    loading: false,
});

const mutations = {
    SET_EMPLOYEE_EDUCATION(state, list){
        state.employeeEduction = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state,error){
        state.error = error
    }
}


const actions = {
    addEmployeeEducation({commit},educationObject){
        commit("SET_LOADING", true);
        return EmployeeEducationService.addEmployeeEducation(educationObject).then(
            async(education)=>{
                const res = await Promise.resolve(education);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_EDUCATION", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },
    

    getEmployeeEducation({commit}, employeeId){
        commit("SET_LOADING", true);
        return EmployeeEducationService.getEmployeeEducation(employeeId).then(
            async(education)=>{
                const res = await Promise.resolve(education);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_EDUCATION", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            })

    }
}

const getters = {
    getAllEmployeeEducation(state) {
        return state.employeeEduction;
    },
    loading(state) {
        return state.loading;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

