<template>
<div class="file-sidebar">
                  <div class="file-header justify-content-center">
                    <span>Projects</span>
                    <a href="javascript:void(0);" class="file-side-close"><i class="fa fa-times"></i></a>
                  </div>
                  <form class="file-search">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <i class="fa fa-search"></i>
                      </div>
                      <input type="text" class="form-control rounded-pill" placeholder="Search">
                    </div>
                  </form>
                  <div class="file-pro-list">
                    <div class="file-scroll">
                      <ul class="file-menu">
                        <li class="active">
                          <a href="javascript:void(0)">All Projects</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">AHF Project</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">ICC Installation</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">ESCCOM Installation</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">Virtual Host</a>
                        </li>
                      </ul>
                      <div class="show-more">
                        <a href="javascript:void(0)">Show More</a>
                      </div>
                    </div>
                  </div>
                </div>
</template>