<template>
  <div>
    <!-- Add Department Modal -->
    <div id="add_department" class="modal custom-modal fade" role="dialog"   data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Department</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit="onSubmit" id="addModal">
              <div class="form-group">
                <label>Department Name <span class="text-danger">*</span></label>
                <input class="form-control" v-model="description" type="text" />
              </div>
              <div class="submit-section">
                <button type="submit" @click=" submitAndCheck($event)"  class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Department Modal -->

    <!-- Edit Department Modal -->
    <!-- <div id="edit_department" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Department</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label>Department Name <span class="text-danger">*</span></label>
                <input class="form-control" type="text" />
              </div>
              <div class="submit-section">
                <button type="submit" class="btn btn-primary submit-btn">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Edit Department Modal -->


  </div>
  <!-- /Delete Department Modal -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["department"],
  data() {
    return {
      description: "",
      warning : "",
    };
  },
  mounted(){
    // this.getALL();
  },
  methods: {
    ...mapActions([
      "addDepartment",
      "removeDepartment",
      "updateDepartment",
      "fetchDepartments",
    ]),
    ...mapActions('departments2',['getAll']),
    // onSubmit(e) {
    //   e.preventDefault();
    //   const department = {
    //     description: this.description,
    //   };
    //   this.addDepartment(department).then(() => {
    //     $("#add_department").modal("hide");
    //     this.fetchDepartments();
    //   });
    // },
    closeModal() {
      console.log("close The Modal");
      this.$emit("closeAddDepartment");
    },
    updateDepartment() {
      const updatedDepartment = {
        id: this.department.id,
        description: this.department.description,
      };
      this.updateDepartment(updatedDepartment);
    },
    deleteDepartment() {
      // this.removeDepartment(this.department);
      console.log(this.department);
    },

    submitAndCheck(e){
      e.preventDefault();
      let found =  this.getDepartment.some(el =>{
    
       return el.description === this.description
      })
      console.log(found)

      if (!found) {
        // console.log("value does not existss")
        const department = {
        description: this.description,
      };
      this.addDepartment(department).then(() => {
        $("#add_department").modal("hide");
          this.description = "";
        this.fetchDepartments();
      });

      }else{
          this.warning = "Department Already Exists"
      }

    }
    
  },
  computed : {
    ...mapGetters('departments2', ['allDeparments']),
    getDepartment(){
      let departmentList = Object.assign([], this.allDeparments);
      return departmentList

    }
  },
  mounted() {
this.getAll()
    },
};
</script>
