
import ObjectiveService from "../../services/objective.service";


const state = () => ({
    objective: [],
    objectiveToEdit : {},
    error: null,
    loading: false,
});

const mutations = {
    SET_OBJECTIVES(state, list){
        state.objective = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },

    SET_OBJECTIVE(state,objective){
        state.objective.push(objective);
    },

    SET_EDIT_OBJECTIVE(state,objective){
        state.objectiveToEdit = objective
    },
    SET_UPDATED_OBJECTIVE(state,objective){
        const index = state.objective.findIndex(item => item.id ===objective.id)
        if(index !== -1){
            state.objective.splice(index,1,objective)
        }

    },  SET_ERROR(state, err) {
        state.error = err;
    }
};

const actions = {
    getAll({ commit }){
        commit("SET_LOADING", true);
        return ObjectiveService.getAll().then(async (depObj) => {
            const resp = await Promise.resolve(depObj);
            commit("SET_LOADING", false);
            commit("SET_OBJECTIVES", resp);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        );
    },

    add({commit},objective){
        commit("SET_LOADING", true);
        return ObjectiveService.create(objective).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_OBJECTIVE", resp);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_OBJECTIVES", err);
        }
        )
    },

    setEditObjective({commit},objective){
        commit("SET_LOADING", true);
        commit("SET_EDIT_OBJECTIVE", objective);
        commit("SET_LOADING", false);

    },

    updateObjective({commit},objective){
        return ObjectiveService.updateOne(objective).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_UPDATED_OBJECTIVE", debObj);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_OBJECTIVES", err);
        }
        )
    }
};

const getters = {
    allObjectives(state) {
        return state.objective;
    },
    depObjectives(state) {
        return state.objective;
    },
    desObjectives(state) {
        return state.objective;
    },
    loading(state){
        return state.loading;
    },
    selectedObjectiveEdit(state){
        return state.objectiveToEdit ;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};