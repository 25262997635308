import PerspectiveService from "../../services/perspectiveinformation.service";

const state = () => ({
    perspectiveObj : {},
    error: null,
    loading: false,
})


const mutations = {
    SET_PERSPECTIVES(state, obj){
        state.perspectiveObj = obj;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state, error){
        state.error = error;
    }, 

}

const actions = {
    getPerspectiveInfomation({commit},id){
        commit("SET_LOADING", true)
        return PerspectiveService.getDepartmentObjectiveInfo(id).then(async(obj)=>{
            const resp = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_PERSPECTIVES", resp);
        }).catch(async(error)=>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        })
    },
    getPerspectiveInfomationDesignation({commit},id){
        commit("SET_LOADING", true)
        return PerspectiveService.getDesignationObjectiveInfo(id).then(async(obj)=>{
            console.log("Project",obj);
            const resp = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_PERSPECTIVES", resp);
        }).catch(async(error)=>{
            console.log("Project",obj);
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        })
    }
}

const getters = {
    perspectiveGetter(state){
        return state.perspectiveObj ;
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};