<template>
  <div>
    <!-- Add Employee Modal -->
    <div id="add_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Employee</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createEmployee">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" v-model="employee.name" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" type="text" v-model="employee.surname" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="email" v-model="employee.email" />
                  </div>
                </div>
                <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <input class="form-control" type="password" v-model="employee.password"/>
                </div>
              </div> -->
                <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Confirm Password</label>
                  <input class="form-control" type="password" />
                </div>
              </div> -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon custompicker">
                      <datepicker
                        v-model="employee.created_at"
                        class="picker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" type="text" v-model="employee.phone" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Position <span class="text-danger">*</span></label>
                    <select class="form-control" v-model="employee.designation_id">
                      <option value="">Select Position</option>
                      <option
                        v-for="item in designations"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- <div class="table-responsive m-t-15">
                <table class="table table-hover table-bordered review-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Holidays</td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.holidays.read"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.holidays.write"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.holidays.create"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.holidays.delete"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.holidays.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.holidays.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Leaves</td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.leaves.read"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.leaves.write"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.leaves.create"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.leaves.delete"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.leaves.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.leaves.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Clients</td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.clients.read"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.clients.write"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.clients.create"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.clients.delete"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.clients.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.clients.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Projects</td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.projects.read"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.projects.write"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.projects.create"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.projects.delete"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.projects.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.projects.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.tasks.read"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.tasks.write"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.tasks.create"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.tasks.delete"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.tasks.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.tasks.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Chats</td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.chats.read"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.chats.write"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.chats.create"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          type="checkbox"
                          v-model="permissions.chats.delete"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.chats.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.chats.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Assets</td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.assets.read"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.assets.write"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.assets.create"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.assets.delete"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.assets.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.assets.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.timesheet.read"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.timesheet.write"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.timesheet.create"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          
                          v-model="permissions.timesheet.delete"
                          true-value="1"
                          false-value="0"
                          type="checkbox"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.timesheet.import"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                      <td class="text-center">
                        <input
                          type="checkbox"
                          v-model="permissions.timesheet.export"
                          true-value="1"
                          false-value="0"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <div class="submit-section">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Employee Modal -->

    <!-- Edit Employee Modal -->
    <div id="edit_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Employee</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="John" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" value="Doe" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input class="form-control" value="johndoe" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      class="form-control"
                      value="johndoe@example.com"
                      type="email"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <input class="form-control" value="johndoe" type="password" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Confirm Password</label>
                    <input class="form-control" value="johndoe" type="password" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Employee ID <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      value="FT-0001"
                      readonly
                      class="form-control floating"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon custompicker">
                      <datepicker
                        v-model="enddate"
                        class="picker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" value="9876543210" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Company</label>
                    <select class="select">
                      <option>ICC</option>
                      <option>AHF</option>
                      <option selected>Kings Office</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Department <span class="text-danger">*</span></label>
                    <select class="select">
                      <option>Select Department</option>
                      <option>Web Development</option>
                      <option>IT Management</option>
                      <option>Marketing</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Designation <span class="text-danger">*</span></label>
                    <select class="select">
                      <option>Select Designation</option>
                      <option>Project Manager</option>
                      <option>Operations Manager</option>
                      <option>Android Developer</option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- <div class="table-responsive m-t-15">
              <table class="table table-hover table-bordered review-table">
                <thead>
                  <tr>
                    <th>Module Permission</th>
                    <th class="text-center">Read</th>
                    <th class="text-center">Write</th>
                    <th class="text-center">Create</th>
                    <th class="text-center">Delete</th>
                    <th class="text-center">Import</th>
                    <th class="text-center">Export</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Holidays</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Leaves</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Clients</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Projects</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Tasks</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Chats</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Assets</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                  <tr>
                    <td>Timing Sheets</td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input  type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                    <td class="text-center">
                      <input type="checkbox" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
              <div class="submit-section">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Employee Modal -->

    <!-- Delete Employee Modal -->
    <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Employee</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Employee Modal -->
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      designations: [],
      employee: {},
      permissions: {
        holidays: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "Holidays",
        },
        leaves: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "Leaves",
        },
        clients: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "clients",
        },
        projects: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "projects",
        },
        tasks: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "tasks",
        },
        chats: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "chats",
        },
        assets: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "assets",
        },
        timesheet: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "timesheet",
        },
      },
    };
  },
  created() {
    axios.get("api/designation/").then((response) => {
      this.designations = response.data;
    });
  },
  // mounted() {
  //   // Select 2
  //   if ($(".select").length > 0) {
  //     $(".select").select2({
  //       minimumResultsForSearch: -1,
  //       width: "100%",
  //     });
  //   }
  // },
  methods: {
    createEmployee() {
      let theObj = Object.assign(this.employee, { permissions: this.permissions });
      axios
        .post("https://projects4ai.com/api/employee/user-permissions", theObj)
        .then((response) => {
          $("#add_employee").modal("hide");
        })
        .catch((err) => $("#add_employee").modal("hide"))
        .finally(() => (this.loading = false));
      // axios
      //   .post("https://projects4ai.com/api/employee", this.employee)
      //   .then((response) => this.$router.push({ name: "employee" }))
      //   .catch((err) => console.log(err))
      //   .finally(() => (this.loading = false));
      console.log("The permisions", this.permissions);
      console.log("The employee", this.employee);

      console.log("The final Obj", theObj);
    },
  },
};
</script>
