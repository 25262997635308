<template>
  <div v-show="getModalActive" id="education_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title"> Education Informations</h5>
                <button type="button" class="close"  @click="$.emit('closeEduction')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <!-- <div class="form-scroll"> -->
                    <div class="card">
                      <div class="card-body">
                        <h3 class="card-title">Education Informations </h3>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" v-model="education_info_1.institution" class="form-control floating">
                              <label class="focus-label">Institution</label>
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text"  v-model="education_info_1.subject"  class="form-control floating">
                              <label class="focus-label">Course</label>
                            
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon custompicker">
                            <datepicker v-model="education_info_1.start_date"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                              <label class="focus-label">Start Date</label>
                            </div>
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <div class="cal-icon custompicker">
                              <datepicker v-model="education_info_1.end_date"  class="picker" 
                              :editable="true"
                              :clearable="false" />
                              <label class="focus-label">End Date</label>
                              </div>
                             
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text" v-model="education_info_1.qualification"  class="form-control floating">
                              <label class="focus-label">Highest Level of Education Achieved</label>
                              
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">

                               <input ref="fileUpload"  type="file" @change="onFileSelected($event)"  class="form-control">
                              <!-- <label class="focus-label">Highest Level of Education Achieved</label> -->

                            </div>
                          </div>

                          <!-- <div class="col-md-6">
                            <div class="form-group form-focus focused">
                              <input type="text"  v-model="education_info_1.grade"  class="form-control floating">
                             
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                    
                  
                  <!-- </div> -->
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn" @click="submitEducation2($event)">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {toRaw} from "Vue"
import { emit } from "process";
export default {
    props : ['modalActive', 'employeeId'],
    computed :{
        getModalActive(){
            return this.modalActive
        }
    },
     data (){
        return {
            education_info_1 : {
                institution : null,
                subject : null,
                start_date : null,
                end_date : null,
                qualification : null,
                grade : null,
            },
            qualificationDocument : null
        }
     },
     methods :{
      ...mapActions('EmployeeEducation',['addEmployeeEducation', 'getEmployeeEducation']),
        submitEducation(e){
            e.preventDefault()
           let education = [toRaw(this.education_info_1), toRaw(this.education_info_2)];

           let payload = { employeeId : this.employeeId ,
                            education : education }
          //  console.log(education)

          this.education_info_1["employee_id"] =  this.employeeId;

          let edu = toRaw(this.education_info_1);

          this.addEmployeeEducation(edu).then(()=>{
            this.getEmployeeEducation({employeeId :this.employeeId});
            $("#education_info").modal("hide");
            this.education_info_1.institution = null
            this.education_info_1.subject = null
                this.education_info_1.start_date = null
                this.education_info_1.end_date = null
                this.education_info_1.qualification = null
                this.education_info_1.grade =  null
            

          });
          

          
        },

        onFileSelected(event){
            this.qualificationDocument = event.target.files[0] ;
        },

        submitEducation2(e){
          e.preventDefault()
          const  fd = new FormData();

          fd.append('institution', this.education_info_1.institution);
          fd.append('course', this.education_info_1.subject);
          fd.append('start_date', this.education_info_1.start_date);
          fd.append('end_date', this.education_info_1.end_date);
          fd.append('qualification', this.education_info_1.qualification);
          fd.append('grade', this.education_info_1.grade);
          fd.append("employee_id",  this.employeeId );
          fd.append('qualificationDocument', this.qualificationDocument, this.qualificationDocument.name)

          this.addEmployeeEducation(fd).then(()=>{
            this.getEmployeeEducation({employeeId :this.employeeId});
            $("#education_info").modal("hide");
            this.education_info_1.institution = null
            this.education_info_1.subject = null
                this.education_info_1.start_date = null
                this.education_info_1.end_date = null
                this.education_info_1.qualification = null
                this.education_info_1.grade =  null
                this.$refs.fileUpload.value = null ;
            

          });

        }
     }

}
</script>

<style>

</style>