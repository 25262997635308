import Axios from 'axios';
const MAIN_NAMESPACE = "api/performance-period"
const SUB_NAMESPACE = "api/employee-card/"
const SUB_NAMESPACE_2 = "api/performance-period/update-active-state/"
const SUB_NAMESPACE_3 = "api/performance-period/update-deactivate-state/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const SUB_API_URL_2 = `/${SUB_NAMESPACE_2}`
const SUB_API_URL_3 = `/${SUB_NAMESPACE_3}`


class PerformancePeriodService { 

    async getPerformancePeriod(){
        return await Axios.get(MAIN_API_URL ,{}).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }


    async addPerformancePeriod(obj){
        return Axios.post( MAIN_API_URL , obj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async updatePerformancePeriod(obj){
        return Axios.put( MAIN_API_URL , obj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async deleteOne(id){
        return await Axios.delete('api/performance-period/' +`${id}`, ).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async updateStatus(id){
        return Axios.put( SUB_API_URL_2 + `${id}`, null, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async updateDeactivateStatus(id){
        return Axios.put( SUB_API_URL_3 + `${id}`, null, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }



} 
export default new  PerformancePeriodService 