import Axios from 'axios';
const MAIN_NAMESPACE = "api/performance-indicators"
const SUB_NAMESPACE = "api/performance-indicator/"
const SUB_NAMESPACE2 = "api/performance-indicator"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const SUB_API_URL2 = `/${SUB_NAMESPACE2}`

class PerformanceService {  

    async getPerformanceIndicator(performanceObj) {
    
        return await Axios.get(MAIN_API_URL ,{}).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });


    }
    async addPerformanceIndicator(performanceObj) {
    
        return await Axios.post(SUB_API_URL2 , performanceObj).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });

    }
    async getSelectedPerfomanceIndicator(degsination_id) {

        return await Axios.get(SUB_API_URL2 + `/${degsination_id}`).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });


    }

}

export default new  PerformanceService


