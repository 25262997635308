<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 text-center">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Monthly Perfomance</h3>
              <div style="max-width: 568px">
                <vue3-chart-js v-if="loading" v-bind="{ ...chartData2 }" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 text-center">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Perfomance Trend</h3>
              <vue3-chart-js
              v-bind="{...lineChart2}"
                v-if="loadingLineChart"
              ></vue3-chart-js>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { mapGetters, mapActions } from "vuex";
import ChartService from "../../../services/chart.services"
import chartServices from '../../../services/chart.services';

export default {
  components: {
    Vue3ChartJs
  },

  data() {
    return {
      loading : false,
      loadingLineChart : false,
      chartData2: {
        type: "bar",
        options: {
          min: 0,
          max: 100,
          responsive: true,
          plugins: {
            legend: {
              position: "top"
            }
          }
        },

        data: {
        labels: [
   
        ],
        datasets: [
          {
            label: "High Perfomers",
            backgroundColor: [
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E"
            ],
            data: []
          },
          {
            label: "Low Perfomers",
            backgroundColor: [
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2"
            ],
            data: []
          }
        ]
      }
    },
    lineChart2 : {
        type: "line",
         data: {
           labels: [],
            datasets: [
              {
                label: "Avg Score",
                data : [],
                fill: false,
                borderColor: "#111F6E",
                tension: 0.5,
                backgroundColor: "#111F6E"
              }
            ]
         }

    }
  }
},

  mounted() {
    // this.fetchStats().then(() => {
    //   this.chartData2.data.labels = this.$store.getters["Chart/getStats"].months;
    // });

    ChartService.getStats().then(response =>{

      try{
          this.chartData2.data.labels = response.months
          this.chartData2.data.datasets[0].data = response.highest
          this.chartData2.data.datasets[1].data = response.lowest

          this.loading = true 
      }catch(e){
        console.error(e)
      }  
    })


    ChartService.getAverage().then(response =>{

      try{
        console.log("The response for aberage", response)
         let newArray = response.average.map(item=>{
          if (item == null){
            return 0
          }else{
            return parseFloat(item).toFixed(2);
          }
        })

        console.log(newArray)

          this.lineChart2.data.datasets[0].data = newArray
        this.lineChart2.data.labels = response.months

          this.loadingLineChart = true 
      }catch(e){
        console.error(e)
      } 

     })
  },
  methods: {
    ...mapActions("Chart", ["fetchStats"])
  },
  computed: {
    ...mapGetters("Chart", ["getStats"]),

    getStatisticsMethod() {

      // let list = Object.assign({}, this.$store.getters["Chart/getStats"]);
      return this.$store.getters["Chart/getStats"];
    }
  },
  setup() {
    const barChart = {
      type: "bar",
      options: {
        min: 0,
        max: 100,
        responsive: true,
        plugins: {
          legend: {
            position: "top"
          }
        }
      },
      data: {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUNE",
          "JULY",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ],
        datasets: [
          {
            label: "High Perfomers",
            backgroundColor: [
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E",
              "#111F6E"
            ],
            data: [95, 65, 40, 65, 60, 65, 90, 55, 52, 75, 80, 85]
          },
          {
            label: "Low Perfomers",
            backgroundColor: [
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2",
              "#3980C2"
            ],
            data: [15, 35, 60, 35, 40, 35, 10, 45, 48, 25, 20, 15]
          }
        ]
      }
    };
    const lineChart = {
      id: "line",
      type: "line",
      data: {
        labels: [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUNE",
          "JULY",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC"
        ],
        datasets: [
          {
            label: "Avg Score",
            data: [40, 55, 60, 45, 70, 85, 65, 50, 65, 60, 65, 70],
            fill: false,
            borderColor: "#111F6E",
            tension: 0.5,
            backgroundColor: "#111F6E"
          }
        ]
      },
      options: {
        plugins: {}
      }
    };
    return {
      barChart,
      lineChart
    };
  }
};
</script>
