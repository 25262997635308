<template>
    <table
                class="table table-hover table-bordered review-table mb-0 datatable"
                id="designationtable"
            >
                <thead>
                    <tr>
                        <th>Position</th>
                        <th>Department</th>
                        <th class="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in allDesignations" :key="item.id">
                        <td>{{ item.description }}</td>
                        <td>{{ item.department }}</td>
                        <td class="text-end">
                            <div class="dropdown dropdown-action">
                                <a
                                    href="javascript:void(0)"
                                    class="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    ><i class="material-icons">more_vert</i></a
                                >
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a
                                        class="dropdown-item"
                                        @click="openEditModelEvent(e, item)"
                                        ><i class="fa fa-pencil m-r-5"></i>
                                        Edit</a
                                    >
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="deleteDesignation(item)"
                                        ><i class="fa fa-trash-o m-r-5"></i>
                                        Delete</a
                                    >
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import util from "../../../../assets/utils/util";
export default {
    data() {
        return {
            role: "",
        };
    },
    methods: {
        ...mapActions(["fetchDesignations", "removeDesignation"]),
        ...mapActions("designations2", ["setEditDesignation"]),
        deleteDesignation(designation) {
            // if (confirm("Are you sure to delete this designation ?")) {
            this.$emit("deleteDesignation", designation);
            // this.removeDesignation(designation);
            // }
        },

        openEditModelEvent(e, item) {
            // e.preventDefault()
            console.log("Ready to Edit", item);

            this.setEditDesignation(item).then(() => {
                this.$emit("openEditDesignationModal", item);
            });
        },
    },
    computed: mapGetters(["allDesignations"]),
    created() {
        this.fetchDesignations();
    },
    mounted() {
        util.datatable("#designationtable");
        let user = JSON.parse(localStorage.getItem("user"));
        this.role = user.user.role;
    },
};
</script>
