import TaskCommentServices from "../../services/taskComments.services";

const state = () => ({
    comment: {},
    error: null,
    loading: false,
});

const mutations = {
    SET_COMMENTS(state, list){
        state.comment = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state, err) {
        state.error = err;
    },
    
}

const actions = {

    addComment({commit},obj){
        return TaskCommentServices.addComment(obj).then(async (response)=>{
            const resp = await Promise.resolve(obj);
            commit("SET_LOADING", response);
            // commit("SET_COMMENTS", resp);

        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }

        )
    },

    getComment({commit}, id){

        return TaskCommentServices.getComment(id).then(async (response)=>{
            const resp = await Promise.resolve(response);
            commit("SET_LOADING", response);
            commit("SET_COMMENTS", resp);

        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }

        )

    }

}

const getters = {

    allComments(state) {
        return state.comment;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};