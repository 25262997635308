<template>
  <table class="table table-hover table-bordered review-table mb-0 datatable" id="departmenttable">
    <thead>
      <tr>
        <!-- <th style="width: 30px">#</th> -->
        <th>Department Name</th>
        <th class="text-end">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in allDepartments" :key="item.id">
        <!-- <td>{{ $index }}</td> -->
        <td>

          <router-link :to="{ name: 'department-detail', params: { id: item.id } }"><p>{{ item.description }}</p></router-link>
        </td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a v-if="role == 'admin'"
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" @click="openEditModelEvent(e, item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a  
                @click="deleteDepartment(item)"
                class="dropdown-item"
                href="javascript:void(0)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
              <!-- <DeleteDepartment :department="item" /> -->
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import util from "../../../../assets/utils/util";
export default {
  methods: {
    ...mapActions(["fetchDepartments", "removeDepartment"]),
    ...mapActions("departments2", ["setEditDepartment"]),
    deleteDepartment(department) {
      // if (confirm("Are you sure to delete this department ?")) {
       
        this.$emit('deleteDepartment', department)
      // }
    },

    openEditModelEvent(e, item) {
      this.setEditDepartment(item).then(() => {
        this.$emit("openEditDepartmentModal", item);
      });
    },


  
  },
  computed: mapGetters(["allDepartments"]),
  created() {
    this.fetchDepartments();
  },
  data() {
    return {
      role: "",
    };
  },
  mounted() {
    util.datatable("#departmenttable");
    let user = JSON.parse(localStorage.getItem("user"));
    this.role = user.user.role;
  },
};
</script>
