import { createRouter, createWebHistory } from 'vue-router';

/********dashboard routes******/

/********activities route******/

/********knowledgebase route******/

/********jobs component route******/

/********settings component route******/

/********assets component route******/

/********leads component route******/

/********Application component route******/

/********employee component route******/

/********report component route******/


/********account component route******/

/********accounting component route******/

/********app component route******/

/********profile component route******/

/********client component route******/

/********components component route******/

/********sales component route******/

/********table component route******/

/********dashboard routes******/

/********mainpage route******/

/********form route******/

/********goals route******/

/********payroll route******/

/********performance route******/

/********project route******/

/********projects route******/

/********user route******/

/********training route******/

/********tickets route******/

/********task route******/

/********resignation route******/

/********subscriptions route******/

const routes = [
    {
        path: '/',
        name: 'login',
        component: () => import('../pages/application/login'),
        // meta: {
        //     requiresAuth: true
        // }
    },
    {
        path: '/index',
        name: 'indexs',
        component: () => import('../pages/dashboard/admin/main'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/employee',
        name: 'employee',
        component: () => import('../pages/dashboard/employee/Index'),
        //meta: {
   //         requiresAuth: true
      //  }
    },

    ,
    {
        path: '/applied-jobs',
        name: 'applied-jobs',
        component: () => import('../pages/jobs/appliedjobs'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/approval-setting',
        name: 'approval-setting',
        component: () => import('../pages/settings/approvalsetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/apptitude-result',
        name: 'apptitute-result',
        component: () => import('../pages/jobs/aptituteresult'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/archived-jobs',
        name: 'archived-jobs',
        component: () => import('../pages/jobs/archivedjobs'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/assets',
        name: 'assets',
        component: () => import('../pages/assets'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/attendance',
        name: 'attendance',
        component: () => import('../pages/employees/attendance'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/attendance-employee',
        name: 'attendance-employee',
        component: () => import('../pages/employees/attendanceemployee'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/attendance-reports',
        name: 'attendance-reports',
        component: () => import('../pages/reports/attendancereport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    /*Employee Review */
    {
        path: '/employee-review',
        name: 'employee-review',
        component: () => import('../pages/employee-review/review'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/employee-review-admin/:id',
        name: 'employee-review-admin',
        component: () => import('../pages/employee-review-admin/review'),
        //meta: {
   //         requiresAuth: true
      //  }
    }
    ,
    {
        path: '/attendance-reports',
        name: 'attendance-reports',
        component: () => import('../pages/reports/attendancereport'),
        //meta: {
   //         requiresAuth: true
      //  }
    }
    
    ,
    {
        path: '/blank-page',
        name: 'blank-page',
        component: () => import('../pages/account/blankpage'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/budget-expenses',
        name: 'budget-expenses',
        component: () => import('../pages/accounting/budgetexpenses'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/budget-revenues',
        name: 'budget-revenues',
        component: () => import('../pages/accounting/budgetrevenues'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/budgets',
        name: 'budgets',
        component: () => import('../pages/accounting/budgets'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/candidates',
        name: 'candidates',
        component: () => import('../pages/jobs/candidatelist'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('../pages/accounting/categories'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import('../pages/settings/changepassword'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/chat',
        name: 'chat',
        component: () => import('../pages/app/chat'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/client-profile',
        name: 'client-profile',
        component: () => import('../pages/profile/clientprofile'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import('../pages/clients/index'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/clients-list',
        name: 'clients-list',
        component: () => import('../pages/clients/clientlistindex'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/components',
        name: 'components',
        component: () => import('../pages/components'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/compose',
        name: 'compose',
        component: () => import('../pages/app/email/compose'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('../pages/app/contacts'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/create-estimate',
        name: 'create-estimate',
        component: () => import('../pages/sales/estimates/createestimate'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/create-invoice',
        name: 'create-invoice',
        component: () => import('../pages/sales/invoices/createinvoice'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/cron-setting',
        name: 'cron-setting',
        component: () => import('../pages/settings/cronsetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/daily-reports',
        name: 'daily-reports',
        component: () => import('../pages/reports/dailyreports'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/data-tables',
        name: 'data-tables',
        component: () => import('../pages/table/datatable'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/departments',
        name: 'departments',
        component: () => import('../pages/employees/departments'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/department-detail/:id',
        name: 'department-detail',
        component: () => import('../pages/employees/departments/departmentDetails'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/designations',
        name: 'designations',
        component: () => import('../pages/employees/designations'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/edit-estimate',
        name: 'edit-estimate',
        component: () => import('../pages/sales/estimates/editestimate'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/edit-invoice',
        name: 'edit-invoice',
        component: () => import('../pages/sales/invoices/editinvoice'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/email-settings',
        name: 'email-settings',
        component: () => import('../pages/settings/emailsettings'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/employee-reports',
        name: 'employee-reports',
        component: () => import('../pages/reports/employeereports'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/employees',
        name: 'employees',
        component: () => import('../pages/employees/mainemployee'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/employees-list',
        name: 'employees-list',
        component: () => import('../pages/employees/mainemployee/employeeslistindex'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/scorecards-list',
        name: 'scorecards-list',
        component: () => import('../pages/scorecards/scorecardlistindex'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('../pages/error/error404'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/error-500',
        name: 'error-500',
        component: () => import('../pages/error/error500'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/estimates',
        name: 'estimates',
        component: () => import('../pages/sales/estimates/mainestimate'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/estimate-view',
        name: 'estimate-view',
        component: () => import('../pages/sales/estimates/estimateview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('../pages/app/events'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/expense-reports',
        name: 'expense-reports',
        component: () => import('../pages/reports/expensereports'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/expenses',
        name: 'expenses',
        component: () => import('../pages/sales/expenses'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/experiance-level',
        name: 'experiance-level',
        component: () => import('../pages/jobs/experiancelevel'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('../pages/mainpage/faq'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/file-manager',
        name: 'file-manager',
        component: () => import('../pages/app/filemanager'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../pages/mainpage/forgotpassword'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/reset/:token/:email',
        name: 'reset',
        component: () => import('../pages/mainpage/password-reset'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/form-basic-inputs',
        name: 'form-basic-inputs',
        component: () => import('../pages/form/basicinputs'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/form-horizontal',
        name: 'form-horizontal',
        component: () => import('../pages/form/horizontal'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/form-input-groups',
        name: 'form-input-groups',
        component: () => import('../pages/form/inputgroups'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/form-mask',
        name: 'form-mask',
        component: () => import('../pages/form/formmask'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/form-validation',
        name: 'form-validation',
        component: () => import('../pages/form/formvalidation'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/form-vertical',
        name: 'form-vertical',
        component: () => import('../pages/form/formvertical'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/goal-tracking',
        name: 'goal-tracking',
        component: () => import('../pages/goals/goaltracking'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/goal-type',
        name: 'goal-type',
        component: () => import('../pages/goals/goaltype'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/holidays',
        name: 'holidays',
        component: () => import('../pages/employees/holidays'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/inbox',
        name: 'inbox',
        component: () => import('../pages/app/inbox'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/incoming-call',
        name: 'incoming-call',
        component: () => import('../pages/app/call/incomingcall'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/interviewing',
        name: 'interviewing',
        component: () => import('../pages/jobs/interviewing'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/interview-questions',
        name: 'interview-questions',
        component: () => import('../pages/jobs/interviewquestions'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/invoice-reports',
        name: 'invoice-reports',
        component: () => import('../pages/reports/invoicereport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: () => import('../pages/sales/invoices/maininvoice'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/invoice-settings',
        name: 'invoice-settings',
        component: () => import('../pages/settings/invoicesettings'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/invoice-view',
        name: 'invoice-view',
        component: () => import('../pages/sales/invoices/invoiceview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/job-applicants',
        name: 'job-applicants',
        component: () => import('../pages/jobs/jobapplicants'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/job-aptitude',
        name: 'job-aptitude',
        component: () => import('../pages/jobs/jobaptitute'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/job-details',
        name: 'job-details',
        component: () => import('../pages/jobs/jobdetails'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/job-list',
        name: 'job-list',
        component: () => import('../pages/jobs/joblist'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/jobs',
        name: 'jobs',
        component: () => import('../pages/jobs/mainjobs'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/jobs-dashboard',
        name: 'jobs-dashboard',
        component: () => import('../pages/jobs/jobsdashboard'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/job-view',
        name: 'job-view',
        component: () => import('../pages/jobs/jobview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/knowledgebase',
        name: 'knowledgebase',
        component: () => import('../pages/knowledgebase'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/knowledgebase-view',
        name: 'knowledgebase-view',
        component: () => import('../pages/knowledgebase/view'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/leads',
        name: 'leads',
        component: () => import('../pages/leads'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/leave-reports',
        name: 'leave-reports',
        component: () => import('../pages/reports/leavereport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/leaves',
        name: 'leaves',
        component: () => import('../pages/employees/leave'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/leaves-employee',
        name: 'leaves-employee',
        component: () => import('../pages/employees/leaveemployee'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/leave-settings',
        name: 'leave-settings',
        component: () => import('../pages/employees/leavesettings'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/leave-type',
        name: 'leave-type',
        component: () => import('../pages/settings/leavetype'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/localization',
        name: 'localization',
        component: () => import('../pages/settings/localization'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/lock-screen',
        name: 'lock-screen',
        component: () => import('../pages/application/lockscreen'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/mail-view',
        name: 'mail-view',
        component: () => import('../pages/app/email/mailview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/manage-resumes',
        name: 'manage-resumes',
        component: () => import('../pages/jobs/manageresume'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/notifications-settings',
        name: 'notifications-settings',
        component: () => import('../pages/settings/notification'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/offer_approvals',
        name: 'offer_approvals',
        component: () => import('../pages/jobs/offerapprove'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/offered-jobs',
        name: 'offered-jobs',
        component: () => import('../pages/jobs/offeredjob'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/otp',
        name: 'otp',
        component: () => import('../pages/application/otp'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/outgoing-call',
        name: 'outgoing-call',
        component: () => import('../pages/app/call/outgoingcall'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/overtime',
        name: 'overtime',
        component: () => import('../pages/employees/overtime'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/payments',
        name: 'payments',
        component: () => import('../pages/sales/payments'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/payments-reports',
        name: 'payments-reports',
        component: () => import('../pages/reports/paymentreport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/payroll-items',
        name: 'payroll-items',
        component: () => import('../pages/payroll/payrollitems'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/payslip-reports',
        name: 'payslip-reports',
        component: () => import('../pages/reports/payslipreport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/index2',
        name: 'newperformance',
        component: () => import('../pages/performance/index2'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/performance',
        name: 'performance',
        component: () => import('../pages/performance'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/performance/subordinatescorecard',
        name: 'subordinatescorecard',
        component: () => import('../pages/performance/subordinatescorecard'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/scorecards',
        name: 'scorecards',
        component: () => import('../pages/scorecards'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/scorecards-supervisor',
        name: 'scorecards-supervisor',
        component: () => import('../pages/scorecards/scorecardsupervisor'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/performance-appraisal',
        name: 'performance-appraisal',
        component: () => import('../pages/performance/performanceappraisal'),
        //meta: {
           requiresAuth: true,
            adminAuth : true,
          employeeAuth : false,
          superviser : true

      //  }
    },
    {
        path: '/performers',
        name: 'performers',
        component: () => import('../pages/performers'),
        meta: {
          requiresAuth: true,
          adminAuth : true,
          employeeAuth : false,
       }
    },
    {
        path: '/competencies',
        name: 'competencies',
        component: () => import('../pages/performance/competencies'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/strategy',
        name: 'strategy',
        component: () => import('../pages/performance/strategy'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/perspectives',
        name: 'perspectives',
        component: () => import('../pages/performance/perspectives'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/performance-setting',
        name: 'performance-setting',
        component: () => import('../pages/settings/performancesetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/policies',
        name: 'policies',
        component: () => import('../pages/policies'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../pages/mainpage/privacypolicy'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/profile/:id',
        name: 'profile',
        component: () => import('../pages/profile'),
        meta: {
            requiresAuth: true,
            adminAuth : true,
            employeeAuth : false,
        }
    },
      {
        path : '/disciplinary',
        name : 'disciplinary',
        component: import('../pages/discplinary'),
        meta : {
          requiresAuth: true,
          adminAuth : true,
          employeeAuth : false,
        }
      }
    ,
      {
        path : '/leave',
        name : 'leave',
        component: import('../pages/leave'),
        meta : {
          requiresAuth: true,
          adminAuth : true,
          employeeAuth : false,
        }
      }
    ,
      {
        path : '/leave-superviser',
        name : 'leave-superviser',
        component: import('../pages/leave-superviser'),
        meta : {
          requiresAuth: true,
          adminAuth : true,
          employeeAuth : false,
        }
      }
    ,
      {
        path : '/leave-employee',
        name : 'leave-employee',
        component: import('../pages/leave-employee'),
        meta : {
          requiresAuth: true,
          adminAuth : false,
          employeeAuth : true,
        }
      }
    ,
    {
        path: '/scorecard/:id',
        name: 'scorecard',
        component: () => import('../pages/scorecards/scorecard'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/project-list',
        name: 'project-list',
        component: () => import('../pages/project/projectlist'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/project-reports',
        name: 'project-reports',
        component: () => import('../pages/reports/projectreport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import('../pages/projects/mainproject'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/project-view',
        name: 'project-view',
        component: () => import('../pages/projects/projectview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/promotion',
        name: 'promotion',
        component: () => import('../pages/promotion'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/provident-fund',
        name: 'provident-fund',
        component: () => import('../pages/sales/providentfund'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/voice-call',
        name: 'voice-call',
        component: () => import('../pages/app/call/voicecall'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/visited-jobs',
        name: 'visited-jobs',
        component: () => import('../pages/jobs/visitedjobs'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/video-call',
        name: 'video-call',
        component: () => import('../pages/app/call/videocall'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('../pages/users'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/user-reports',
        name: 'user-reports',
        component: () => import('../pages/reports/userreport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/user-dashboard',
        name: 'user-dashboard',
        component: () => import('../pages/jobs/userdashboard'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/user-all-jobs',
        name: 'user-all-jobs',
        component: () => import('../pages/jobs/useralljobs'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/training-type',
        name: 'training-type',
        component: () => import('../pages/training/trainingtype'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/training',
        name: 'training',
        component: () => import('../pages/training/maintraining'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/trainers',
        name: 'trainers',
        component: () => import('../pages/training/trainers'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/toxbox-setting',
        name: 'toxbox-setting',
        component: () => import('../pages/settings/toxboxsetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/timesheet',
        name: 'timesheet',
        component: () => import('../pages/employees/timesheet'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/ticket-view',
        name: 'ticket-view',
        component: () => import('../pages/tickets/ticketview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: () => import('../pages/tickets/mainticket'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/theme-settings',
        name: 'theme-settings',
        component: () => import('../pages/settings/themesetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('../pages/mainpage/term'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/termination',
        name: 'termination',
        component: () => import('../pages/mainpage/termination'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/taxes',
        name: 'taxes',
        component: () => import('../pages/sales/taxes'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../pages/tasks'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/task-reports',
        name: 'task-reports',
        component: () => import('../pages/reports/taskreport'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/task-board',
        name: 'task-board',
        component: () => import('../pages/projects/taskboard'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/action-plans',
        name: 'action-plans',
        component: () => import('../pages/actionplans'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/action-plans-admin',
        name: 'action-plans-admin',
        component: () => import('../pages/actionplans-admin'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/employee-action-plans',
        name: 'employee-action-plans',
        component: () => import('../pages/actionplans-admin/employee-action-plan.vue'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/tables-basic',
        name: 'tables-basic',
        component: () => import('../pages/table/tablebasic'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/subscriptions-company',
        name: 'subscriptions-company',
        component: () => import('../pages/subscription/subscriptioncompany'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/subscriptions',
        name: 'subscriptions',
        component: () => import('../pages/subscription/subscriptions'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/subscribed-companies',
        name: 'subscribed-companies',
        component: () => import('../pages/subscription/subscribedcompanies'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/sub-category',
        name: 'sub-category',
        component: () => import('../pages/accounting/subcategory'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/shortlist-candidates',
        name: 'shortlist-candidates',
        component: () => import('../pages/jobs/shortlistcandidate'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/questions',
        name: 'questions',
        component: () => import('../pages/jobs/question'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../pages/application/register'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/resignation',
        name: 'resignation',
        component: () => import('../pages/resignation'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/roles-permissions',
        name: 'roles-permissions',
        component: () => import('../pages/settings/rolepermission'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/salary',
        name: 'salary',
        component: () => import('../pages/payroll/salary'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/salary-settings',
        name: 'salary-settings',
        component: () => import('../pages/settings/salarysetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/salary-view',
        name: 'salary-view',
        component: () => import('../pages/payroll/salaryview'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/saved-jobs',
        name: 'saved-jobs',
        component: () => import('../pages/jobs/savedjob'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/schedule-timing',
        name: 'schedule-timing',
        component: () => import('../pages/jobs/scheduletiming'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../pages/settings/mainsetting'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/shift-list',
        name: 'shift-list',
        component: () => import('../pages/employees/shift'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/shift-scheduling',
        name: 'shift-scheduling',
        component: () => import('../pages/employees/scheduling'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('../pages/mainpage/search'),
        //meta: {
   //         requiresAuth: true
      //  }
    },
];

export const router = createRouter({
    history: createWebHistory('/laravel-vue/orange/'),
    linkActiveClass: 'active',
    routes
});

router.beforeEach((to, from,next) => {

    const authorize = to.meta;
    const currentUser = JSON.parse(localStorage.getItem('user'));


 

    // console.log("THE ROUTER LOGGED THIS :", currentUser, "THE AUTHERIZOR :", authorize);
    if(authorize.requiresAuth ){
        if(!currentUser &&  !currentUser.token ){
            next({name:'login'})
        }else if(authorize.adminAuth){
                let role = currentUser.user.role
                if(role === 'admin' || role === 'superviser' ){
                    next()
                }else{
                    // next('employee')
                }
        }else if(authorize.employeeAuth){
            let role = currentUser.user.role
                    if(role === 'employee'){
                        next()
                    }else{
                        next('/employee')
                    }

        }

    }else{
        next()
    }


})