<template>
  <div>
    <div v-if="allFilteredEmployees.length == 0" class="row staff-grid-row">
      <div
        class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
        v-for="item in Employees"
        :key="item.id"
      >
        <div class="profile-widget">
          <div class="profile-img">
            <router-link
              :to="{ name: 'profile', params: { id: item.id } }"
              class="avatar"
            >
              <img :src="'../../../../assets/img/profiles/' + item.image" alt="" />
            </router-link>
          </div>
          <div class="dropdown profile-action">
            <a
            v-if="role == 'admin'"
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
              
                class="dropdown-item"
                href="javascript:void(0)"
               @click="$emit('editEmployeeContent', item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
              
                class="dropdown-item"
                href="javascript:void(0)"
                @click="$emit('deleteEmployeeContent', item)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
            </div>
          </div>
          <h4 class="user-name m-t-10 mb-0 text-ellipsis">
            <router-link :to="{ name: 'profile', params: { id: item.id } }"
              >{{ item.name }} <span>{{ item.surname }}</span></router-link
            >
          </h4>
          <div class="small text-muted">{{ item.designation }}</div>
        </div>
        <div class="profile-widget" v-if="role == 'employee'">
          <div class="profile-img">
            <div class="avatar">
              <img :src="'../../../../assets/img/profiles/' + item.image" alt="" />
            </div>
          </div>
          <h4 class="user-name m-t-10 mb-0 text-ellipsis">
            <router-link :to="{ name: 'profile', params: { id: item.id } }"
              >{{ item.name }} <span>{{ item.surname }}</span></router-link
            >
          </h4>
          <div class="small text-muted">{{ item.designation }}</div>
        </div>
      </div>
    </div>

    <div v-else class="row staff-grid-row">
      <div
        class="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
        v-for="item in allFilteredEmployees"
        :key="item.id"
      >
        <div class="profile-widget" v-if="role == 'admin'">
          <div class="profile-img">
            <router-link
              :to="{ name: 'profile', params: { id: item.id } }"
              class="avatar"
            >
              <img :src="'../../../../assets/img/profiles/' + item.image" alt="" />
            </router-link>
          </div>
          <div class="dropdown profile-action" v-if="role == 'admin'">
            <a
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="$emit('editEmployeeContent', item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="$emit('deleteEmployeeContent', item)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
            </div>
          </div>
          <h4 class="user-name m-t-10 mb-0 text-ellipsis">
            <router-link :to="{ name: 'profile', params: { id: item.id } }"
              >{{ item.name }} <span>{{ item.surname }}</span></router-link
            >
          </h4>
          <div class="small text-muted">{{ item.designation }}</div>
        </div>
        <div class="profile-widget" v-if="role == 'employee'">
          <div class="profile-img">
            <div class="avatar">
              <img :src="'../../../../assets/img/profiles/' + item.image" alt="" />
            </div>
          </div>
          <h4 class="user-name m-t-10 mb-0 text-ellipsis">
            <router-link :to="{ name: 'profile', params: { id: item.id } }"
              >{{ item.name }} <span>{{ item.surname }}</span></router-link
            >
          </h4>
          <div class="small text-muted">{{ item.designation }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["Employees"],
  data() {
    return {
      role: "",
    };
  },
  methods: {
    deleteEmployee(employee) {
      if (confirm("Are you sure to delete this employee ?")) {
        this.removeEmployee(employee);
      }
    },
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.role = user.user.role;
  },
  created() {},
  computed: {
    ...mapGetters("employees2", ["allEmployees", "allFilteredEmployees"]),
  },
};
</script>
