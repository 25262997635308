<template>
  <!-- Page Header -->
  <div class="page-header">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">Department</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/index">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active">Department</li>
        </ul>
      </div>
      <div v-if="role == 'admin'" class="col-auto float-end ms-auto">
        <a
          href="javascript:void(0)"
          class="btn add-btn"
          data-bs-toggle="modal"
          data-bs-target="#add_department"
          ><i class="fa fa-plus"></i> Add Department</a
        >
      </div>
    </div>
  </div>
  <!-- /Page Header -->
</template>
<script>
export default {
  data() {
    return {
      role: "",
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.role = user.user.role;
  },
};
</script>
