<template>
     <div  v-show="modalActive"  id="bank_modal" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">

            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title">Banking Detals</h5>
                <button type="button" class="close" data-bs-dismiss="modal" @click="$.emit('closeBankModal')" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <div class="row">

                    <div class="col-md-6">
                        <div class="form-group">
                                  <label>Bank <span class="text-danger">*</span></label>
                                  <input type="text"  v-model="bank_name" class="form-control">
                                </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                                  <label>Account Number <span class="text-danger">*</span></label>
                                  <input type="text" v-model="bank_account" class="form-control">
                                </div>
                    </div>
                    <div class="col-md-6">
                    <div class="form-group">
                                  <label>Bank Branch <span class="text-danger">*</span></label>
                                  <input type="text"  v-model="bank_branch" class="form-control">
                                </div>
                    </div>
                  
                  <div  class="col-md-6">
                    <div class="form-group">
                                  <label> Account Type<span class="text-danger">*</span></label>
                                  <input type="text"  v-model="acc_type" class="form-control">
                                </div>
                    </div>
              

                  </div>

                
                    <div class="submit-section">
                      <div v-if="emergencycontacts">
                        <button v-if="emergencycontacts.length === 0" class="btn btn-primary submit-btn" @click="addNewContacts($event)">Submit</button>
                     

                      
                    <button v-if="emergencycontacts.length === 0" class="btn btn-primary submit-btn" @click="addNewContacts($event)">Submit</button>
                  </div> 
                    <button v-else  class="btn btn-primary submit-btn" @click="addBank($event)">Submit</button>
                  </div>

              </div>  




            </div>
        </div>  

     </div>
  
</template>

<script>
 import { ref } from 'vue';
import { mapActions, mapGetters } from "vuex";

export default {
  methods : {
    ...mapActions('employees2',['addBankingDetails']),
        addBank(event){
          let bakObj = {
            bank_name : this.bank_name,
            bank_account: this.bank_account,
            ifsc_code : this.bank_branch ,
            pan_no : this.acc_type,
            employee_id : this.Employee.id, 
            email : this.email
          }
            console.log("The bank account",bakObj);
            this.addBankingDetails(bakObj).then(()=>{
                this.$emit('closeBankModal');
            })
        }

  },
  data(){
    return{
      bank_name : null ,
       bank_account : null, 
       bank_branch : null, 
     account_number  : null,
     acc_type : null

    }
    
  },
  computed : {
    ...mapGetters('employees2',['Employee']),
  }

 

}
</script>

