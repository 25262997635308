// GoalTracking module
import axios from 'axios';

const resource_uri = "api/goal-tracking/";

const state = {
    goal_trackings: []
};

const mutations = {
    setGoalTrackings: (state, goal_trackings) => state.goal_trackings = goal_trackings,
    newGoalTracking: (state, goal_tracking) => state.goal_trackings.push(goal_tracking),
    updGoalTracking: (state, updatedGoalTracking) => {
        const index = state.goal_trackings.findIndex(t => t.id === updatedGoalTracking.id);
        if(index !== -1) {
            state.goal_trackings.splice(index, 1, updatedGoalTracking);
        }        
    },
    deleteGoalTracking: (state, goal_tracking) => state.goal_trackings = state.goal_trackings.filter(t => goal_tracking.id !== t.id),
};

const actions = {
    async fetchGoalTrackings({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setGoalTrackings', response.data);
    },
    async addGoalTracking( { commit }, goal_tracking) {
        const response = await axios.post(resource_uri, goal_tracking);
        commit('newGoalTracking', response.data.goal_tracking);
    },
    async updateGoalTracking( { commit }, goal_tracking) {
        const response = await axios.put(`${resource_uri}${goal_tracking.id}`, goal_tracking);
        commit('updGoalTracking', response.data);
    },
    async removeGoalTracking( { commit }, goal_tracking) {
        const response = await axios.delete(`${resource_uri}${goal_tracking.id}`);
        commit('deleteGoalTracking', goal_tracking);
    }
};

const getters = {
    allGoalTrackings: state => state.goal_trackings
};

export default {
    state, 
    getters, 
    actions, 
    mutations
}