import Axios from 'axios';
const MAIN_NAMESPACE = "api/ratings"
const SUB_NAMESPACE = "api/ratings/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class RatingService {


    async createOne(ratingObj) {
        return Axios.post(MAIN_API_URL, ratingObj, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
     

    async getAll() {
        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async deleteOne(id){
        return await Axios.delete(SUB_API_URL +`${id}`, ).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async update(obj){
        return await Axios.put(SUB_API_URL +`${obj.id}`,obj ).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
}

export default new RatingService
