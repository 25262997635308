import PerspectiveService from "../../services/perspective.service";

const state = () => ({
    perspective: [],
    perspectiveToEdit : {},
    error: null,
    loading: false,
});

const mutations = {
    SET_PERSPECTIVES(state, list){
        state.perspective = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },

    SET_PERSPECTIVE(state,perspective){
        state.perspective.push(perspective);
    },

    SET_EDIT_PERSPECTIVE(state,perspective){
        state.perspectiveToEdit = perspective
    },
    SET_UPDATED_PERSPECTIVE(state,perspective){
        const index = state.perspective.findIndex(item => item.id ===perspective.id)
        if(index !== -1){
            state.perspective.splice(index,1,perspective)
        }

    }
};

const actions = {
    getAllPerspectives({ commit }){
        commit("SET_LOADING", true);
        return PerspectiveService.getAllPerspectives().then(async (depObj) => {
            const resp = await Promise.resolve(depObj);
            commit("SET_LOADING", false);
            commit("SET_PERSPECTIVES", resp);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_PERSPECTIVES", err);
            }
        );
    },

    add({commit},perspective){
        commit("SET_LOADING", true);
        return PerspectiveService.create(perspective).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_PERSPECTIVE", resp);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    },

    setEditPerspective({commit},perspective){
        commit("SET_LOADING", true);
        commit("SET_EDIT_PERSPECTIVE", perspective);

    },

    updatePerspective({commit},perspective){
        return PerspectiveService.updateOne(perspective).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_UPDATED_PERSPECTIVE", resp);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_PERSPECTIVES", err);
        }
        )
    },

    deletePerspectiveAction({commit}, debObj){
        return PerspectiveService.deletePerspective(debObj).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
          
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_PERSPECTIVES", err);
        }

        )

    }

};

const getters = {
    allPerspectives(state) {
        return state.perspective;
    },
    loading(state){
        return state.loading;
    },
    perspective(state){
        return state.perspectiveToEdit ;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};