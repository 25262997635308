<template>
  <!-- Add Goal Modal -->
  <div id="add_goal" class="modal custom-modal fade" data-bs-backdrop="static" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Goal/Objective</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit="onSubmit" id="addmodal">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Applies To</label>
                  <select
                    class="form-select"
                    v-on:change="switchSelect($event)"
                    v-model="appliesTo"
                    required
                  >
                    <!-- <option value="organization">Organization</option> -->
                    <option value="Department">Department</option>
                    <option value="Position">Position</option>
                    <!-- <option value="Individual">Individual</option> -->
                    <option value="Organizational">Organizational</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Perspective</label>
                  <select class="form-select" v-model="perspective" required>
                    <option
                      v-for="perspective in getAllPerspective"
                      :key="perspective.id"
                      :value="perspective"
                    >{{ perspective.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-12" v-show="showAppliesTo">
                <div class="form-group">
                  <label
                    class="col-form-label"
                    :hidden="appliesTo=='Organizational'"
                  >Select {{ appliesTo }}</label>

                  <!-- <select
                    class="custom-multi-select form-control"
                    v-if="appliesTo == 'Individual'"
                    v-model="employee"
                    required
                  >
                    <option
                      v-for="employee in allEmployees"
                      :key="employee.id"
                      :value="employee"
                    >
                      {{ employee.name + " " + employee.surname }}
                    </option>
                  </select>-->

                  <VueMultiselect
                    :options="allEmployees"
                    :multiple="true"
                    v-if="appliesTo == 'Individual'"
                    v-model="selectedEmployee"
                    track-by="id"
                    label="name"
                    :custom-label="empName"
                  ></VueMultiselect>

                  <select
                    class="custom-multi-select form-control"
                    v-model="designation"
                    v-else-if="appliesTo == 'Position'"
                    required
                  >
                    <option
                      v-for="designation in allDesignations"
                      :key="designation.id"
                      :value="designation"
                    >{{ designation.description }}</option>
                  </select>

                  <select
                    class="form-select"
                    v-model="department"
                    v-else-if="appliesTo == 'Department'"
                    required
                  >
                    <option
                      v-for="department in allDepartments"
                      :key="department.id"
                      :value="department"
                    >{{ department.description }}</option>
                  </select>

                  <span v-if="Organizational"></span>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="col-form-label">Goal/Objective</label>
                  <textarea class="form-control" rows="4" type="text" v-model="goal" required></textarea>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <label class="col-form-label">KPI/Measure</label>
                  <input class="form-control" type="text" v-model="kpi" required />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">Reporting Frequency</label>
                  <select class="form-select" v-model="reporting_frequency" required>
                    <option value="W">Weekly</option>
                    <option value="M" selected>Monthly</option>
                    <option value="Q">Quartely</option>
                    <option value="Y">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">
                    Target Date
                    <span class="text-danger">*</span>
                  </label>
                  <input type="date" class="form-control" v-model="target_period" />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">
                    Baseline Target
                    <span class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="number" v-model="baseline_target" required />
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">
                    Strecth Target
                    <span class="text-danger">*</span>
                  </label>
                  <input class="form-control" type="number" v-model="stretch_target" required />
                </div>
              </div>

              <div class="col-sm-4">
                <div class="form-group">
                  <label class="col-form-label">
                    Unit of Measure
                    <span class="text-danger">*</span>
                  </label>
                  <!-- <input
                    class="form-control"
                    type="text"
                    min="0"
                    max="100"
                    v-model="unit_of_measure"
                    required
                  /> -->

                  <select class="form-select" aria-label="Default select example"    v-model="unit_of_measure">
                    <option selected>Open this select menu</option>
                    <option value="Percentage">Percentage</option>
                    <option value="Number"> Number</option>
                    <option value="Time">Time</option>
                    <option value="Cost">Cost</option>
                    <option value="weight">Weight</option>
                    <option value="Distance">Distance</option>
                    <option value="Rate">Rate</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Goal Modal -->

  <!-- Edit Goal Modal -->
  <div id="edit_goal__" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Goal Tracking</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="col-form-label">Goal Type</label>
                  <select class="select" required>
                    <option selected>Invoice Goal</option>
                    <option>Event Goal</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Subject</label>
                  <input class="form-control" type="text" value="Test Goal" />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Target Achievement</label>
                  <input class="form-control" type="text" value="Lorem ipsum dollar" required />
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label>
                    Start Date
                    <span class="text-danger">*</span>
                  </label>
                  <div class="cal-icon custompicker">
                    <datepicker
                      v-model="startdate1"
                      class="picker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label>
                    End Date
                    <span class="text-danger">*</span>
                  </label>
                  <div class="cal-icon custompicker">
                    <datepicker
                      v-model="enddate1"
                      class="picker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mb-3">
                <div class="form-group">
                  <label for="customRange">Progress</label>
                  <input type="range" class="form-control-range form-range" id="customRange" />
                  <div class="mt-2" id="result">
                    Progress Value:
                    <b></b>
                  </div>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label>
                    Description
                    <span class="text-danger">*</span>
                  </label>
                  <textarea class="form-control" rows="4">Lorem ipsum dollar</textarea>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="form-group">
                  <label class="col-form-label">Status</label>
                  <select class="select">
                    <option>Active</option>
                    <option>Inactive</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Goal Modal -->

  <!-- Delete Goal Modal -->
  <div class="modal custom-modal fade" id="delete_goal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Goal Tracking List</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn">Delete</a>
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                >Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Goal Modal -->
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  components: {
    VueMultiselect
  },
  props: ["objective"],
  data() {
    return {
      name: "",
      perspective: {},
      department: {},
      designation: {},
      employee: {},
      selectedEmployee: [],
      appliesTo: "",
      showAppliesTo: false
    };
  },
  methods: {
    ...mapActions([
      "fetchDepartments",
      "fetchDesignations",
      "fetchEmployees",
      "removeObjective",
      "fetchObjectives"
    ]),
    ...mapActions("perspectives2", ["getAllPerspectives"]),
    ...mapActions("objectives", ["addObjective"]),
    ...mapActions("objectives", ["fetchObjectives"]),
    onSubmit(e) {
      e.preventDefault();
      const objective = {
        goal: this.goal,
        kpi: this.kpi,
        target_period: this.target_period,
        baseline_target: this.baseline_target,
        stretch_target: this.stretch_target,
        unit_of_measure: this.unit_of_measure,
        applies_to: this.appliesTo,
        perspective_id: this.perspective.id,
        perspective: this.perspective.name,
        reporting_frequency: this.reporting_frequency,
        department_id: this.department.id,
        designation_id: this.designation.id,
        employee_id: this.employee.id,
        employees: this.selectedEmployee
      };
   
      this.addObjective(objective).then(() => {
        this.goal = "";
        this.kpi = "";
        this.target_period = "";
        this.baseline_target = "";
        this.stretch_target = "";
        this.unit_of_measure = "";
        this.appliesTo = "";
        this.perspective_id = "";
        this.perspective = "";
        this.reporting_frequency = "";
        this.department_id = "";
        this.designation_id = "";
        this.employee_id = "";
        this.selectedEmployee = [];
        $("#add_goal").modal("hide");
        this.fetchObjectives();
      });
    },
    deleteObjective() {
      // this.removeObjective(this.objective);
      this.fetchObjectives();
      console.log(this.objective);
    },
    switchSelect(event) {
      this.appliesTo = event.target.value;
      this.showAppliesTo = true;
    },
    empName({ name, surname, designation }) {
      console.log(name, surname, designation);
      return `${name} ${surname} (${designation})`;
    }
  },
  // computed: mapGetters([
  //   "allPerspectives",

  // ]),
  computed: {
    ...mapGetters("perspectives2", ["allPerspectives"]),
    ...mapGetters(["allDepartments", "allDesignations", "allEmployees"]),
    ...mapGetters("performance",['allPerformers']),
    getAllPerspective() {
      let perspectiveList = Object.assign([], this.allPerspectives);
      return perspectiveList;
    },

    getEmloyees() {
      let comp = this.allEmployees;
      let arrayVal = [];
      comp.map(data => {
        arrayVal.push(data.name);
      });
      return arrayVal;
    }
  },
  created() {
    this.getAllPerspectives();
    this.fetchDepartments();
    this.fetchDesignations();
    this.fetchDesignations();
    this.fetchEmployees();
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
