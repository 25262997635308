
import ActionPlanService from "../../services/actionPlan.service";

const state = () => ({
    actionplan: [],
    actionPlanToEdit: {},
    error: null,
    loading: false,
});


const mutations = {
    SET_ACTIONPLANS(state, list) {
        state.actionplan = list;
    },
    SET_EDIT_ACTIONPLAN(state, actionPlan) {
        state.actionPlanToEdit = actionPlan
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ACTIONPLAN(state,actionPlan){
        state.actionPlan.push(actionPlan);
    },
    SET_ERROR(state, error) {
        state.error = error
    },

    SET_UPDATED_ACTIONPLAN(state,actionPlan){
        const index = state.actionplan.findIndex(item => item.id ===actionPlan.id)
        if(index !== -1){
            state.actionplan.splice(index,1,actionPlan)
        }

    }
}

const actions = {


    getAllActionPlans({ commit }) {
        commit("SET_LOADING", true);
        return ActionPlanService.getAll().then(
            async (actionplans) => {
                const res = await Promise.resolve(actionplans);
                commit("SET_LOADING", false);
                commit("SET_ACTIONPLANS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    
    employeeActionPlans({commit}, obj){
        commit("SET_LOADING", true);
      
       
        return ActionPlanService.getEmployee(obj.employeeId, obj.performance_period_id).then(
            async (actionplans) => {
                const res = await Promise.resolve(actionplans);
                commit("SET_LOADING", false);
                commit("SET_ACTIONPLANS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },

    employeeActionAdminPlans({commit}, obj){
        commit("SET_LOADING", true);
      
       
        return ActionPlanService.getEmployee2(obj.employeeId, obj.performance_period_id).then(
            async (actionplans) => {
                const res = await Promise.resolve(actionplans);
                commit("SET_LOADING", false);
                commit("SET_ACTIONPLANS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },


    setEditActionPlan({ commit }, actionPlan) {
        commit("SET_LOADING", true);
        commit("SET_EDIT_ACTIONPLAN", actionPlan);
        // commit("SET_LOADING", false);


    },

    updateActionPlan({ commit }, actionPlan) {
        return ActionPlanService.updateOne(actionPlan).then(async (debObj) => {

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_UPDATED_ACTIONPLAN", resp);

        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    fetchObjectivesActionPlans({commit}){
        return ActionPlanService.getAllObjectsActionPlansWithArray().then(
            async (actionplans) => {
                const res = await Promise.resolve(actionplans);
                commit("SET_LOADING", false);
                commit("SET_ACTIONPLANS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    }

};

const getters = {
    allActionplans(state) {
        return state.actionplan;
    },

    actionPlan(state) {
        return state.actionPlanToEdit
    },

    

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


