<template>
  <!-- Add Performance Appraisal Modal -->
  <Dialog
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }"
    header="Give Performance Appraisal"
  >
    <div class="modal-body">
      <form>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label class="col-form-label">Employee</label>
              <select v-model="employeeName" class="select" id="designation">
                <option disabled selected>Select DesEmployee</option>
                <option v-for="item in getEmployeeNames" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>Select Date <span class="text-danger">*</span></label>
              <div class="custompicker">
                <!-- <datepicker   class="picker" 
                          :editable="true"
                          :clearable="false" /> -->

                <input class="picker" v-model="appraisal_date" type="date" />
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">
                <div class="tab-box">
                  <div class="row user-tabs">
                    <div class="col-lg-12 col-md-12 col-sm-12 line-tabs">
                      <ul class="nav nav-tabs nav-tabs-solid">
                        <li class="nav-item">
                          <a
                            href="#appr_technical"
                            data-bs-toggle="tab"
                            class="nav-link active"
                            >Technical</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            href="#appr_organizational"
                            data-bs-toggle="tab"
                            class="nav-link"
                            >Organizational</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-content">
                  <div id="appr_technical" class="pro-overview tab-pane fade show active">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="bg-white">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Technical Competencies</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th colspan="2">Indicator</th>
                                <th colspan="2">Expected Value</th>
                                <th>Set Value</th>
                              </tr>
                              <tr>
                                <td colspan="2">Customer Experience</td>
                                <td colspan="2">
                                  {{
                                    getSelectedPerformanceIndicator.customer_experience
                                  }}
                                </td>
                                <td>
                                  <select
                                    id="appraisal_customer_experience"
                                    v-model="performance_appraisal.customer_experience"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Marketing</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.marketing }}
                                </td>
                                <td>
                                  <select
                                    name="marketing"
                                    id="appraisal_marketing"
                                    v-model="performance_appraisal.marketing"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Management</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.management }}
                                </td>
                                <td>
                                  <select
                                    name="management"
                                    id="appraisal_management"
                                    v-model="performance_appraisal.management"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Administration</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.marketing }}
                                </td>
                                <td>
                                  <select
                                    name="administration"
                                    id="appraisal_administration"
                                    v-model="performance_appraisal.administration"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Presentation Skill</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.presentation_skill }}
                                </td>
                                <td>
                                  <select
                                    name="presentation_skill"
                                    id="appraisal_presentation_skill"
                                    v-model="performance_appraisal.presentation_skill"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Quality Of Work</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.qualifty_of_work }}
                                </td>
                                <td>
                                  <select
                                    name="quality_of_work"
                                    id="appraisal_quality_of_work"
                                    v-model="performance_appraisal.quality_of_work"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Efficiency</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.efficiency }}
                                </td>
                                <td>
                                  <select
                                    name="efficiency"
                                    id="appraisal_efficiency"
                                    v-model="performance_appraisal.efficiency"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="appr_organizational">
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="bg-white">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Organizational Competencies</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th colspan="2">Indicator</th>
                                <th colspan="2">Expected Value</th>
                                <th>Set Value</th>
                              </tr>
                              <tr>
                                <td colspan="2">Integrity</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.integrity }}
                                </td>
                                <td>
                                  <select
                                    name="integrity"
                                    id="appraisal_integrity"
                                    v-model="performance_appraisal.integrity"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Professionalism</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.professionalism }}
                                </td>
                                <td>
                                  <select
                                    name="professionalism"
                                    id="appraisal_professionalism"
                                    v-model="performance_appraisal.professionalism"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Team Work</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.team_work }}
                                </td>
                                <td>
                                  <select
                                    name="team_work"
                                    id="appraisal_team_work"
                                    v-model="performance_appraisal.team_work"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Critical Thinking</td>
                                <td colspan="2">
                                  {{ getSelectedPerformanceIndicator.critical_thinking }}
                                </td>
                                <td>
                                  <select
                                    name="critical_thinking"
                                    id="appraisal_critical_thinking"
                                    v-model="performance_appraisal.critical_thinking"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Conflict Management</td>
                                <td colspan="2">
                                  {{
                                    getSelectedPerformanceIndicator.conflict_management
                                  }}
                                </td>
                                <td>
                                  <select
                                    name="conflict_management"
                                    id="appraisal_conflict_management"
                                    v-model="performance_appraisal.conflict_management"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Attendance</td>
                                <td colspan="2">Intermediate</td>
                                <td>
                                  <select
                                    name="attendance"
                                    id="appraisal_attendence"
                                    v-model="performance_appraisal.attendence"
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2">Ability To Meet Deadline</td>
                                <td colspan="2">
                                  {{
                                    getSelectedPerformanceIndicator.ability_to_meet_deadline
                                  }}
                                </td>
                                <td>
                                  <select
                                    name="ability_to_meet_deadline"
                                    id="appraisal_ability_to_meet_deadline"
                                    v-model="
                                      performance_appraisal.ability_to_meet_deadline
                                    "
                                    class="form-control"
                                  >
                                    <option
                                      v-for="(item, index) in options"
                                      :key="index"
                                      :value="item"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="form-group">
              <label class="col-form-label">Status</label>
              <select
                class="select"
                id="appraisal_status"
                v-model="performance_appraisal.status"
              >
                <option v-for="(item, index) in status" :key="index" :value="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="submit-section">
          <button class="btn btn-primary submit-btn" @click="submitAppraisal($event)">
            Submit
          </button>
        </div>
      </form>
    </div>
  </Dialog>
  <!-- /Add Performance Appraisal Modal -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["modalActive", "employees"],
  data() {
    return {
      indicators: {},
      appraisal_date: 0,
      employeeId: null,
      employeeName: null,
      performance_appraisal: {},
      allEmployees: null,
      display: true,
      options: ["None", "Beginner", "Intermediate", "Advanced", "Expert/Leader"],
      status: ["active", "Inactive"],
    };
  },
  methods: {
    ...mapActions("performanceAppraisals", ["addPerformanceAppraisal"]),
    changeEmployee(e) {
      console.log(event.target.value);
    },

    submitAppraisal(e) {
      e.preventDefault();
      // console.log("EMPLOYEE", this.selection);

      const appraisal0bj = {
        designation_id: this.getSelectedPerformanceIndicator.designation_id,
        performanc_indicators_id: this.getSelectedPerformanceIndicator.id,
        appraisal_date: this.appraisal_date,
        employee_id: this.getEmployeeId(this.employeeName),
        customer_experience: this.performance_appraisal.customer_experience,
        marketing: this.performance_appraisal.marketing,
        management: this.performance_appraisal.management,
        administration: this.performance_appraisal.administration,
        presentation_skill: this.performance_appraisal.presentation_skill,
        qualifty_of_work: this.performance_appraisal.quality_of_work,
        efficiency: this.performance_appraisal.efficiency,
        integrity: this.performance_appraisal.integrity,
        professionalism: this.performance_appraisal.professionalism,
        critical_thinking: this.performance_appraisal.critical_thinking,
        conflict_management: this.performance_appraisal.conflict_management,
        team_work: this.performance_appraisal.team_work,
        ability_to_meet_deadline: this.performance_appraisal.ability_to_meet_deadline,
        status: this.performance_appraisal.status,
      };

      this.addPerformanceAppraisal(appraisal0bj);
    },
    getEmployeeId(employeeName) {
      const empList = this.employees;
      let value;
      empList.forEach((element) => {
        if (element.name + " " + element.surname == employeeName) {
          value = element.id;
        }
      });

      return value;
    },
    getOptionsArray() {
      const thelist = this.employees;
      let arrayList = thelist.map(function (value) {
        return value;
      });

      return arrayList || [];
    },
    getDegsinationId(employeeName) {
      const empList = this.employees;
      let value;
      empList.forEach((element) => {
        if (element.name + " " + element.surname == employeeName) {
          value = element.designation_id;
        }
      });
      return value;
    },
  },

  computed: {
    ...mapGetters("performance", ["getSelectedPerformanceIndicator"]),
    getselectedId(selected) {
      const option_list = this.options;
      let value;
      option_list.forEach((element) => {
        if (element.value == selected.critical_thinking) {
          value = element.value;
        }
      });
      return value;
    },
    getEmployeeNames() {
      let employeeList = this.employees;
      let empArr = employeeList.map(function (value, index) {
        return value.name + " " + value.surname;
      });
      return empArr || [];
    },
    getIndicatorData() {
      return this.$store.getters.getSelectedPerformanceIndicator;
    },
  },
  watch: {
    modalActive: function () {
      if (this.modalActive == true) {
        this.allEmployees = this.getOptionsArray();
      } else if (this.modalActive == false) {
      }
    },
    employeeName: function (val) {
      //extract degsination_id from  data
      let data = this.getDegsinationId(val);

      //call store [dispatch idndicator/getOne, designation_id]
      this.$store.dispatch("performance/getSelectedPerformanceIndicator", data);
    },
  },
  mounted() {
    $("#appraisal_employee_id").change(
      function () {
        this.employeeId = $("#appraisal_employee_id").val();
      }.bind(this)
    );
    $("#appraisal_customer_experience").change(
      function () {
        this.performance_appraisal.customer_experience = $(
          "#appraisal_customer_experience"
        ).val();
      }.bind(this)
    );
    $("#appraisal_marketing").change(
      function () {
        this.performance_appraisal.marketing = $("#appraisal_marketing").val();
      }.bind(this)
    );
    $("#appraisal_management").change(
      function () {
        this.performance_appraisal.management = $("#appraisal_management").val();
      }.bind(this)
    );
    $("#appraisal_administration").change(
      function () {
        this.performance_appraisal.administration = $("#appraisal_administration").val();
      }.bind(this)
    );
    $("#appraisal_presentation_skill").change(
      function () {
        this.performance_appraisal.presentation_skill = $(
          "#appraisal_presentation_skill"
        ).val();
      }.bind(this)
    );
    $("#appraisal_quality_of_work").change(
      function () {
        this.performance_appraisal.quality_of_work = $(
          "#appraisal_quality_of_work"
        ).val();
      }.bind(this)
    );
    $("#appraisal_efficiency").change(
      function () {
        this.performance_appraisal.efficiency = $("#appraisal_efficiency").val();
      }.bind(this)
    );

    $("#appraisal_integrity").change(
      function () {
        this.performance_appraisal.integrity = $("#appraisal_integrity").val();
      }.bind(this)
    );
    $("#appraisal_professionalism").change(
      function () {
        this.performance_appraisal.professionalism = $(
          "#appraisal_professionalism"
        ).val();
      }.bind(this)
    );
    $("#appraisal_team_work").change(
      function () {
        this.performance_appraisal.team_work = $("#appraisal_team_work").val();
      }.bind(this)
    );
    $("#appraisal_critical_thinking").change(
      function () {
        this.performance_appraisal.critical_thinking = $(
          "#appraisal_critical_thinking"
        ).val();
      }.bind(this)
    );
    $("#appraisal_conflict_management").change(
      function () {
        this.performance_appraisal.conflict_management = $(
          "#appraisal_conflict_management"
        ).val();
      }.bind(this)
    );
    $("#appraisal_attendence").change(
      function () {
        this.performance_appraisal.attendence = $("#appraisal_attendence").val();
      }.bind(this)
    );
    $("#appraisal_ability_to_meet_deadline").change(
      function () {
        this.performance_appraisal.ability_to_meet_deadline = $(
          "#appraisal_ability_to_meet_deadline"
        ).val();
      }.bind(this)
    );
    $("#appraisal_status").change(
      function () {
        this.performance_appraisal.status = $("#appraisal_status").val();
      }.bind(this)
    );

    // Select 2
    if ($(".select").length > 0) {
      $(".select").select2({
        minimumResultsForSearch: -1,
        width: "100%",
      });
    }
  },
};
</script>
