<template>
<div class="row">
            <div class="col-md-12">
              <div class="card-group m-b-30">
                <div class="card" v-for="item in getCounters" :key="item.id">
                  <div class="card-body">
                    <div class="d-flex justify-content-between mb-3">
                      <div>
                        <span class="d-block">{{ item.name }}</span>
                      </div>
                      <div>
                        <span class="text-success">{{ item.weight }}%</span>
                      </div>
                    </div>
                    <h3 class="mb-3" v-for="item in item['counters']"
                :key="item.id">{{ item.no }}</h3>
                    <div class="progress mb-2" style="height: 5px;">
                      <div class="progress-bar bg-primary" role="progressbar" :style="{'width':item.weight +'%'}" :aria-valuenow="item.weight" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <p class="mb-0">Allocated Weight {{ item.weight }}%</p>
                  </div>
                </div>
              </div>
            </div>  
          </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
 
  methods :{
      ...mapActions('PerspectiveDashboard', ['getAllCounters']),
  
    },
    computed :{
       ...mapGetters('PerspectiveDashboard', ['getCounter']) ,
       getCounters(){
        let counterList = Object.assign({},this.getCounter )
        return counterList
      }

    },
    mounted() {
      this.getAllCounters()
    },
 
};
</script>