import RatingService from "../../services/ratings.service";

const state = () => ({
    ratings: [],
    error: null,
    loading: false,
});


const mutations = {
    SET_RATING(state, list) {
        state.ratings = list;
    },

    NEW_RATING(state,obj){
            state.ratings.push(obj)
    },
    SET_LOADING(state, status) {
        state.companyinfo = status
    },
    SET_ERROR(state,err){
        state.error = err
    }

}

const actions = {
    getAllRatings({ commit }){

        commit("SET_LOADING", true);
        return RatingService.getAll().then(async (depObj) => {
            const resp = await Promise.resolve(depObj);
            commit("SET_RATING", resp);
            commit("SET_PERSPECTIVES", resp);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        );
    },

    add({commit},perspective){
        commit("SET_LOADING", true);
        return RatingService.createOne(perspective).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("NEW_RATING", resp);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    },
    deleRatings({commit}, id){
        commit("SET_LOADING",true) ;
        return RatingService.deleteOne(id).then(async (id)=>{
                // this.getAllRatings();
                commit("SET_LOADING",false) ;
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        
        )
    },
    updateRatings({commit}, obj){
        commit("SET_LOADING",true) ;
        return RatingService.update(obj).then(async (id)=>{
            // this.getAllRatings();
            commit("SET_LOADING",false) ;
    },
    async (error) => {
        const err = await Promise.reject(error);
        commit("SET_LOADING", false);
        commit("SET_ERROR", err);
    }
    
    )
    }



}

const getters = {
    allRatings(state) {
        return state.ratings;
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

