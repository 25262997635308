<template>
<div class="col-lg-8 col-xl-9">
              <div class="card">
                <div class="card-body">
                  <div class="project-title">
                    <h5 class="card-title">ESCCOM Installation</h5>
                    <small class="block text-ellipsis m-b-15"><span class="text-xs">2</span> <span class="text-muted">open tasks, </span><span class="text-xs">5</span> <span class="text-muted">tasks completed</span></small>
                  </div>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sollicitudin libero vitae est consectetur, a molestie tortor consectetur. Aenean tincidunt interdum ipsum, id pellentesque diam suscipit ut. Vivamus massa mi, fermentum eget neque eget, imperdiet tristique lectus. Proin at purus ut sem pellentesque tempor sit amet ut lectus. Sed orci augue, placerat et pretium ac, hendrerit in felis. Integer scelerisque libero non metus commodo, et hendrerit diam aliquet. Proin tincidunt porttitor ligula, a tincidunt orci pellentesque nec. Ut ultricies maximus nulla id consequat. Fusce eu consequat mi, eu euismod ligula. Aliquam porttitor neque id massa porttitor, a pretium velit vehicula. Morbi volutpat tincidunt urna, vel ullamcorper ligula fermentum at. </p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vel elit neque. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sollicitudin libero vitae est consectetur, a molestie tortor consectetur. Aenean tincidunt interdum ipsum, id pellentesque diam suscipit ut. Vivamus massa mi, fermentum eget neque eget, imperdiet tristique lectus. Proin at purus ut sem pellentesque tempor sit amet ut lectus. Sed orci augue, placerat et pretium ac, hendrerit in felis. Integer scelerisque libero non metus commodo, et hendrerit diam aliquet. Proin tincidunt porttitor ligula, a tincidunt orci pellentesque nec. Ut ultricies maximus nulla id consequat. Fusce eu consequat mi, eu euismod ligula. Aliquam porttitor neque id massa porttitor, a pretium velit vehicula. Morbi volutpat tincidunt urna, vel ullamcorper ligula fermentum at. </p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title m-b-20">Uploaded image files</h5>
                  <div class="row">
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-4 col-lg-4 col-xl-3">
                      <div class="uploaded-box">
                        <div class="uploaded-img">
                          <img src="../../../../assets/img/placeholder.jpg" class="img-fluid" alt="">
                        </div>
                        <div class="uploaded-img-name">
                           demo.png
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title m-b-20">Uploaded files</h5>
                  <ul class="files-list">
                    <li>
                      <div class="files-cont">
                        <div class="file-type">
                          <span class="files-icon"><i class="fa fa-file-pdf-o"></i></span>
                        </div>
                        <div class="files-info">
                          <span class="file-name text-ellipsis"><a href="javascript:void(0)">AHA Selfcare Mobile Application Test-Cases.xls</a></span>
                          <span class="file-author"><a href="javascript:void(0)">Ayanda Khumalo</a></span> <span class="file-date">May 31st at 6:53 PM</span>
                          <div class="file-size">Size: 14.8Mb</div>
                        </div>
                        <ul class="files-action">
                          <li class="dropdown dropdown-action">
                            <a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:void(0)">Download</a>
                              <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>
                              <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <div class="files-cont">
                        <div class="file-type">
                          <span class="files-icon"><i class="fa fa-file-pdf-o"></i></span>
                        </div>
                        <div class="files-info">
                          <span class="file-name text-ellipsis"><a href="javascript:void(0)">AHA Selfcare Mobile Application Test-Cases.xls</a></span>
                          <span class="file-author"><a href="javascript:void(0)">Charles Nkuna</a></span> <span class="file-date">May 31st at 6:53 PM</span>
                          <div class="file-size">Size: 14.8Mb</div>
                        </div>
                        <ul class="files-action">
                          <li class="dropdown dropdown-action">
                            <a href="" class="dropdown-toggle btn btn-link" data-bs-toggle="dropdown" aria-expanded="false"><i class="material-icons">more_horiz</i></a>
                            <div class="dropdown-menu dropdown-menu-right">
                              <a class="dropdown-item" href="javascript:void(0)">Download</a>
                              <a class="dropdown-item" href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#share_files">Share</a>
                              <a class="dropdown-item" href="javascript:void(0)">Delete</a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="project-task">
                <ul class="nav nav-tabs nav-tabs-top nav-justified mb-0">
                  <li class="nav-item"><a class="nav-link active" href="#all_tasks" data-bs-toggle="tab" aria-expanded="true">All Tasks</a></li>
                  <li class="nav-item"><a class="nav-link" href="#pending_tasks" data-bs-toggle="tab" aria-expanded="false">Pending Tasks</a></li>
                  <li class="nav-item"><a class="nav-link" href="#completed_tasks" data-bs-toggle="tab" aria-expanded="false">Completed Tasks</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane show active" id="all_tasks">
                    <div class="task-wrapper">
                      <div class="task-list-container">
                        <div class="task-list-body">
                          <ul id="task-list">
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Patient appointment booking</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Appointment booking with payment gateway</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="completed task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label">Doctor available module</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Patient and Doctor video conferencing</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Private chat module</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                            <li class="task">
                              <div class="task-container">
                                <span class="task-action-btn task-check">
                                  <span class="action-circle large complete-btn" title="Mark Complete">
                                    <i class="material-icons">check</i>
                                  </span>
                                </span>
                                <span class="task-label" contenteditable="true">Patient Profile add</span>
                                <span class="task-action-btn task-btn-right">
                                  <span class="action-circle large" title="Assign">
                                    <i class="material-icons">person_add</i>
                                  </span>
                                  <span class="action-circle large delete-btn" title="Delete Task">
                                    <i class="material-icons">delete</i>
                                  </span>
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="task-list-footer">
                          <div class="new-task-wrapper">
                            <textarea  id="new-task" placeholder="Enter new task here. . ."></textarea>
                            <span class="error-message hidden">You need to enter a task first</span>
                            <span class="add-new-task-btn btn" id="add-task">Add Task</span>
                            <span class="btn" id="close-task-panel">Close</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="pending_tasks"></div>
                  <div class="tab-pane" id="completed_tasks"></div>
                </div>
              </div>
            </div>
</template>