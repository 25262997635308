import Axios from 'axios';
const MAIN_NAMESPACE = "api/designation-comptency"
const MAIN_NAMESPACE_UPDATE = "api/designation-comptency/update"
const SUB_NAMESPACE = "api/designation-comptency/"
const SUB_NAMESPACE_2 = "api/designation-comptency/designation"
const SUB_NAMESPACE_4 = "api/designation-comptency/delete"
const SUB_NAMESPACE_3 = "api/designation-comptency/competencies"
const SUB_NAMESPACE_5 = "api/designation-comptency/competencies/print" //download 
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_2 = `/${MAIN_NAMESPACE_UPDATE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`
const SUB_API_URL_1 = `/${SUB_NAMESPACE_5}`

class CompetenciesDesignationService{

        async addCompDesignation(competencyObj){
            return Axios.post(MAIN_API_URL, competencyObj, {
            }).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });
        }

         async updateCompDesignation(competencyObj){
            return Axios.post(MAIN_API_URL_2, competencyObj, {
            }).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });
        }

        async getAllCompDesignations(){
            return Axios.post(SUB_NAMESPACE_2 , null, {
            }).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });
        }
        async deleteCompDesignations(obj){
            return Axios.post(SUB_NAMESPACE_4 , obj, {
            }).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });
        }

        async getCompetencies(competencyObj){
            return Axios.post(SUB_NAMESPACE_3 , competencyObj, {
            }).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });
        }

        async downloadDocument(payload){
            return await Axios.post(SUB_API_URL_1, payload,{ responseType: "blob"}).then(
                (response)=>{
                    return response
                }  
            ).catch((error) => {
                return error
            })
    
         }

    

        
}

export default new CompetenciesDesignationService