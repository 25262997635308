<template>
  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table
          class="table table-hover table-bordered review-table mb-0 datatable"
          id="performanceappraisal"
        >
          <thead>
            <tr>
              <th style="width: 30px">#</th>
              <th>Employee</th>
              <th>Position</th>
              <th>email</th>
              <!-- <th>Date</th> -->
              <th class="text-end">Action</th>
            </tr>
          </thead>
          <tbody>

            <template v-if="getRole === 'superviser'">
            <tr  v-for="(item, index) in getSuperviserList" :key="index">
              <td>{{ ++index }}</td>
              <td>
                <h2 class="table-avatar">
                  <a href="javascript:void(0)" @click="viewReview(item)" class="avatar"
                    ><img alt=""
                  /></a>
                  <a href="javascript:void(0)" @click="viewReview(item)">
                    {{ item.name }} <span>{{ item.surname }}</span></a
                  >
                </h2>
              </td>
              <td>{{ item.designation }}</td>
              <td>{{ item.email }}</td>
              <!-- <td></td> -->
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a
                    href="javascript:void(0)"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="material-icons">more_vert</i></a
                  >
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_appraisal"
                      ><i class="fa fa-pencil m-r-5"></i> Edit</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_appraisal"
                      ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="viewReview(item)"
                      ><i class="fa fa-eye m-r-5"></i>View</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            </template>
            <template v-else>
            <tr  v-for="(item, index) in employees" :key="index">
              <td>{{ ++index }}</td>
              <td>
                <h2 class="table-avatar">
                  <a href="javascript:void(0)" @click="viewReview(item)" class="avatar"
                    ><img alt=""
                  /></a>
                  <a href="javascript:void(0)" @click="viewReview(item)">
                    {{ item.name }} <span>{{ item.surname }}</span></a
                  >
                </h2>
              </td>
              <td>{{ item.designation }}</td>
              <td>{{ item.email }}</td>
              <!-- <td></td> -->
              <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a
                    href="javascript:void(0)"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="material-icons">more_vert</i></a
                  >
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_appraisal"
                      ><i class="fa fa-pencil m-r-5"></i> Edit</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_appraisal"
                      ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="viewReview(item)"
                      ><i class="fa fa-eye m-r-5"></i>View</a
                    >
                  </div>
                </div>
              </td>
            </tr>
            </template>

          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import performanceappraisal from '../../../../assets/json/performanceappraisal.json';
import util from "../../../../assets/utils/util";
const images = require.context("../../../../assets/img/profiles", false, /\.png$|\.jpg$/);
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["appraisals", "employees", "selectedPerfomanceMonth"],
  data() {
    return {
      performanceappraisal: {},
   
    };
  },
  created() {
    // axios.get("https://projects4ai.com/api/employee/").then((response) => {
    //   this.performanceappraisal = response.data;
    // });
  },
  mounted() {
    util.datatable("#performanceappraisal");
  },
  computed: {



    ...mapGetters('Subordinates', ['allSubOrdinates']),
    getAllSubordinates(){
        let subordinates = Object.assign([], this.allSubOrdinates)
        return subordinates
    },

    getRole(){
      let user = JSON.parse(localStorage.getItem("user"));
    
    return user.user.role


    },

    getSuperviserList(){
      let subordinates = Object.assign([], this.allSubOrdinates)
      let employees = Object.assign([], this.employees)
        
      if (subordinates && subordinates.length > 0){
      const filteredEmployees =  employees.filter(employee => subordinates.map(subordinate => subordinate.id).includes(employee.id));
     return  filteredEmployees
      }else{
        return [] ;
      }

     
    }

  },
  methods: {
    ...mapActions("performanceAppraisals", ["setEmployee"]),
    //     loadImg(imgPath) {
    //         return images('./' + imgPath).default
    // },

    viewReview(item) {
      console.log("Here we are ", item);
      // this.setEmployee(item);
      // this.$emit('viewPerformanceAppraisal')
      if(this.selectedPerfomanceMonth  == null){
              let dateObj = new Date();
            let month =  (dateObj.getMonth() + 1).toString().padStart(2, "0") ; //months from 1-12
      
            let year = dateObj.getUTCFullYear();


        let monthYear = year + '-'+ month + '-00'
      
      console.log("There was null");
        this.$router.push({ name: "employee-review-admin", params: { id: item.id, performancemonth: monthYear ,  employeeName : item.name +' '+item.surnames, email : item.email } });
      }

      console.log("WE GOOD");
      this.$router.push({ name: "employee-review-admin", params: { id: item.id, performancemonth: this.selectedPerfomanceMonth , employeeName : item.name +' '+item.surname ,email : item.email } });
    },
  },
};
</script>
