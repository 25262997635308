import Axios from 'axios';

const MAIN_API_URL = "api/violation" ;
const MAIN_API_URL_1 = "api/violation/" ;
const MAIN_API_URL_2 = "api/violation/statistics" ;

class ViolationsService {


        async create(obj){
            return await Axios.post(MAIN_API_URL, obj).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            }); 
        }

        async getAll() {
            return await Axios.get(MAIN_API_URL, {
            }).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });
        }

        async updateViolation(obj){
            return await Axios.put(MAIN_API_URL_1 +`${obj.id}`, obj).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            }); 
        }
       async getViolationStats(){
           
            return await Axios.post(MAIN_API_URL_2,{}).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            }); 
        }

        async deleteViolations(){
            return await Axios.delete(MAIN_API_URL_1 +`${obj.id}`,{}).then((response) => {
                return response.data
            }).catch((error) => {
                return error
            });  
        }
}

export default new ViolationsService