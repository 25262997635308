import Axios from 'axios';
const MAIN_NAMESPACE = "api/reports-to"
const MAIN_NAMESPACE_2 = "api/superviser"
const SUB_NAMESPACE = "api/reports-to/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_2 = `/${MAIN_NAMESPACE_2}`
const SUB_API_URL = `/${SUB_NAMESPACE}`


class ReportsToService {


   async getAllSsubOrdinates(email){
        return await Axios.post(MAIN_API_URL, {email:email
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
   async getSuperviser(id){
        return await Axios.post(MAIN_API_URL_2, {id:id
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
}

export default new ReportsToService 