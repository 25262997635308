import { error } from "jquery";
import violationsService from "../../services/violations.service";
import ViolationsService from "../../services/violations.service";


const state = () => ({
    violations: [],
    error: null,
    loading: false,
});

const mutations = {

    SET_VIOLATIONS(state, list) {
        state.violations = list;
    },
    SET_VIOLATION(state, obj) {
        state.violations.push(obj);
    },
    UPDATE_VIOLATION(state,obj){
        const index = state.violations.findIndex(violation => violation.id === obj.id);

        if (index !== -1) {
            // Update the user object at the specified index
            state.violations[index] = obj;
          }
    },
    DELETE_VIOLATION(state,obj){
        const index = state.violations.findIndex(violation => violation.id === obj.id);
        if (index !== -1) {
            state.violations.splice(index, 1); 
          } else {
            // Handle object not found error (optional):
            console.warn(`Object with ID ${objectId} not found in array ${arrayName}`);
          }
    }
    ,
    
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state, err) {
        state.error = err;
    }
}

const actions = {

    getViolations({commit}){
        commit("SET_LOADING", true);
        return violationsService.getAll().then(async(obj)=>{
            const resp = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_VIOLATIONS", resp);

        }).catch(async error =>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        })


    },

    add({commit},violation){
        commit("SET_LOADING", true);
        return ViolationsService.create(violation).then(async (obj)=>{
            const resp = await Promise.resolve(obj);
            commit("SET_LOADING", false);
            commit("SET_VIOLATION", resp);

        }).catch(async error =>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        })
    },
    updateViolation({commit},violation ){
        commit("SET_LOADING", true);
        return   ViolationsService.updateViolation(violation).then(async (obj)=>{
            const resp = await Promise.resolve(obj); 
            commit("SET_LOADING", false);
            commit("UPDATE_VIOLATION", resp);
        }).catch(async error =>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        })
    },
    deleteViolations({commit}, violation){
        commit("SET_LOADING", true);
        return   ViolationsService.updateViolation(violation).then(async (obj)=>{
           
            commit("SET_LOADING", false);
            commit("DELETE_VIOLATION", violation);
        }).catch(async error =>{
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        })

    }
}

const getters = {
    allViolation(state) {
        return state.violations;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};