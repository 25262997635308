// perspective module
import axios from 'axios';

const resource_uri = "api/perspective/";

const state = {
    perspectives: []
};

const mutations = {
    setPerspectives: (state, perspectives) => state.perspectives = perspectives,
    newPerspective: (state, perspective) => state.perspectives.push(perspective),
    updPerspective: (state, updatedPerspective) => {
        const index = state.perspectives.findIndex(t => t.id === updatedPerspective.id);
        if(index !== -1) {
            state.perspectives.splice(index, 1, updatedPerspective);
        }        
    },
    deletePerspective: (state, perspective) => state.perspectives = state.perspectives.filter(t => perspective.id !== t.id),
};

const actions = {
    async fetchPerspectives({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setPerspectives', response.data);
    },
    async addPerspective( { commit }, perspective) {
        const response = await axios.post(resource_uri, perspective);
        commit('newPerspective', response.data);
    },
    async updatePerspective( { commit }, perspective) {
        const response = await axios.put(`${resource_uri}${perspective.id}`, perspective);
        commit('updPerspective', response.data);
    },
    async removePerspective( { commit }, perspective) {
        const response = await axios.delete(`${resource_uri}${perspective.id}`);
        commit('deletePerspective', response);
    }
};

const getters = {
    allPerspectives: state => state.perspectives
};

export default {
    namespaced: true,
    state, 
    getters, 
    actions, 
    mutations
}