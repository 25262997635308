import Axios from 'axios';

const MAIN_NAMESPACE = "api/company-settings"
const SUB_NAMESPACE = "api/company-settings/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class CompanySettingsService {


 async getOneById(id) {
        return await Axios.get(SUB_API_URL + `${id}`, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });
    }


    async updateOne(obj){
    
        return await Axios.put(SUB_API_URL + `${obj.id}`, obj).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error updating")
            return error
        });

    }

    async addCompanySettings(obj){
        return await Axios.post(MAIN_API_URL , obj).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });

    }

}

export default new CompanySettingsService