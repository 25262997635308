<template>
  <table class="table table-hover table-bordered review-table datatable" id="employeelistcontent">
    <thead>
      <tr>
        <th>Name</th>
        <th>Position</th>
        <th>Score</th>
        <th class="no-sort">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in Employees" :key="item.id">
        <td>
          <h2 class="table-avatar">
            <router-link  :to="{name:'profile', params:{id:item.id}}"  class="avatar"
              ><img
                alt=""
                :src="'../../../../assets/img/profiles/' + item.image"
            /></router-link>
            <router-link :to="{name:'scorecard', params:{id:item.id}}" 
              >{{ item.name }} <span>{{ item.surname }}</span></router-link
            >
          </h2>
        </td>
        <td>{{ item.designation }}</td>
        <td></td>
        <td>
          <router-link :to="{name:'scorecard', params:{id:item.id}}"><a href="javascript:void(0)" class="btn btn-primary btn-sm">View Scorecard</a></router-link>
          
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import util from '../../../assets/utils/util'
export default {
  props :["Employees"],
  methods: {
    
    deleteEmployee(employee) {
      if (confirm("Are you sure to delete this employee ?")) {
        this.removeEmployee(employee);
      }
    },
    getEmployee(id){
      console.log("Here",id);
    }
  },
  
  created() {
   
  },
  mounted() {
    util.datatable('#employeelistcontent')
  }
};
</script>