<template>
  <!-- 5 Ratings Content -->
  <div>
  <!-- <form action>
    <div class="row mb-4">
      <div class="col-md-3">
        <input
          type="text"
          class="form-control"
          required
          placeholder="Short Descriptive Word"
          v-model="ratingsObj.short_description"
        />
      </div>
      <div class="col-md-6">
        <textarea
          style="height: 44px"
          rows="4"
          cols="20"
          class="form-control"
          placeholder="	Definition"
          required
          v-model="ratingsObj.definition"
        ></textarea>
      </div>
      <div class="col-md-3">
        <button
        v-if="update==false"
          @click="saveRating($event)"
          class="btn btn-primary submit-btn create_goal_configuration_submit"
          type="submit"
        >
          Save Scale
        </button>
        <button
            v-if="update==true"
          @click="updateRating($event)"
          class="btn btn-primary submit-btn create_goal_configuration_submit"
          type="submit"
        >
          Update Scale
        </button>
      </div>
    </div>
  </form> -->
  <table
    class="table table-bordered table-hover review-table"
    v-if="getRatings.length > 0"
  >
    <thead>
      <tr>
        <th>Rating</th>
        <th>Short Descriptive Word</th>
        <th>Definition</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in getRatings" :key="item.id">
        <td style="width: 50px">
          {{ item.rate_number }}
        </td>
        <td>
          {{ item.short_description }}
        </td>
        <td>
          {{ item.definition }}
        </td>
        <td style="width: 80px; text-align: center">
          <!-- <a
            href="javascript:void(0);"
            @click="editRating($event, item)"
            class="text-warning"
            data-original-title="Edit"
          >
            <i class="fa fa-pencil"></i>
          </a>
          <a
            @click="deleteRating($event, item)"
            class="text-danger"
            title
            data-toggle="ajaxModal"
            data-original-title="Delete"
          >
            <i class="fa fa-times"></i>
          </a> -->
        </td>
      </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
import { toRaw } from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ratings table",
  data() {
    return {
      ratingsObj: {
        id : null,
        rate_number: null,
        short_description: null,
        definition: null,
      },
      update : false
    };
  },
  computed: {
    ...mapGetters("ratings", ["allRatings"]),
    getRatings() {
      let ratingList = Object.assign([], this.allRatings);
      return ratingList;
    },
  },
  methods: {
    ...mapActions("ratings", ["add", "getAllRatings", "deleRatings", "updateRatings"]),
    saveRating(e) {
      this.ratingsObj.rate_number = this.getRatings.length + 1;
      console.log("THE RATE NUMBER", this.rate_number);
      if (
        this.ratingsObj.definition == null ||
        this.ratingsObj.short_description == null
      ) {
        return;
      }
      e.preventDefault();
      let ratings = toRaw(this.ratingsObj);
      console.log("The ratings", ratings);

      this.add(ratings).then(() => {
        this.ratingsObj.short_description = null;
        this.ratingsObj.definition = null;
      });
    },
    deleteRating(e, item) {
      e.preventDefault();
      console.log("Ready to delete", item);
      this.deleRatings(item.id).then(() => {
        this.getAllRatings();
      });
    },
    editRating(e, item){
      e.preventDefault();
      console.log("The item to edit",item)
      this.ratingsObj.id = item.id;
      this.ratingsObj.definition = item.definition;
      this.ratingsObj.short_description = item.short_description;
      this.ratingsObj.rate_number = item.rate_number ;
      this.update = true;


    },
    updateRating(e){
      e.preventDefault();
      if (
        this.ratingsObj.definition == null ||
        this.ratingsObj.short_description == null
      ) {
        return;
      }

      this.updateRatings(this.ratingsObj).then(()=>{
          this.getAllRatings().then(()=>{
            this.ratingsObj.definition = null 
        this.ratingsObj.short_description = null
        this.rate_number = null
        this.update = false
          });

      })

    }
    
  },
  mounted() {
    this.getAllRatings();
  },
};
</script>

<style></style>
