
import DesignationService from "../../services/designation.service";

const state = () => ({
    designations: [],
    designationToEdit : {},
    error: null,
    loading: false,
});


const mutations = {
        SET_DESIGNATIONS(state, list){
            state.designations = list;
        },
        SET_EDIT_DESIGNATION(state,designation){
            state.designationToEdit = designation
        },
        SET_LOADING(state, status) {
            state.loading = status;
        },

        SET_ERROR(state,error){
            state.error = error
        },

        SET_UPDATED_DESIGNATION(state,designation){
            const index = state.designations.findIndex(item => item.id ===designation.id)
            if(index !== -1){
                state.designations.splice(index,1,designation)
            }
    
        }
}

const actions = {

    
    getAllDesignations({ commit }) {
        return DesignationService.getAll().then(
            async(designObj)=>{
                const res = await Promise.resolve(designObj);
                commit("SET_LOADING", false);
                commit("SET_DESIGNATIONS", res);
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }
        )
    },

    setEditDesignation({commit},designation){
        commit("SET_LOADING", true);
        commit("SET_EDIT_DESIGNATION", designation);
        commit("SET_LOADING", false);


    },

    updateDesignation({commit},designation){
        console.log(designation);
        return DesignationService.updateOne(designation).then(async (debObj)=>{

          

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_UPDATED_DESIGNATION", debObj);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_ERROR", err);
        }

        )
    }

    };

const getters = {
    allDesignations(state) {
        return state.designations;
    },

    selectedDesignationEdit(state){
        return state.designationToEdit
    }
   

};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


