import Axios from 'axios';

const MAIN_NAMESPACE = "api/chart";
const MAIN_NAMESPACE_2 = "api/chart-employee";
const MAIN_NAMESPACE_AVG = "api/performance-trend";
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_2 = `/${MAIN_NAMESPACE_2}`
const MAIN_API_URL_AVG = `/${MAIN_NAMESPACE_AVG}`

class ChartService {


    async getStats() {

        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });
    }
    async getEmployeeStats(obj) {

        return await Axios.post(MAIN_API_URL_2, obj).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });
    }

   async getAverage(){
        return await Axios.get(MAIN_API_URL_AVG, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });
    }
    
}

export default new ChartService

