// Department module
import axios from 'axios';

const resource_uri = "api/department/";

const state = {
    departments: []
};

const mutations = {
    setDepartments: (state, departments) => state.departments = departments,
    newDepartment: (state, department) => state.departments.push(department),
    updDepartment: (state, updatedDepartment) => {
        const index = state.departments.findIndex(t => t.id === updatedDepartment.id);
        if(index !== -1) {
            state.departments.splice(index, 1, updatedDepartment);
        }        
    },
    deleteDepartment: (state, department) => state.departments = state.departments.filter(t => department.id !== t.id),
};

const actions = {
    async fetchDepartments({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setDepartments', response.data);
    },
    async addDepartment( { commit }, department) {
        const response = await axios.post(resource_uri, department);
        commit('newDepartment', response.data);
    },
    async updateDepartment( { commit }, department) {
        const response = await axios.put(`${resource_uri}${department.id}`, department);
        commit('updDepartment', response.data);
    },
    async removeDepartment( { commit }, department) {
        const response = await axios.delete(`${resource_uri}${department.id}`);
        commit('deleteDepartment', department);
    }
};

const getters = {
    allDepartments: state => state.departments
};

export default {
    state, 
    getters, 
    actions, 
    mutations
}