<template>
  <section class="review-section information">
    <div class="review-header text-center">
      <h3 class="review-title">Employee Basic Information</h3>
      <p class="text-muted">Lorem ipsum dollar</p>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="table-responsive">
          <table class="table table-bordered table-nowrap review-table mb-0">
            <tbody>
              <tr>
                <td>
                  <form>
                    <div class="form-group">
                      <label for="name">Name</label>
                      <!-- <input type="text" class="form-control" id="name"> -->
                      <select
                        class="form-control inputWidth"
                        v-model="selectedEmployee"
                        @change="ChangedEmployee($event)"
                      >
                        <option selected disabled>Select Employee</option>
                        <option
                          v-for="(item, index) in employees"
                          :key="index"
                          :value="item"
                        >
                          {{ item.name }} {{ item.surname }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="depart3">Department</label>
                      <input
                        type="text"
                        v-model="department"
                        class="form-control"
                        id="depart3"
                      />
                    </div>
                    <div class="form-group">
                      <label for="departa">Position</label>
                      <input
                        type="text"
                        v-model="designation"
                        class="form-control"
                        id="departa"
                      />
                    </div>
                    <div class="form-group">
                      <label for="qualif">Qualification: </label>
                      <input type="text" class="form-control" id="qualif" />
                    </div>
                  </form>
                </td>
                <td>
                  <form>
                    <div class="form-group">
                      <label for="doj">Emp ID</label>
                      <input type="text" v-model="employee_id" class="form-control" />
                    </div>
                    <div class="form-group">
                      <label for="doj">Date of Join</label>
                      <input type="text" class="form-control" id="doj" />
                    </div>
                    <div class="form-group">
                      <label for="doc">Date of Confirmation</label>
                      <input type="text" class="form-control" id="doc" />
                    </div>
                    <div class="form-group">
                      <label for="qualif1">Previous years of Exp</label>
                      <input type="text" class="form-control" id="qualif1" />
                    </div>
                  </form>
                </td>
                <td>
                  <form>
                    <div class="form-group">
                      <label for="name1"> RO's Name</label>
                      <input type="text" class="form-control" id="name1" />
                    </div>
                    <div class="form-group">
                      <label for="depart1"> RO Designation: </label>
                      <input type="text" class="form-control" id="depart1" />
                    </div>
                  </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
export default {
  props: ["employees"],
  data() {
    return {
      selectedEmployee: null,
      designation: null,
      department: null,
      employee_id: null,
    };
  },
  components: {},
  mounted() {},
  methods: {
    ChangedEmployee(event) {
      console.log(
        "This has been selected",
        event.target.value,
        "Selected employee :",
        this.selectedEmployee.id
      );
      this.designation = this.selectedEmployee.designation;
      this.department = this.selectedEmployee.department;
      this.employee_id = this.selectedEmployee.id;
    },
  },
};
</script>

<style scoped>
.inputWidth {
  width: 80%;
}
</style>
