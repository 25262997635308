import Axios from 'axios';

const MAIN_NAMESPACE = "api/basic-settings"
const SUB_NAMESPACE = "api/basic-setting/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`


class BasicSettingsService {

    async getOneById(id) {
        return await Axios.get(SUB_API_URL + `${id}`, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            console.log("There was an error")
            return error
        });
    }



}

export default new BasicSettingsService