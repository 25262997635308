// Designation module
import axios from 'axios';

const resource_uri = "api/designation/";

const state = {
    designations: []
};

const mutations = {
    setDesignations: (state, designations) => state.designations = designations,
    newDesignation: (state, designation) => state.designations.push(designation),
    updDesignation: (state, updatedDesignation) => {
        const index = state.designations.findIndex(t => t.id === updatedDesignation.id);
        if(index !== -1) {
            state.designations.splice(index, 1, updatedDesignation);
        }        
    },
    deleteDesignation: (state, designation) => state.designations = state.designations.filter(t => designation.id !== t.id),
};

const actions = {
    async fetchDesignations({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setDesignations', response.data);
    },
    async addDesignation( { commit }, designation) {
        const response = await axios.post(resource_uri, designation);
        commit('newDesignation', response.data.designation);
    },
    async updateDesignation( { commit }, designation) {
        const response = await axios.put(`${resource_uri}${designation.id}`, designation);
        commit('updDesignation', response.data);
    },
    async removeDesignation( { commit }, designation) {
        const response = await axios.delete(`${resource_uri}${designation.id}`);
        commit('deleteDesignation', designation);
    }
};

const getters = {
    allDesignations: state => state.designations
};

export default {
    state, 
    getters, 
    actions, 
    mutations
}