<template>
<!-- Page Header -->
       <div class="page-header">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Competence Review</h3>
                <ul class="breadcrumb">
                  <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                  <li class="breadcrumb-item active">Competence Review</li>
                </ul>
              </div>
              <div class="col-auto float-end ms-auto">
                <a  @click="$emit('openModal')" class="btn add-btn" ><i class="fa fa-plus"></i> Add New</a>
              </div>
            </div>
       </div>
       <!-- /Page Header -->
</template>