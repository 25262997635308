<template>
<div class="row">
            <div class="col-md-12">
              <div class="welcome-box">
                <div class="welcome-img">
                  <img alt="" src="../../../../assets/img/profiles/avatar-02.jpg">
                </div>
                <div class="welcome-det">
                  <!-- <h3>Welcome, John Doe</h3> -->
                  <!-- <p>Monday, 20 May 2019</p> -->
                </div>
              </div>
            </div>
          </div>
</template>