
import EmployeeExperienceService from "../../services/employee-experience";

const state = () => ({
    employeeExperience: [],
    error: null,
    loading: false,
});

const mutations = {
    SET_EMPLOYEE_EXPERIENCE(state, list){
        state.employeeExperience = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },
    SET_ERROR(state,error){
        state.error = error
    }
}


const actions = {
    addEmployeeExperience({commit},expObject){
        commit("SET_LOADING", true);
        return EmployeeExperienceService.addEmployeeExperience(expObject).then(
            async(experience)=>{
                const res = await Promise.resolve(experience);
                commit("SET_LOADING", false);
                // commit("SET_EMPLOYEE_EXPERIENCE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    getEmployeeExperience({commit}, employeeId){
        commit("SET_LOADING", true);
        return EmployeeExperienceService.getEmployeeExperience(employeeId).then(
            async(experience)=>{
                const res = await Promise.resolve(experience);
                commit("SET_LOADING", false);
                commit("SET_EMPLOYEE_EXPERIENCE", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            })

    }
}

const getters = {
    getAll(state) {
        return state.employeeExperience;
    },
    loading(state) {
        return state.loading;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

