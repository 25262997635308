<template>



                            <div v-show="OpenClose" id="add_basicSettings" class="modal custom-modal fade show" role="dialog">
                                <div class="modal-dialog modal-dialog-centered" role="document" style="display:block">
                                    <div v-show="OpenClose" class="modal-content">
                                        <div class="modal-header">
                                                <h5 class="modal-title">Add Leave Type</h5>
                                                <button type="button" class="close"  @click="props.close_func" data-bs-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                        </div>
                                        <slot name="body"></slot>
                                          
                                    </div>

                                </div>
                            </div> 

      
    
</template>
<script>
    export default {
        name : 'ModalbasicSetting',
        props: {
            visible:Boolean,
            close_func:Function
            // basicSettings : {}
        },
        data(){
            return {
                OpenClose : false
            }
        },
        methods: {
            OpenCloseFun(){
                this.OpenClose = !this.OpenClose;
            }
        },
        watch :{
            visible : function(newVal, oldVal){
                this.OpenClose = newVal;
                console.log("New Value " + newVal + "   Old Value "+ oldVal);
            }
        }

    }

</script>