import DepartmentService from "../../services/department.service";

const state = () => ({
    department: [],
    departmentToEdit : {},
    error: null,
    loading: false,
});

const mutations = {
    SET_DEPARTMENTS(state, list){
        state.department = list;
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },

    SET_DEPARTMENT(state,department){
        state.department.push(department);
    },

    SET_EDIT_DEPARTMENT(state,department){
        state.departmentToEdit = department
    },
    SET_UPDATED_DEPARTMENT(state,department){
        const index = state.department.findIndex(item => item.id ===department.id)
        if(index !== -1){
            state.department.splice(index,1,department)
        }

    }
};

const actions = {
    getAll({ commit }){
        commit("SET_LOADING", true);
        return DepartmentService.getAll().then(async (depObj) => {
            const resp = await Promise.resolve(depObj);
            commit("SET_LOADING", false);
            commit("SET_DEPARTMENTS", resp);
        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_DEPARTMENTS", err);
            }
        );
    },

    add({commit},department){
        commit("SET_LOADING", true);
        return DepartmentService.create(department).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_DEPARTMENT", resp);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_DEPARTMENTS", err);
        }
        )
    },

    setEditDepartment({commit},department){
        commit("SET_LOADING", true);
        commit("SET_EDIT_DEPARTMENT", department);

    },

    updateDepartment({commit},department){
        return DepartmentService.updateOne(department).then(async (debObj)=>{

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_UPDATED_DEPARTMENT", debObj);
                
        },
        async (error) => {
            const err = await Promise.reject(error);
            commit("SET_LOADING", false);
            commit("SET_DEPARTMENTS", err);
        }
        )
    }
};

const getters = {
    allDeparments(state) {
        return state.department;
    },
    loading(state){
        return state.loading;
    },
    department(state){
        return state.departmentToEdit ;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};