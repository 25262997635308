import Axios from 'axios';
const MAIN_NAMESPACE = "api/designation"
const SUB_NAMESPACE = "api/designation/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`



class DesignationService {

    async getAll() {
        return await Axios.get(MAIN_API_URL, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

  async  updateOne(designation){
        return await Axios.put(SUB_API_URL + `${designation.id}`, designation).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
}    

export default new DesignationService