<template>
  <table
    class="table table-bordered review-table mb-0 datatable"
    id="strategytable"
  >
    <thead>
      <tr>
        <!-- <th style="width: 30px">#</th> -->
        <th>Perspective</th>
        <th>Strategic Objective</th>
        <th class="text-end">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td rowspan="4">Financial</td>
        <td>To analyze working capital and advise management on the level of healthy <br>working capital (adequate WC to meet fixed costs for 6 months) </td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                @click=" openEditModelEvent(e,item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="deleteObjective(item)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>To maintain a turnaround time of 3 months from invoicing to payment by clients 100% </td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                @click=" openEditModelEvent(e,item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="deleteObjective(item)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>To produce management accounts monthly by the 10th of the following month, covering the following: 100%</td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                @click=" openEditModelEvent(e,item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="deleteObjective(item)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>To monitor financial commitments through Purchase Orders (PO) <br>issued POs when issued to be visible to the Finance Department for the purpose of drawing a month end payment plan) </td>
        <td class="text-end">
          <div class="dropdown dropdown-action">
            <a
              href="javascript:void(0)"
              class="action-icon dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              ><i class="material-icons">more_vert</i></a
            >
            <div class="dropdown-menu dropdown-menu-right">
              <a
                class="dropdown-item"
                @click=" openEditModelEvent(e,item)"
                ><i class="fa fa-pencil m-r-5"></i> Edit</a
              >
              <a
                class="dropdown-item"
                href="javascript:void(0)"
                @click="deleteObjective(item)"
                ><i class="fa fa-trash-o m-r-5"></i> Delete</a
              >
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import util from '../../../../assets/utils/util';
export default {
 
  methods: {
    ...mapActions(["fetchObjectives", "removeObjective"]),
    ...mapActions('objectives2',['setEditObjective']),
    deleteObjective(objective) {
      if (confirm("Are you sure to delete this objective ?")) {
        this.removeObjective(objective);
      }
    },



    openEditModelEvent(e,item){
      // e.preventDefault()
      console.log("Ready to Edit", item)

      this.setEditObjective(item).then(()=>{
        this.$emit('openEditObjectiveModal',item)
      });

      
    }
    
  },
  computed: mapGetters(["allObjectives"]),
  created() {
    this.fetchObjectives();
  },
  mounted() {
    util.datatable('#strategytable')
  },
};
</script>