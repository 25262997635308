<template>
   <div v-show="modalActive" id="profile_info" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Profile Information</h5>
                <button type="button" class="close" @click="close($event)" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="profile-img-wrap edit-img">
                        <img class="inline-block" src="../../../assets/img/profiles/avatar-02.jpg" alt="user">
                        <div class="fileupload btn">
                          <span class="btn-text">edit</span>
                          <input class="upload" type="file">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>First Name</label>
                            <input type="text" class="form-control" v-model=" getProfile.name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Last Name</label>
                            <input type="text" class="form-control" v-model=" getProfile.surname">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Birth Date</label>
                             
                          <!-- <datepicker v-model="getProfile.dob"  class="picker" 
                          :editable="true"
                          :clearable="false" /> -->
                          <input type="date" class="form-control custompicker" v-model="getProfile.dob"  />
                           
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Gender</label>
                            <select  v-model="getProfile.gender" class="select form-control">
															<option value="male selected">Male</option>
															<option value="female">Female</option>
														</select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Physical Address</label>
                        <input type="text" class="form-control" v-model=" getProfile.address">
                      </div>
                    </div>
          
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Country</label>
                        <input type="text" class="form-control" v-model=" getProfile.country">
                      </div>
                    </div>
                   
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Phone Number</label>
                        <input type="text" class="form-control" v-model=" getProfile.phone" >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Department <span class="text-danger">*</span></label>
                        <select class="select"  v-model=" getProfile.department_id" :key="id">
													<option v-for="item in departments" :key="item.id" :value="item.id">{{item.description}}</option>
											
												</select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Designation <span class="text-danger">*</span></label>
                        <select class="select" v-model=" getProfile.designation_id"  id="designation">
													<option v-for="item in designations" :key="item.id" :value="item.id">{{item.description}}</option>
												
												</select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Reports To <span class="text-danger">*</span></label>
                        <select id="reports_to"  class="select" v-model="selected_reports_to_id" :key="id" >
                         
													<option v-for="item in getDesignation" :key="item.id" :value="item.id">{{item.description}}</option>
												
												</select>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn" @click="saveEmployeeProfile($event, getProfile)">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import { mapGetters, mapActions  } from "vuex";
export default {

    name: 'profile',
    props : ['modalActive', 'profile',"designations","departments",'employees'],
    setup(props,{emit}){
      const close = () =>{
        emit('close')
      }

      return {close}
    },
    methods :{
        ...mapActions('employees2',['UpdateEmployee']),
        ...mapActions('Subordinates', ['getSuperviser']),
        saveEmployeeProfile(e,profile){
          e.preventDefault();

          if(this.selected_reports_to_id !== null || this.selected_reports_to_id !== undefined){
            profile.reports_to_empid =  this.selected_reports_to_id

          }



            if(this.designation_id !== null || this.designation_id !== undefined){
            profile.designation_id =  this.designation_id
            }

          console.log("reportsToID", this.selected_reports_to_id, 'The profile', profile)

        
          this.UpdateEmployee(profile).then(()=>{
            // $('#profile_info').modal('hide');
            this.close();
            this.getSuperviser(profile.id);
          });
          // console.log("The Log",profile)
          //Update the employee coz its an existing profile]

        


          

        },
        changedReportingTo(event){
            console.log("changed Reporting To", this.selected_reports_to_id)

          }
     

    },
    data(){
              return {
                  id :"",
                  selected_reports_to_id : null,
                  designation_id : null
              }
        },
   
    created(){
            // this.fetchDesignations();
    },
    //  computed : mapGetters(['allDesignations'])
    computed : {
      
      ...mapGetters('Subordinates', ['Superviser']),
      getEmployees(){
        let employeesArray = this.employees;
        return employeesArray ;
      },


      getSuperVisers(){

        let designations = Object.assign({},this.Superviser)
          return designations  ;

      },
      getDesignation(){
        let designations = Object.assign({},this.designations)
          return designations  ;
      },



      getProfile(){
          let profileData = Object.assign({},this.profile)
          return profileData ;
      },

      selectedID: function () {
      return this.selected_reports_to_id;
    }
    },
    mounted(){
      $("#reports_to").change(
      function () {
        this.selected_reports_to_id = $("#reports_to").val();
      }.bind(this)
    );
      $("#designation").change(
      function () {
        this.designation_id = $("#designation").val();
      }.bind(this)
    );
    }
    ,
    watch : {
      modalActive : function() {
        this.selected_reports_to_id = this.getSuperVisers.id
      }
    }

}
</script>

<style>

</style>