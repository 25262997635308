<template>
  <div class="row">
    <div class="col-md-12">
      <div class="kanban-board card mx-4">
        <div class="card-body">
          <div class="kanban-cont">
            <!-- <template v-for="data in employeeScoreCards" > -->
            <div class="kanban-list kanban-info" v-for="item in allActionplans" :key="item.id">
              <div class="kanban-header">
                <span class="status-title">{{ item.goal }}</span>
              </div>
              <!-- <template v-for="data,index in allActionplans" :key="index"> -->
              <div class="kanban-wrap" v-for="item in item['action_plans']" :key="item.id">
                <div class="card panel">
                  <div class="kanban-box">
                    <div class="task-board-header">
                      <span class="status-title"><router-link to="/task-view">{{
                        item.task_name
                      }}</router-link></span>
                      <div class="dropdown kanban-task-action">
                        <a href="" data-bs-toggle="dropdown">
                          <i class="fa fa-angle-down"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right">
                          <a class="dropdown-item" @click="openEditModelEvent(e, item)">Edit</a>
                          <a class="dropdown-item" @click="deleteActionPlan(item)"> Delete</a>
                        </div>
                      </div>
                    </div>
                    <div class="task-board-body">
                      <div class="kanban-info">
                        <div class="progress progress-xs">
                          <div class="progress-bar bg-primary" role="progressbar" :style="{'width':item.progress +'%'}" :aria-valuenow="item.progress" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span>{{ item.progress }}%</span>
                      </div>
                      <div class="kanban-footer">
                        <span class="task-info-cont">
                          <span class="task-date"><i class="fa fa-clock-o"></i> {{ item.due_date }}</span>
                          <span class="task-priority badge bg-inverse-danger">{{
                            item.task_priority
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </template> -->
              <div class="add-new-task">
                <a href="javascript:void(0);" @click="addNewTask(item)">Add Action Plan</a>
              </div>
            </div>
            <!-- </template> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, toRaw } from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["employees", "employeeScoreCards"],

  components: {

  },
  methods: {
    ...mapActions("employees2", ["getEmployeeScorecard"]),
    // ...mapActions("actionplans", ['fetchActionPlans']),
    ...mapActions("actionplans",["removeActionPlan"]),
    ...mapActions("actionplans2", ['fetchObjectivesActionPlans', 'setEditActionPlan', "employeeActionPlans"]),
   

    // deleteActionPlan(actionPlan) {      
    //     this.$emit('deleteActionPlan', actionPlan)
    // },
    deleteActionPlan(actionPlan) {
            if (confirm("Are you sure to delete this Task ?")) {
                this.removeActionPlan(actionPlan).then(() => {

                  let user = JSON.parse(localStorage.getItem("user"));

let employeeId = user.user.id;
let obj = {
  employeeId: employeeId,
  performance_period_id: this.getActivePerformancePeriod.id
};
this.employeeActionPlans(obj)
        


      });
            }
          },

    addNewTask(data) {
      console.log("inner coponent", data);
      let obj = toRaw(data);
      this.$emit("add_task_modal", obj);
    },

    openEditModelEvent(e, item) {
      // e.preventDefault()
      console.log("Ready to Edit", item)

      this.setEditActionPlan(item).then(() => {
        this.$emit('openEditPerspectiveModal', item)
      });
    }
  },
  computed: {
    ...mapGetters("PerformancePeriod", [
      "allPerformancePeriods",
      "activePerformancePeriods"
    ]),
    ...mapGetters("actionplans2", ["allActionplans"]),
    getActivePerformancePeriod() {
      let performancePeriod = Object.assign({}, this.activePerformancePeriods);
      return performancePeriod;
    },
    getEmployeeScorecards() {
      let employeescoreCardObj = Object.assign({}, this.employeeScoreCards);
      return employeescoreCardObj;
    },
  },

  created() {
    // this.fetchActionPlans();
  },
  mounted() {
    // this.getEmployeeScorecard();
    // this.fetchObjectivesActionPlans()
  },
};
</script>
