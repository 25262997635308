<template>
  <div class="row">
    <div class="col-md-6 d-flex">
      <div class="card card-table flex-fill">
        <div class="card-header">
          <h3 class="card-title mb-0">High Perfomers</h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table v-if="loading" class="table custom-table mb-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Score</th>
                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in highPerformers" :key="item.id">
                  <td>
                    <h2 class="table-avatar">
                      <a href="javascript:void(0)" class="avatar">
                        <img alt :src="loadImg('avatar-14.jpg')" />
                      </a>
                      <router-link to="/client-profile">
                        {{item.name}} {{item.surname}}
                        <span>{{item.designation}}</span>
                      </router-link>
                    </h2>
                  </td>
                  <td>{{item.department}}</td>
                  <td>{{ item.score }}</td>
                  <td class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0)"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="material-icons">more_vert</i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void(0)">
                          <i class="fa fa-eye m-r-5"></i> Scorecard
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <router-link :to="{ path: 'performers',  query: { performers: JSON.stringify(highPerformers) , type:'High Perfomers' }}" >View all</router-link>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex">
      <div class="card card-table flex-fill">
        <div class="card-header">
          <h3 class="card-title mb-0">Poor Perfomers</h3>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table custom-table mb-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Score</th>
                  <th class="text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in PoorPerformers" :key="item.id">
                  <td>
                    <h2 class="table-avatar">
                      <a href="javascript:void(0)" class="avatar">
                        <img alt :src="loadImg('avatar-14.jpg')" />
                      </a>
                      <router-link to="/client-profile">
                        {{item.name}}   {{item.surname}}
                        <span>{{item.designation}}</span>
                      </router-link>
                    </h2>
                  </td>
                  <td>{{item.department}}</td>
                  <td>{{item.score}}</td>
                  <td class="text-end">
                    <div class="dropdown dropdown-action">
                      <a
                        href="javascript:void(0)"
                        class="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="material-icons">more_vert</i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right">
                        <a class="dropdown-item" href="javascript:void(0)">
                          <i class="fa fa-eye m-r-5"></i> Scorecard
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="card-footer">
          <router-link :to="{ path: 'performers',  query: { performers: JSON.stringify(PoorPerformers) , type:'Poor Perfomers' }}">View all</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminclient from "../../../../assets/json/adminclient.json";
import adminclient2 from "../../../../assets/json/adminclient2.json";
import adminproject from "../../../../assets/json/adminproject.json";
import PerformersListService from "../../../services/perfomers.service";
import { mapGetters, mapActions } from "vuex";
const images = require.context(
  "../../../../assets/img/profiles",
  false,
  /\.png$|\.jpg$/
);
import Vue from "vue";
export default {
  data() {
    return {
      adminclient: adminclient,
      adminclient2: adminclient2,
      adminproject: adminproject,
      highPerformers : [],
      PoorPerformers : [],
      loading: false
    };
  },
  components: {},
  methods: {
    ...mapActions('Performers', ['getPerformers']),
    loadImg(imgPath) {
      return images("./" + imgPath).default;
    }


  
  },
  computed:{
      ...mapGetters('Performers', ['allPerformers']),
      getPerformers(){
        // let list = Object.assign({}, this.$store.getters["Chart/getStats"]);
      return this.$store.getters["Performers/allPerformers"];
      }
  },
  mounted(){
    // this.getPerformers();
    PerformersListService.getPerformers().then(response =>{
      console.log("response", response)
      this.PoorPerformers = response.low_performers
      this.highPerformers = response.top_performers
      this.loading = true ;
    })
  }
};
</script>