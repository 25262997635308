<template>
  <div class="card mb-0">
    <!-- {{profile}} -->
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="profile-view">
            <div class="profile-img-wrap">
              <div class="profile-img">
                <a href="javascript:void(0)"
                  ><img alt="" src="../../../assets/img/profiles/avatar-02.jpg"
                /></a>
              </div>
            </div>
            <div class="profile-basic">
              <div class="row">
                <div class="col-md-5">
                  <div class="profile-info-left">
                    <h3 class="user-name m-t-0 mb-0">
                      {{ profile.name }} {{ profile.surname }}
                    </h3>
                    <h6 class="text-muted">{{ profile.department }}</h6>
                    <small class="text-muted">{{ profile.designation }}</small>
                    <div class="staff-id">Employee ID : {{ profile.id }}</div>
                    <div class="small doj text-muted">
                      Date of Join : {{ profile.created_at }}
                    </div>
                    <!-- <div class="staff-msg">
                      <router-link class="btn btn-custom" to="/chat"
                        >Send Message</router-link
                      >
                    </div> -->
                  </div>
                </div>
                <div class="col-md-7">
                  <ul class="personal-info">
                    <li>
                      <div class="title">Name:</div>
                      <div class="text">
                        <p>{{ profile.name }} {{ profile.surname }}</p>
                      </div>
                    </li>
                    <li>
                      <div class="title">Phone:</div>
                      <div class="text">
                        <a href="">{{ profile.phone }}</a>
                      </div>
                    </li>
                    <li>
                      <div class="title">Email:</div>
                      <div class="text">
                        <a href="">{{ profile.email }}</a>
                      </div>
                    </li>
                    <!-- <li>
                      <div class="title">Address:</div>
                      <div class="text">
                        {{ profile.address }}
                      </div>
                    </li> -->
                    <!-- <li>
                      <div class="title">Gender:</div>
                      <div class="text">{{ profile.gender }}</div>
                    </li> -->
                    <li>
                      <span class="title">Reports to:</span>
                     
                       
                      <p> {{ userSuperviser.description }}   </p>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="pro-edit">
              <a class="edit-icon" @click="toggleProfileModal()"
                ><i class="fa fa-pencil"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modalprofile
      @close="toggleProfileModal"
      :employees="employees"
      :departments="departments"
      :profile="profile"
      :modalActive="modalProfileActive"
      :designations="designations"
    />
  </div>
</template>

<script>
import { Vue, ref } from "vue";
import departments from "../../store/modules/departments";
import modalprofile from "./modalprofileInformation.vue";
import { mapGetters } from 'vuex';

export default {
  props: ["profile", "designations", "departments", "employees"],
  components: {
    modalprofile,
  },
  setup() {
    const modalProfileActive = ref(false);
    const toggleProfileModal = () => {
      // e.preventDefault();
      modalProfileActive.value = !modalProfileActive.value;
      if (modalProfileActive.value == true) {
        $("#profile_info").modal("show");
      } else {
        $("#profile_info").modal("hide");
      }
    };
    return { modalProfileActive, toggleProfileModal };
  },
  computed :{
    ...mapGetters('Subordinates', ['Superviser']),

    userSuperviser(){
      let superviser = Object.assign({},this.Superviser)
      return superviser
    }
  },

  name: "profilefirstcard",
};
</script>
