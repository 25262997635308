
import ActualService from "../../services/actual.service";

const state = () => ({
    actuals: [],
    actualToEdit: {},
    error: null,
    loading: false,
});


const mutations = {
    SET_ACTUALS(state, list) {
        state.actuals = list;
    },
    SET_EDIT_ACTUAL(state, actual) {
        state.actualToEdit = actual
    },
    SET_LOADING(state, status) {
        state.loading = status;
    },

    SET_ERROR(state, error) {
        state.error = error
    },

    // SET_UPDATED_ACTual(state,actual){
    //     const index = state.actuals.findIndex(item => item.id ===actual.id)
    //     if(index !== -1){
    //         state.actuals.splice(index,1,actual)
    //     }

    // }
}

const actions = {


    getAllActuals({ commit }) {
        commit("SET_LOADING", true);
        return ActualService.getAll().then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
                commit("SET_ACTUALS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    
    employeeActuals({commit},obj){
        commit("SET_LOADING", true);
        console.log('The object',obj);
        return ActualService.getOne(obj.employeeId, obj.performance_period_id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
                commit("SET_ACTUALS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },

    employeeActual2({commit}, id){

        commit("SET_LOADING", true);
        return ActualService.getEmployeeOne(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
                commit("SET_ACTUALS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )


    },
    employeeActualSubordinate({commit}, id){

        commit("SET_LOADING", true);
        return ActualService.getEmployeeOneSubordinate(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
                commit("SET_ACTUALS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )


    }
    ,


    SET_EDIT_ACTUAL({ commit }, actual) {
        commit("SET_LOADING", true);
        commit("SET_EDIT_ACTUAL", actual);
        commit("SET_LOADING", false);


    },

    updateActual({ commit }, actual) {
        return ActualService.updateOne(actual).then(async (debObj) => {

            const resp = await Promise.resolve(debObj);
            commit("SET_LOADING", false);
            commit("SET_UPDATED_ACTUAL", resp);

        },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    fetchObjectivesActuals({commit}){
        return ActualService.getAllObjectsActualsWithArray().then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
                commit("SET_ACTUALS", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    },

    updateApprovalIndividual({commit},id){
        return ActualService.updateIndividual(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updatedisApprovalIndividual({commit},id){
        return ActualService.updatedisapproveIndividual(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updateApprovalDesignation({commit},id){
        return ActualService.updateDesignation(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updateDisapprovalDesignation({commit},id){
        return ActualService.updateDisapprovalDesignation(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updateApprovalDepartment({commit},id){
        return ActualService.updateDepartment(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updateDissapprovalDepartment({commit},id){
        return ActualService.updateDisapproveDepartment(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updateApprovalOrganizational({commit},id){
        return ActualService.updateOrganizational(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    },
    updateDissapprovalOrganizational({commit},id){
        return ActualService.updateDisapproveOrganizational(id).then(
            async (actuals) => {
                const res = await Promise.resolve(actuals);
                commit("SET_LOADING", false);
              
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )

    }

};

const getters = {
    allActuals(state) {
        return state.actuals;
    },

    selectedActualEdit(state) {
        return state.actualToEdit
    }


};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


