<template>
  <div>
    <table
      v-if="allFilteredEmployees.length == 0"
      class="table table-hover table-bordered review-table mb-0 datatable"
      id="employeelistcontent"
    >
      <thead>
        <tr>
          <th>Name</th>
          <!-- <th>Employee ID</th> -->
          <th>Email</th>
          <th>Mobile</th>
          <th>Join Date</th>
          <th>Position</th>
          <th class="text-end no-sort">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in Employees" :key="item.id">
          <td>
            <h2 class="table-avatar" v-if="role == 'admin'">
              <router-link
                :to="{ name: 'profile', params: { id: item.id } }"
                class="avatar"
                ><img alt="" :src="'../../../../assets/img/profiles/' + item.image"
              /></router-link>
              <router-link :to="{ name: 'profile', params: { id: item.id } }"
                >{{ item.name }} <span>{{ item.surname }}</span></router-link
              >
            </h2>
            <h2 class="table-avatar" v-if="role == 'employee'">
              <div class="avatar">
                <img alt="" :src="'../../../../assets/img/profiles/' + item.image" />
              </div>
              <div>
                {{ item.name }} <span>{{ item.surname }}</span>
              </div>
            </h2>
          </td>
          <!-- <td>{{item.id}}</td> -->
          <td>{{ item.email }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.designation }}</td>
          <td class="text-end">
            <div class="dropdown dropdown-action">
              <a
                href="javascript:void(0)"
                class="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="material-icons">more_vert</i></a
              >
              <div class="dropdown-menu dropdown-menu-right">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="$emit('editEmployeeContent', item)"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a class="dropdown-item" @click="deleteEmployee($event, item)"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table
      v-else
      class="table table-hover table-bordered review-table datatable"
      id="employeelistcontent"
    >
      <thead>
        <tr>
          <th>Name</th>
          <!-- <th>Employee ID</th> -->
          <th>Email</th>
          <th>Mobile</th>
          <th>Join Date</th>
          <th>Position</th>
          <th class="text-end no-sort">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in allFilteredEmployees" :key="item.id">
          <td>
            <h2 class="table-avatar">
              <router-link
                :to="{ name: 'profile', params: { id: item.id } }"
                class="avatar"
                ><img alt="" :src="'../../../../assets/img/profiles/' + item.image"
              /></router-link>
              <router-link :to="{ name: 'profile', params: { id: item.id } }"
                >{{ item.name }} <span>{{ item.surname }}</span></router-link
              >
            </h2>
            <h2 class="table-avatar">
              <div class="avatar">
                <img alt="" :src="'../../../../assets/img/profiles/' + item.image" />
              </div>
              <div>
                {{ item.name }} <span>{{ item.surname }}</span>
              </div>
            </h2>
          </td>
          <!-- <td>{{item.id}}</td> -->
          <td>{{ item.email }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.designation }}</td>
          <td class="text-end">
            <div class="dropdown dropdown-action">
              <a
                href="javascript:void(0)"
                class="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                ><i class="material-icons">more_vert</i></a
              >
              <div class="dropdown-menu dropdown-menu-right">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="$emit('editEmployeeContent', item)"
                  ><i class="fa fa-pencil m-r-5"></i> Edit</a
                >
                <a
                  class="dropdown-item"
                  @click="deleteEmployee($event, item)"
                  href="javascript:void(0)"
                  ><i class="fa fa-trash-o m-r-5"></i> Delete</a
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

  
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import util from "../../../../assets/utils/util";

export default {
  
  props: ["Employees"],
  data() {
    return {
      role: "",
    };
  },
    

  methods: {
    deleteEmployee(employee) {
      if (confirm("Are you sure to delete this employee ?")) {
        this.removeEmployee(employee);
      }
    },
    getEmployee(id) {
      console.log("Here", id);
    },

    deleteEmployee(e, item) {
      e.preventDefault();
      console.log("Delete");
      this.$emit("deleteEmployeeContent", item);
    },
  },
  mounted() {
    util.datatable("#employeelistcontent");
    let user = JSON.parse(localStorage.getItem("user"));
    this.role = user.user.role;
  },

  computed: {
    ...mapGetters("employees2", ["allEmployees", "allFilteredEmployees"]),
  },
  name : "list of employees"
};
</script>
