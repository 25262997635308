// GoalType module
import axios from 'axios';

const resource_uri = "api/goal-type/";

const state = {
    goal_types: []
};

const mutations = {
    setGoalTypes: (state, goal_types) => state.goal_types = goal_types,
    newGoalType: (state, goal_type) => state.goal_types.push(goal_type),
    updGoalType: (state, updatedGoalType) => {
        const index = state.goal_types.findIndex(t => t.id === updatedGoalType.id);
        if(index !== -1) {
            state.goal_types.splice(index, 1, updatedGoalType);
        }        
    },
    deleteGoalType: (state, goal_type) => state.goal_types = state.goal_types.filter(t => goal_type.id !== t.id),
};

const actions = {
    async fetchGoalTypes({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setGoalTypes', response.data);
    },
    async addGoalType( { commit }, goal_type) {
        const response = await axios.post(resource_uri, goal_type);
        commit('newGoalType', response.data.goal_type);
    },
    async updateGoalType( { commit }, goal_type) {
        const response = await axios.put(`${resource_uri}${goal_type.id}`, goal_type);
        commit('updGoalType', response.data);
    },
    async removeGoalType( { commit }, goal_type) {
        const response = await axios.delete(`${resource_uri}${goal_type.id}`);
        commit('deleteGoalType', goal_type);
    }
};

const getters = {
    allGoalTypes: state => state.goal_types
};

export default {
    state, 
    getters, 
    actions, 
    mutations
}