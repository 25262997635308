<template>
<!-- Add Goal Modal -->
        <div id="add_type" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add New Goal Type</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form @submit="onSubmit" >
                  <div class="form-group">
                    <label>Goal Type <span class="text-danger">*</span></label>
                    <input class="form-control" v-model="type" type="text" />
                  </div>
                  <div class="form-group">
                    <label>Description <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4" v-model="description"></textarea>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                    <select class="select" v-model="status">
											<option value="Active">Active</option>
											<option value="Inactive">Inactive</option>
										</select>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Add Goal Modal -->
        
        <!-- Edit Goal Modal -->
        <div id="edit_goal_type" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Edit Goal Type</h5>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                  <div class="form-group">
                    <label>Goal Type <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" value="Invoice Goal">
                  </div>
                  <div class="form-group">
                    <label>Description <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="4">Lorem ipsum ismap</textarea>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Status</label>
                   <select class="form-control select">
											<option>Active</option>
											<option>Inactive</option>
										</select>
                  </div>
                  <div class="submit-section">
                    <button class="btn btn-primary submit-btn">Save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Edit Goal Modal -->
        
        <!-- Delete Goal Modal -->
        <div id="delete_goal_type" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Goal Type</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    @click="deleteGoal_type()"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        <!-- /Delete Goal Modal -->
</template>
<script>
  // import Vue from 'vue'
  import { mapActions } from 'vuex';

export default {
    
    methods: {
        ...mapActions(['addGoalType', 'removeGoalType', 'updateGoalType']),
        onSubmit(e) {
            e.preventDefault();
            const goal_type = {
                type: this.type,
                description: this.description,
                status: this.status
            }
            this.addGoalType(goal_type);
        },
        updateGoalType() {
          const updatedGoalType = {
              id: this.goal_type.id,
              type: this.goal_type.type,
              description: this.goal_type.description,
              status: this.goal_type.status,
          };
          this.updateGoalType(updatedGoalType);
        },
        deleteGoalType() {
          console.log(this.goal_type)
        }
    },

    mounted() {
     // Select 2
    //  if ($('.select').length > 0) {
    //          $('.select').select2({
    //              minimumResultsForSearch: -1,
    //              width: '100%'
    //          });
    //      }
 }
};
  
</script>
