<template>
  <div class="tab-pane active" id="okr_tab">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="form-group">
          <label>OKRs Description</label>
          <textarea rows="5" cols="5" class="form-control" name="description">
Objectives and Key Results (OKR) is a framework for defining and tracking organizations objectives and their outcomes. OKRs comprise an Objective—a clearly defined goal—and one or more Key Results—specific measures used to track the achievement of that goal. The goal of OKR is to define how to achieve objectives through concrete, specific and measurable actions. Key Results can be measured on a sliding scale from 0.0 - 1.0 or 0-100%. Objectives should also be supported by initiatives, which are the plans and activities that help to achieve the objective and move forward the key results. Once objectives and key results have been established, regular and quarterly check-ins are required to make sure OKRs is progressing</textarea
          >
        </div>
      </div>
      <!-- <div class="col-md-12 col-lg-12">
        <div class="rating-list m-t-20">
          <span class="rating-bad">
            <span class="rating-count">
              <a class="me-1" href="">0.0</a>
              <a class="me-1" href="">0.1</a>
              <a class="me-1" href="">0.2</a>
              <a class="me-1" href="">0.3</a> </span
            ><br />
            <span class="rating-text">We failed to make real progress</span>
          </span>
          <span class="rating-normal">
            <span class="rating-count">
              <a class="me-1" href="">0.4</a>
              <a class="me-1" href="">0.5</a>
              <a class="me-1" href="">0.6</a> </span
            ><br />
            <span class="rating-text"
              >We made progress, but fell short of completion</span
            >
          </span>
          <span class="rating-good">
            <span class="rating-count">
              <a class="me-1" href="">0.7</a>
              <a class="me-1" href="">0.8</a>
              <a class="me-1" href="">0.9</a>
              <a href="">1.0</a> </span
            ><br />
            <span class="rating-text">We delivered</span>
          </span>
        </div>
      </div> -->
      <!-- <div class="col-md-12 col-lg-12">
        <div class="submit-section my-3">
          <button
            class="btn btn-primary submit-btn performance_status red_circle"
            data-status="okr"
            title="Admin can’t activate it again"
          >
            Activate OKR
          </button>
        </div>
      </div> -->
      <div class="col-md-12 col-lg-12">
        <hr style="margin-top: 0" />
      

        <!-- 5 Ratings Content -->
        <div class="form-group" id="5ratings_cont_okr">
          <div class="table-responsive">
            <form>
              <table class="table setting-performance-table">
                <thead>
                  <tr>
                    <th>Rating</th>
                    <th>Short Descriptive Word</th>
                    <th>Definition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 50px">1</td>
                    <td style="width: 300px">
                      <input
                        type="text"
                        name="rating_value[]"
                        class="form-control"
                        placeholder="Short word to describe rating of 1"
                      />
                    </td>
                    <td>
                      <textarea
                        rows="3"
                        name="definition[]"
                        class="form-control"
                        placeholder="Descriptive Rating Definition"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50px">2</td>
                    <td style="width: 300px">
                      <input
                        type="text"
                        name="rating_value[]"
                        class="form-control"
                        placeholder="Short word to describe rating of 2"
                      />
                    </td>
                    <td>
                      <textarea
                        rows="3"
                        name="definition[]"
                        class="form-control"
                        placeholder="Descriptive Rating Definition"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50px">3</td>
                    <td style="width: 300px">
                      <input
                        type="text"
                        name="rating_value[]"
                        class="form-control"
                        placeholder="Short word to describe rating of 3"
                      />
                    </td>
                    <td>
                      <textarea
                        rows="3"
                        name="definition[]"
                        class="form-control"
                        placeholder="Descriptive Rating Definition"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50px">4</td>
                    <td style="width: 300px">
                      <input
                        type="text"
                        name="rating_value[]"
                        class="form-control"
                        placeholder="Short word to describe rating of 4"
                      />
                    </td>
                    <td>
                      <textarea
                        rows="3"
                        name="definition[]"
                        class="form-control"
                        placeholder="Descriptive Rating Definition"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 50px">5</td>
                    <td style="width: 300px">
                      <input
                        type="text"
                        name="rating_value[]"
                        class="form-control"
                        placeholder="Short word to describe rating of 5"
                      />
                    </td>
                    <td>
                      <textarea
                        rows="3"
                        name="definition[]"
                        class="form-control"
                        placeholder="Descriptive Rating Definition"
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="submit-section m-b-0">
                <button
                  class="btn btn-primary submit-btn create_goal_configuration_submit"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- /5 Ratings Content -->
      </div>
    </div>
  </div>
</template>
