<template>
  <div v-show="modalActive" id="family_info_modal" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Family Member</h5>
          <button type="button" class="close" @click="$emit('closeFamily')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Full Name</label>
                  <input type="text" v-model="family_Info.name" class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Phone</label>
                  <input type="text" v-model="family_Info.phone" class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Date Of Birth Date</label>
                  <!-- <div class="cal-icon custompicker"> -->
                  <!-- <datepicker id="passport_expiry"  v-model="familyInfo.passport_expry_date"  class="picker" 
                          :editable="true"
                          :clearable="false" /> -->

                  <input type="date" class="picker" min="1997-01-01" max="2030-12-31" v-model="family_Info.date_of_birth" />

                  <!-- </div> -->
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Relationship</label>
                  <input class="form-control" v-model="family_Info.relationship" type="text">
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="submitFamilyInfo($event)">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { toRaw } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ['modalActive', 'employeeId'],
  data() {
    return {
      family_Info : {
        name :null,
      phone : null,
      relationship : null,
      date_of_birth : null
            }
     
    }
  },
  methods: {
    ...mapActions('EmployeeFamily', ['addEmployeeFamily', 'getEmployeeFamily', ]),
    // ...mapActions("employeesFamily", ['getEmployeeFamilyInfo']),

    submitFamilyInfo(event) {
      event.preventDefault();
      let family = [toRaw(this.family_Info)]
      this.family_Info["employee_id"] =  this.employeeId;
      let fam = toRaw(this.family_Info);
      
      this.addEmployeeFamily(fam).then(() => {
       
      this.getEmployeeFamily({employeeId :this.employeeId})
          this.family_Info.name = "";
          this.family_Info.phone = "";
          this.family_Info.relationship = "";
          this.family_Info.date_of_birth = "";
          $("#family_info_modal").modal('hide')
        
          this.$emit('closeFamily');
      });


    },
   

  },
  // computed: {
  //   ...mapGetters('employees2', ['familyInfo']),
  //   getfamilyInfo() {
  //     let FamilyInfoObject = Object.assign({}, this.familyInfo)
  //     return FamilyInfoObject;
  //   }

  // },
  // setup(props, { emit }) {
  //   const close = () => {
  //     emit('close')
  //   }

  //   return { close }
  // },
  mounted() {



    // $("#basic_info_marital_stat_2").change(function(){
    //           this.personalInfo.martital_status = $("#basic_info_marital_stat").val();
    //       }.bind(this)); // To set the user selected value to the data property 



  },

  created() {

  }

}

</script>