import Axios from 'axios';

const MAIN_NAMESPACE = "api/leave";
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const EMPLOYEE = `/${MAIN_NAMESPACE}/employee-entry`
const MAIN_API_URL_STATS = `/${MAIN_NAMESPACE}/statistics`
const MAIN_API_URL_SUBORDINATES = `/${MAIN_NAMESPACE}/subordinates`
const MAIN_API_URL_EMPLOYEE = `/${MAIN_NAMESPACE}/employee`

class LeaveService {

    async addLeave(object){
        return await Axios.post(MAIN_API_URL, object).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    }


    async addEmployeeLeave(object){
        return await Axios.post(EMPLOYEE, object).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    }

     async getAllLeaves(){
      
            return await Axios.get(MAIN_API_URL, {}).then(
                (response)=>{
                    return response.data
                }  
            ).catch((error) => {
                return error
            })
     }

     async downloadFile(filename){

        return await Axios.get( MAIN_NAMESPACE + `/file/${filename}`, {
            responseType: 'arraybuffer'
          }).then(
            response => {
                const fileURL = window.URL.createObjectURL(new Blob([response]));
                const fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', filename);
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
                window.URL.revokeObjectURL(fileURL); 
            }
        ).catch((error) => {
            return error
        })
     
       
    }

    async getStatistics(){

        return await Axios.post(MAIN_API_URL_STATS, {}).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    }

     async leavSubordinates(obj){
        return await Axios.post(MAIN_API_URL_SUBORDINATES, obj).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

     }
     async leaveEmployee(obj){
        return await Axios.post(MAIN_API_URL_EMPLOYEE, obj).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

     }

     async updateStatus(obj){
        return await Axios.put( MAIN_NAMESPACE + `/update-status/${obj.id}`, obj).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

     }

    


}

export default new LeaveService