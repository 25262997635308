import CompanySettingsService from "../../services/company.service";
import BasicSettingsService from "../../services/basic_settings.service";
const state = () => ({
    companyinfo: {},
    basicSettings: {},
    error: null,
    loading: false,
});


const mutations = {
    SET_COMPANY_PROFILE(state, list) {
        state.companyinfo = list;
    },
    SET_BASIC_SETTINGS(state, list) {
        state.basicSettings = list;
    },
    SET_LOADING(state, status) {
        state.companyinfo = status
    },
    SET_ERROR(state,err){
        state.error = err
    }
};

const actions = {

    fetchCompanySetting({commit},id){
        commit("SET_LOADING", true);
        return CompanySettingsService.getOneById(id).then(
            async (companyObj) => {
                const res = await Promise.resolve(companyObj);
                commit("SET_LOADING", false);
                commit("SET_COMPANY_PROFILE", res);
            },
            async (error) => {
              
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );
    },

    fetchBasicSettings({commit},id){
        commit("SET_LOADING", true);
        return BasicSettingsService.getOneById(id).then(
            async (basicObj) => {
                const res = await Promise.resolve(basicObj);
                commit("SET_LOADING", false);
                commit("SET_BASIC_SETTINGS", res);
            },
            async (error) => {
              
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );

    },

    updateCompanySettings({commit}, obj){
        commit("SET_LOADING", true);
     
        return CompanySettingsService.updateOne(obj).then(
            async (companyObj) => {
                // const res = await Promise.resolve(companyObj);
                commit("SET_LOADING", false);
             
               
            },
            async (error) => {
              
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        );

    },
    addCompanySettings({commit}, obj){
        return CompanySettingsService.addCompanySettings(obj).then(
            async (companyObj) => {
                const res = await Promise.resolve(companyObj);
                commit("SET_LOADING", false);
                commit("SET_COMPANY_PROFILE", res);
            },
            async (error) => {
              
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
    }

}    

const getters = {
    profile : (state)=> state.companyinfo
    ,
    basicSettings : (state)=>state.basicSettings ,
     
    loading :(state)=> {
         state.loading;
    },

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

