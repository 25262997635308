<template>
  <div>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
      <div class="sidebar-inner" id="style-15">
        <perfect-scrollbar
          class="scroll-area"
          :settings="settings"
          @ps-scroll-y="scrollHanle"
        >
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li v-if="role == 'admin'">
                <router-link to="/index"
                  ><i class="la la-dashboard"></i> <span>Dashboard</span></router-link
                >
              </li>
              <li v-else-if="role == 'employee'">
                <router-link to="/employee"
                  ><i class="la la-dashboard"></i> <span>Dashboard</span></router-link
                >
              </li>
              <li>
                <router-link to="/departments"
                  ><i class="la la-building-o"></i> <span>Departments</span></router-link
                >
              </li>
              <li>
                <router-link to="/designations"
                  ><i class="la la-sitemap"></i> <span>Positions</span></router-link
                >
              </li>
              <li v-if="role == 'admin'">
                <router-link to="/competencies"
                  ><i class="la la-mortar-board"></i> <span>Job Description</span>
                </router-link>
              </li>
              <li>
                <router-link
                  v-bind:class="{ active: currentPath == 'employees-list' }"
                  to="/employees"
                  ><i class="la la-users"></i> <span>Employees </span></router-link
                >
              </li>
              <li v-if="role == 'admin'">
                <router-link to="/perspectives"
                  ><i class="la la-crosshairs"></i> <span>Perspectives </span>
                </router-link>
              </li>
              <li v-if="role == 'admin'">
                <router-link to="/goal-tracking"
                  ><i class="la la-bank"></i> <span>Strategy</span>
                </router-link>
              </li>
              <li v-if="role == 'admin' || role == 'superviser' ">
                <router-link to="/action-plans-admin"
                  ><i class="la la-cogs"></i> <span>Action Plans</span>
                </router-link>
              </li>
              <li v-if="role == 'employee' || role == 'superviser'">
                <router-link to="/action-plans"
                  ><i class="la la-cogs"></i> <span>My Action Plans</span>
                </router-link>
              </li>

              <li v-if="role == 'employee' || role == 'superviser'">
                <router-link to="/performance"
                  ><i class="la la-star"></i> <span>My Scorecard </span></router-link
                >
              </li>
              <li v-if="role == 'superviser'">
                <router-link to="/scorecards-supervisor"
                  ><i class="la la-balance-scale"></i>
                  <span>Scorecards </span></router-link
                >
              </li>
              <li v-if="role == 'admin'">
                <router-link to="/scorecards"
                  ><i class="la la-balance-scale"></i>
                  <span>Scorecards </span></router-link
                >
              </li>
              <li v-if="role == 'admin' || role == 'superviser'">
                <router-link to="/performance-appraisal"
                  ><i class="la la-comment"></i>
                  <span>Competence Review</span></router-link
                >
              </li>
              <li v-if="role == 'employee' || role == 'superviser'">
                <router-link to="/employee-review"
                  ><i class="la la-comment"></i>
                  <span> My Competence Review </span></router-link
                >
              </li>
              <li v-if="role == 'admin'">
                <router-link to="/disciplinary"
                  ><i class="la la-radiation"></i>
                  <span> Disciplinary </span></router-link
                >
              </li>
              <li v-if="role == 'admin'">
                <router-link to="/leave"
                  ><i class="las la-walking"></i>
                  <span> Leave Management</span></router-link
                >
              </li>
              <li v-if="role == 'superviser'">
                <router-link to="/leave-superviser"
                  ><i class="las la-walking"></i>
                  <span> Leave Management</span></router-link
                >
              </li>

              <li v-if="role == 'employee'">
                <router-link to="/leave-employee"
                  ><i class="las la-walking"></i>
                  <span> Leave Management</span></router-link
                >
              </li>

              <li v-if="role == 'admin'">
                <router-link to="/settings"
                  ><i class="la la-cog"></i> <span>Settings</span></router-link
                >
              </li>
            </ul>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <!-- </sidebar> -->
  </div>
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { mapGetters } from "vuex";
export default {
  components: {
    PerfectScrollbar,
  },

  mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.role = user.user.role;
    $("#sidebar-menu a").on("click", function (e) {
      if ($(this).parent().hasClass("submenu")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("subdrop")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("subdrop");
        $(this).next("ul").slideDown(350);
        $(this).addClass("subdrop");
      } else if ($(this).hasClass("subdrop")) {
        $(this).removeClass("subdrop");
        $(this).next("ul").slideUp(350);
      }
    });
    $("#sidebar-menu ul li.submenu a.active")
      .parents("li:last")
      .children("a:first")
      .addClass("active")
      .trigger("click");
  },
  computed: {
    ...mapGetters("user", ["getUser"]),
    currentPath() {
      return this.$route.name;
    },
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
      role: "",
    };
    //  isactive : true
  },
  methods: {
    scrollHanle(evt) {},
  },
};
</script>
<style>
.scroll-area {
  position: relative;
  margin: auto;
  height: calc(100vh - 60px);
  background-color: transparent !important;
}
.sidebar-menu li a.active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
