<template>
  <!-- Search Filter -->
            <div class="row filter-row">
              <!-- <div class="col-sm-6 col-md-3">  
                <div class="form-group form-focus">
                  <input type="text" class="form-control floating">
                  <label class="focus-label">Employee ID</label>
                </div>
              </div> -->
              <!-- <div class="col-sm-6 col-md-3">  
                <div class="form-group form-focus">
                  <input type="text" class="form-control floating">
                  <label class="focus-label">Employee Name</label>
                </div>
              </div> -->
              <div class="col-sm-6 col-md-3"> 
                <div class="form-group">
                  <select class="select form-control floating"  v-model="selectedDesignation" id="designationSelector" >
                      <option value="">Select Designation</option>
                      <option v-for="item in allDesignations" :key="item.id" :value="item.id">{{item.description}}</option>
                    </select>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <!-- <div class="d-grid">
                  <a href="javascript:void(0)" class="btn btn-success w-100"> Search </a>  
                </div>   -->
                <label v-if="allFilteredEmployees.length > 0"> Found {{ allFilteredEmployees.length }} results </label>
                <label v-if="allFilteredEmployees.length == 0"> No Filter Applied </label>
              </div>
                      </div>
            <!-- Search Filter -->
  </template>
  <script>
  
   import { mapGetters, mapActions } from "vuex";
    export default {
      data(){
            return {
              selectedDesignation : null
            } 
      },
      methods: {
        ...mapActions(["fetchDesignations"]),
        ...mapActions('employees2',["filterEmployeeOnDesignation","initializeFilter"]),
      },
      computed: { 
        ...mapGetters(["allDesignations"]),
        ...mapGetters('employees2',['allFilteredEmployees'])
    },
      created() {
        this.fetchDesignations();
        this.initializeFilter();
      },
      mounted() {
  
        $("#designationSelector").change(
        function () {
          this.selectedDesignation = $("#designationSelector").val();
          this.filterEmployeeOnDesignation(this.selectedDesignation);
        
        }.bind(this)
      );
          // Select 2
          if ($('.select').length > 0) {
                  $('.select').select2({
                      minimumResultsForSearch: -1,
                      width: '100%'
                  });
              }
      }
    }
  </script>