import Axios from 'axios';
const MAIN_NAMESPACE = "api/performers-list";


class PerformersListService {

    async getPerformers() {
        return await Axios.get(MAIN_NAMESPACE, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });

        }

    
}

export default new PerformersListService
