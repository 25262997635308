<template>
  <div  id="experience_info" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Experience Informations</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <!-- <div class="form-scroll"> -->
              <div class="card">
                <div class="card-body">
                  <h3 class="card-title">Experience Informations <a href="javascript:void(0);" class="delete-icon"><i
                        class="fa fa-trash-o"></i></a></h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group form-focus focused">
                        <input type="text" class="form-control floating" v-model="company">
                        <label class="focus-label">Company</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-focus focused">
                        <input type="text" class="form-control floating" v-model="country">
                        <label class="focus-label">Country</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-focus focused">
                        <input type="text" class="form-control floating" v-model="position">
                        <label class="focus-label">Position</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-focus ">
                        <div class="cal-icon custompicker">
                          <datepicker v-model="startdate" class="picker" :editable="true" :clearable="false" />
                        </div>
                        <label class="focus-label">Start Date</label>

                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group form-focus ">
                        <div class="cal-icon custompicker">
                          <datepicker v-model="enddate" class="picker" :editable="true" :clearable="false" />
                        </div>
                        <label class="focus-label">End Date</label>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <!-- </div> -->
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" type="submit" @click="addExperienceMethod($event)">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import { toRaw } from "Vue"
import { emit } from "process";
export default {
  props: ['modalActive', 'expObj', 'employeeId'],
  computed: {
    ...mapGetters('EmployeeExperience', ['getAll']),

    getExperience() {
      let expsObj = Object.assign({}, this.expObj);
      return expsObj;
    },
    ExpModalActive() {
      return this.modalActive

    }
  },
  
  data(){
    return {
        company : '',
        country : '',
        position : '',
        startdate : '',
        enddate: ''
    }
  }
  ,
  methods: {
    ...mapActions('EmployeeExperience', ['addEmployeeExperience','getEmployeeExperience']),
  
    addExperienceMethod(e) {
      e.preventDefault();
    

      const experiencesObject = {
        "employee_id": this.employeeId,
        "company": this.company,
        "country": this.country,
        "position": this.position,
        "startdate": this.startdate,
        "enddate": this.enddate,
      };
      this.addEmployeeExperience(experiencesObject).then(() => {
        

      
        this.getEmployeeExperience({employeeId :this.employeeId}).then(() => {
          this.company = "";
          this.country = "";
          this.position = "";
          this.startdate = "";
          this.enddate = "";
          $("#experience_info").modal('hide')
        })

      });

      //In the backend check if the shit exists 

      this.close();

    },
  },
  setup(props, { emit }) {
    const close = () => {
      emit('close')
    }

    return { close }
  },

}
</script>
  
<style></style>