import Axios from 'axios';

const MAIN_NAMESPACE = "api/addemployee-education"
const MAIN_NAMESPACE_2 = "api/employee-individual-education"
const MAIN_NAMESPACE_3 = "api/employee-education/download/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_2 = `/${MAIN_NAMESPACE_2}`
const MAIN_API_URL_3 = `/${MAIN_NAMESPACE_3}`


class EmployeeEducationService {
    async addEmployeeEducation(emergencyObject){
        return await Axios.post(MAIN_API_URL, emergencyObject).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    
    }


    async getEmployeeEducation(obj){

        return await Axios.post(MAIN_API_URL_2, obj).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    }

     async downloadDocument(id){
        return await Axios.get(MAIN_API_URL_3 + `${id}`, { responseType: "blob"}).then(
            (response)=>{
                return response
            }  
        ).catch((error) => {
            return error
        })

     }

}

export default new EmployeeEducationService