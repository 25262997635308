<template>
    <div class="row">
        <div class="col-md-12">
            <table
                class="table table-hover table-bordered review-table mb-0 datatable"
                id="strategytable"
            >
                <thead>
                    <tr>
                        <th>Perspective</th>
                        <th>Strategic Goal/Objective</th>
                        <th>Applies To</th>
                        <th>KPI/Measure</th>
                        <th data-toggle="tooltip" data-placement="right" title="Reporting Frequency">
                            RF
                        </th>
                        <th data-toggle="tooltip" data-placement="right" title="Target Period">
                            TP
                        </th>
                        <th data-toggle="tooltip" data-placement="right" title="Baseline Target">
                            BT
                        </th>
                        <th data-toggle="tooltip" data-placement="right" title="Stretch Target">
                            ST
                        </th>
                        <th data-toggle="tooltip" data-placement="right" title="Allocated Weight">
                            UOM
                        </th>
                        <th class="text-end">Action</th>
                    </tr>
                </thead>
                <tbody>                
                  <tr v-for="item in allObjectives" :key="item.id">
                        <td>
                            {{ item.perspective.name }}
                        </td>
                        <td>{{ item.goal }}</td>
                        <td>{{ item.applies_to }}</td>
                        <td>{{ item.kpi }}</td>
                        <td>{{ item.reporting_frequency }}</td>
                        <td>{{ item.target_period }}</td>
                        <td>{{ item.baseline_target }}</td>
                        <td>{{ item.stretch_target }}</td>
                        <td>{{ item.unit_of_measure }}</td>
                        <td class="text-end">
                            <div class="dropdown dropdown-action">
                                <a
                                    href="javascript:void(0)"
                                    class="action-icon dropdown-toggle"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    ><i class="material-icons">more_vert</i></a
                                >
                                <div class="dropdown-menu dropdown-menu-right">
                                  <a
                                        class="dropdown-item"
                                        @click="openModalInfo(item)"
                                        ><i class=" fafa-regular m-r-5"></i>
                                        info</a
                                    >
                                  <a
                                        class="dropdown-item"
                                        @click="openEditModelEvent(e, item)"
                                        ><i class="fa fa-pencil m-r-5"></i>
                                        Edit</a
                                    >
                                    <a
                                        class="dropdown-item"
                                        href="javascript:void(0)"
                                        @click="deleteObjective(item)"
                                        ><i class="fa fa-trash-o m-r-5"></i>
                                        Delete</a
                                    >
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>
<script>
import util from '../../../../assets/utils/util'
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

export default {
  
    data() {
        return {
            total: 0,
            modalVisible: false
        };
    },
    methods: {
        ...mapActions("objectives", ["fetchObjectives", "removeObjective"]),
        ...mapActions("objectives2", ["setEditObjective"]),
        deleteObjective(objective) {
            if (confirm("Are you sure to delete this objective ?")) {
                this.removeObjective(objective).then(() => {
        this.fetchObjectives();
        });
            }
        },

        openEditModelEvent(e, item) {
            // e.preventDefault()
            console.log("Ready to Edit", item);

            this.setEditObjective(item).then(() => {
                this.$emit("openEditObjectiveModal", item);
            });
        },
        openModalInfo(data) {
                this.$emit('closeInfoModal', data) ;
    },
    closeModal() {
      this.modalVisible = false;
    }
    },
    computed: {
        ...mapGetters("objectives", [
            "allObjectives",
            
        ]),
    },

    created() {
        this.fetchObjectives();
    },
    mounted() {
        util.datatable('#strategytable')
    },
};
</script>
