<template>
  <div class="tab-pane active" id="compentency_tab">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Competency-based</label>
          <textarea rows="4" cols="5" class="form-control" name="competencies_desc">
  Competency-based performance management allow companies to evaluate employees' performance through define core competencies that align with the company’s mission, vision and goals. A 360-degree assessment can also be added to capture ratings and feedback </textarea
          >
        </div>
      </div>

      <!--TABLE STARTS HERE -->
      <div class="col-md-12">
        <competencytable />
      </div>

      <!--END OF THE TOP PART-->

      <div class="col-md-12 col-lg-12">
        <hr style="margin-top: 0" />
        <div class="form-group m-b-0">
          <label>Rating Scale</label>
          <!-- <div class="radio_input" id="rating_scale_select_competency">
            <label class="radio-inline custom_radio">
              <input
                type="radio"
                name="rating_scale_competency"
                value="rating_1_5"
                required=""
                class="rating_scale"
                
              />1 - 5 <span class="checkmark"></span>
            </label>
          </div> -->
        </div>

        <!-- /5 Ratings Content -->
        <ratiingstable />
      </div>
    </div>
  </div>
</template>
<script>
import competencytable from "./competencies-components/competencytable.vue";
import ratiingstable from "./ratings-component/ratingtable.vue";
export default {
  components: { competencytable, ratiingstable },
};
</script>
