// actuals module
import axios from 'axios';

const resource_uri = "api/actuals/";
const resource_uri_2 = "api/actuals/plan/test";

const state = {
    actuals: []
};

const mutations = {
    setActuals: (state, actuals) => state.actuals = actuals,
    newActual: (state, actual) => state.actuals.push(actual),
    updActual: (state, updatedActual) => {
        const index = state.actuals.findIndex(t => t.id === updatedActual.id);
        if(index !== -1) {
            state.actuals.splice(index, 1, updatedActual);
        }        
    },
    deleteActual: (state, actual) => state.actuals = state.actuals.filter(t => actual.id !== t.id),
};

const actions = {
    async fetchActuals({ commit }) {
        const response = await axios.get(resource_uri);    
        commit('setActuals', response.data);
    },
    async fetchActuals2({ commit }) {
        const response = await axios.get(resource_uri_2);    
        commit('setActuals', response.data);
    },
    async addActual( { commit }, actual) {
        const response = await axios.post(resource_uri, actual);
        commit('newActual', response.data.actual);
    },
    async updateActual( { commit }, actual) {
        const response = await axios.put(`${resource_uri}${actual.id}`, actual);
        commit('updActual', response.data);
    },
    async removeActual( { commit }, actual) {
        const response = await axios.delete(`${resource_uri}${actual.id}`);
        commit('deleteActual', actual);
    },
};

const getters = {
    allActuals: state => state.actuals
};

export default {
    namespaced: true,
    state, 
    getters, 
    actions, 
    mutations
}