<template>
  <section class="review-section information">


    <div class="review-header text-center">
      <h3 class="review-title">Employee Basic Information</h3>
      <!-- <p class="text-muted">Lorem ipsum dollar</p> -->
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="table-responsive">
          <table class="table table-bordered table-nowrap review-table mb-0">
            <tbody>
              <tr>
                <td>
                  <div class="form-group ">
                      <label id="name">Name: {{ profile.name }} {{ profile.surname }}</label>
                    </div>
                    <div class="form-group">
                      <label id="departments">Department: {{ profile.department }}</label>
                    </div>
                    <div class="form-group">
                      <label id="designations">Position: {{ profile.designation }}</label>
                    </div>
                </td>
                <td>
                  <div class="form-group">
                      <label for="doj">Emp ID: {{ profile.id }}</label>
                    </div>
                    <div class="form-group">
                      <label>Date of Join: 01 Janaury 2023</label>
                    </div>
                    <div class="form-group">
                      <label for="doc"> Performance Period : {{  getFormattedDate(getActivePeriod.start_date)  }} - {{  getFormattedDate(getActivePeriod.end_date)  }} </label>
                    </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["profile", "employees"],
  data() {
    return {
    
      performancePeriod : {} 
    }

  },
  components: {

  },
  mounted() {
   
  },
  methods: {
    ...mapActions('employees2', ['getOne']),

    // ChangedEmployee(event){
    //     console.log("This has been selected",event.target.value, "Selected employee :", this.selectedEmployee.id);
    //     this.designation = this.selectedEmployee.designation
    //     this.department = this.selectedEmployee.department;
    //     this.employee_id = this.selectedEmployee.id
    // }
    getFormattedDate(thedate) {
      const date = new Date(thedate);
      const formattedDate = date.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      });

   return formattedDate;
    },

  },
  computed: {
    ...mapGetters('employees2', ['employee']),
    
    ...mapGetters('PerformancePeriod',['allPerformancePeriods', 'activePerformancePeriods']),

    getActivePerformancePeriod(){
            let performancePeriod = Object.assign([],this.allPerformancePeriods);
            let activePeriod ;
            activePeriod = performancePeriod.find(obj =>{return  obj.status === 'Active' })
            this.performancePeriod = activePeriod ;
            return activePeriod ;
        },


        getActivePeriod(){
            let performancePeriod = Object.assign([],this.activePerformancePeriods);
            return performancePeriod ;

        }
      

  },
  watch: {
    employeeProp: function () {

    }

  },
  name: 'EmployeeScoreCard'
}

</script>

<style scoped>
.inputWidth {
  width: 80%;
}
</style>