import { createStore } from 'vuex'
import departments from './modules/departments';
import perspectives from './modules/perspectives';
import designations from './modules/designations';
import actionplans from './modules/actionPlans';
import goals from './modules/actuals';
import objectives from './modules/objectives';
import employees from './modules/employee';
import goalTypes from './modules/goal_type';
import goalTrackings from './modules/goal_tracking';
import profile from './modules/profile.module'
import emergency from './modules/emergency.module'
import companysettings from './modules/companysettings.module'
import user from './modules/user.module'
import employees2 from './modules/employee.module';
import designations2 from './modules/designation.module';
import actionplans2 from './modules/actionPlan.module';
import goals2 from './modules/actual.module';
import objectives2 from './modules/strategy.module';
import departments2 from './modules/department.module'
import perspectives2 from './modules/perspective.module'
import performance from './modules/performance.module'
import performanceAppraisals from './modules/PerformanceAppraisal.module'
import competencies from './modules/competencies.module.js'
import competenciesDesignations from './modules/competenciesDesignations.module'
import ratings from './modules/ratings.module'
import PerformancePeriod from './modules/performancePeriod.module'
import EmployeeEducation from './modules/employeeeducation.module'
import EmployeeFamily from './modules/employeeFamily.module'
import EmployeeExperience from './modules//employeeExperience.module'
import DashBoardPlans from './modules/statisticsPlans.module'
import DashBoard from './modules/dashboard.module'
import PerspectiveDashboard from './modules/perspectiveCounter.module'
import DashBoardStatus from './modules/status.module'
import Chart from './modules/chart.module'
import Performers from './modules/performers.module'
import Subordinates from './modules/ReportsTo.module'
import Comments from './modules/taskComments.module'
import EmailResetApi from './modules/emailreset.module'
import PerspectiveInfo from './modules/perspectiveinofmation.module';
import Violations from "./modules/Violations.module.js";
import Leave from "./modules/leave.module.js";

const store = createStore({
    modules: {
        departments,
        perspectives,
        designations,
        actionplans,
        goals,
        objectives,
        employees,
        goalTypes,
        profile,
        emergency,
        companysettings,
        goalTrackings,
        profile,
        user,
        employees2,
        designations2,
        actionplans2,
        goals2,
        objectives2,
        departments2,
        perspectives2,
        performance,
        performanceAppraisals,
        competencies,
        competenciesDesignations,
        ratings,
        PerformancePeriod,
        EmployeeEducation,
        EmployeeExperience,
        EmployeeFamily,
        DashBoardPlans,
        DashBoard,
        PerspectiveDashboard,
        DashBoardStatus,
        Chart,
        Performers,
        Subordinates,
        Comments,
        EmailResetApi,
        PerspectiveInfo,
        Violations,
        Leave
    }
})

export default store;