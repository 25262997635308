import ChartService from "../../services/chart.services";

const state = () => ({
        stats: {},
        error: null,
        loading: false,
})

const mutations = {

    SET_STATS(state, list) {
        state.stats = list;
    },
    SET_LOADING(state, status) {
        state.loading = status
    },
    SET_ERROR(state,err){
        state.error = err
    }
}


const actions = {
    fetchStats({commit}){
        commit("SET_LOADING", true);
        ChartService.getStats().then(
            async (companyObj) => {
                const res = await Promise.resolve(companyObj);
                commit("SET_LOADING", false);
                commit("SET_STATS", res);
            },
            async (error) => {
              
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    },
    fetchEmployeeStats({commit}){
        commit("SET_LOADING", true);
        ChartService.getEmployeeStats().then(
            async (companyObj) => {
                const res = await Promise.resolve(companyObj);
                commit("SET_LOADING", false);
                commit("SET_STATS", res);
            },
            async (error) => {
              
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }
        )
    }



}
const getters = {
    getStats : (state)=> state.stats,
    getError : (state)=>state.error,
    getLoading : (state)=>state.loading,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};