<template>

     <!-- Emergency Contact Modal -->
     <div  v-show="modalActive"  id="emergency_contact_modal" class="modal custom-modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Personal Information</h5>
                <button type="button" class="close" data-bs-dismiss="modal" @click="close($event)" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form>
                
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">Primary Contact</h3>
                      <div class="row" v-if="emergencycontacts.length === 0">

                        <div class="col-md-6">
                                <div class="form-group">
                                  <label>Name <span class="text-danger">*</span></label>
                                  <input type="text" v-model="primaryContact.name" class="form-control">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Relationship <span class="text-danger">*</span></label>
                                  <input class="form-control"  v-model="primaryContact.relationship" type="text">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Phone <span class="text-danger">*</span></label>
                                  <input class="form-control" v-model="primaryContact.phone" type="text">
                                </div>
                              </div>

                      </div>
                      
                      <div class="row" v-for="item in emergencycontacts.slice(0,1)" :key="item.id">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input  type="text" v-model="item.name" class="form-control">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Relationship <span class="text-danger">*</span></label>
                            <input class="form-control"  v-model="item.relationship" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone <span class="text-danger">*</span></label>
                            <input class="form-control"  v-model="item.phone" type="text">
                          </div>
                        </div>
                        <!-- <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone 2</label>
                            <input class="form-control" type="text">
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  
                  <div class="card">
                    <div class="card-body">
                      <h3 class="card-title">Secondary Contact</h3>
                      <div class="row" v-if="emergencycontacts.length === 0">

                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Name <span class="text-danger">*</span></label>
                                  <input type="text" v-model="secondaryContact.name" class="form-control">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Relationship <span class="text-danger">*</span></label>
                                  <input class="form-control"  v-model="secondaryContact.relationship" type="text">
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label>Phone <span class="text-danger">*</span></label>
                                  <input class="form-control" v-model="secondaryContact.phone" type="text">
                                </div>
                              </div>
                        
                      </div>
                      <div v-else class="row" v-for="item in emergencycontacts.slice(1,2)" :key="item.id">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Name <span class="text-danger">*</span></label>
                            <input type="text" v-model="item.name" class="form-control">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Relationship <span class="text-danger">*</span></label>
                            <input class="form-control" v-model="item.surname" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Phone <span class="text-danger">*</span></label>
                            <input class="form-control" v-model="item.phone" type="text">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <!-- <div class="form-group">
                            <label>Phone 2</label>
                            <input class="form-control" type="text">
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="submit-section">
                    <button v-if="emergencycontacts.length === 0" class="btn btn-primary submit-btn" @click="addNewContacts($event)">Submit</button>
                    <button v-else  class="btn btn-primary submit-btn" @click="addContacts($event)">Submit</button>
                  </div>

                   
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- /Emergency Contact Modal -->

  
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {toRaw} from "Vue"

export default {
    props : ['emergencycontacts', 'modalActive',"profile"],

    setup(props,{emit}){
      const close = () =>{
        emit('closeEmergency')
      }

      return {close}
    },
    data() {
      return {
            primaryContact : {
              "name": "",
              "relationship" : "",
              "phone": "",
             "contact_type" : "Primary"

            },
            secondaryContact: {

              "name": "",
              "relationship" : "",
              "phone": "",
              "contact_type" : "Secondary"

            }
      }
    },

    methods :{
          ...mapActions('employees2',['addEmergencyContact']),
          addContacts(e){
            e.preventDefault();
            console.log("The emergency", this.emergencycontacts);

            let theEmergencyObj = {
              employee_id : this.emergencycontacts[0].employee_id,
              "contacts" : this.emergencycontacts
            }

            console.log(theEmergencyObj)

            this.addEmergencyContact(theEmergencyObj).then(()=>{
              this.close();
            })
            

          },
          addNewContacts(e){
            e.preventDefault()

            let contacts = [toRaw(this.primaryContact), toRaw(this.secondaryContact)];

            let emergencyContactObject = {
              employee_id : this.profile.id,
              contacts 

            }

            // console.log(emergencyContactObject);
            this.addEmergencyContact(emergencyContactObject).then(()=>{
              this.close();
            })
            

          }

    },
    computed:{
      ...mapGetters('emergency',['loading']), 
      
     
               
    }
    

}
</script>

<style>

</style>