<template>
  <div>
    <section class="review-section professional-excellence">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered review-table mb-0 datatable" id="strategytable">
            <thead>
              <tr>
                <th>Perspective</th>
                <th>Strategic Goal/Objective</th>
                <th>Applies To</th>
                <th>KPI/Measure</th>
                <th data-toggle="tooltip" data-placement="right" title="Reporting Frequency">
                  RF
                </th>
                <th data-toggle="tooltip" data-placement="right" title="Target Period">
                  TP
                </th>
                <th data-toggle="tooltip" data-placement="right" title="Baseline Target">
                  BT
                </th>
                <th data-toggle="tooltip" data-placement="right" title="Stretch Target">
                  ST
                </th>
                <th>Actual</th>
                <th data-toggle="tooltip" data-placement="right" title="Allocated Weight">
                  Unit of Measure
                </th>
                <th>Score</th>
                <th>Endorsed</th>
                <th class="text-end">Action</th>
              </tr>
            </thead>
            <tbody>
             
              <tr v-for="(itemActuals, index) in getActuals" :key="index">
                <td>{{ itemActuals.perspective }}</td>
                <td>{{ itemActuals.goal }}</td>
                <td>{{ itemActuals.applies_to }}</td>
                <td>{{ itemActuals.kpi }}</td>
                <td>{{ itemActuals.reporting_frequency }}</td>
                <td>{{ itemActuals.target_period }}</td>
                <td>{{ itemActuals.baseline_target }}</td>
                <td>{{ itemActuals.stretch_target }}</td>                
                <td v-if="itemActuals.goal_actuals[0]">
                  {{ itemActuals["goal_actuals"][0].actual }}
                </td>
                <td v-else class="text-danger">Empty</td>
                <td>{{ itemActuals.unit_of_measure }}</td>
                <td>
                 <p v-if="itemActuals.goal_actuals && itemActuals.goal_actuals[0] && itemActuals.goal_actuals[0].actual"> {{
                    parseFloat(
                      itemActuals["goal_actuals"][0]
                        .actual /
                      itemActuals.baseline_target
                    ).toFixed(2)
                  }} </p>

                  <p else>

                  </p>
                </td>
                <td>
                    <div v-if="itemActuals.goal_actuals && itemActuals.goal_actuals[0] && itemActuals.goal_actuals[0].approved">
                     
                  <p style="color:red;" v-if="itemActuals['goal_actuals'][0].approved == 0">Not Approved</p>
                  <p v-else-if="itemActuals['goal_actuals'][0].approved == 1">Approved</p>
                  <p  style="color:rgb(241, 102, 102);" v-else-if="itemActuals['goal_actuals'][0].approved == 2">Disapproved</p>
                  <p  style="color:rgb(241, 102, 102);" v-else></p>
                  
                
                

                    </div>
                    <div v-else>
                      Not Approved
                    </div>
                </td>

            
                
                
             


                <td class="text-end">
                <div class="dropdown dropdown-action">
                  <a
                    href="javascript:void(0)"
                    class="action-icon dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    ><i class="material-icons">more_vert</i></a
                  >
                  <div class="dropdown-menu dropdown-menu-right">
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="approve(itemActuals['goal_actuals'][0].id, itemActuals.applies_to, itemActuals['goal_actuals'])"
                      ><i class="fa-regular fa-thumbs-up"></i> Approve</a
                    >
                    <a
                      class="dropdown-item"
                      href="javascript:void(0)"
                      @click="disApprove(itemActuals['goal_actuals'][0].id, itemActuals.applies_to, itemActuals['goal_actuals'])"
                      ><i class="fa-regular fa-thumbs-up"></i>Disapprove</a
                    >
                  
                 
                  </div>
                </div>
              </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { ref, toRaw } from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: [
    "employees",
    "scoreCards",
    "foo",
    "departmentGoals",
    "designationGoals",
    "employee"
  ],
  data() {
    return {
      total: 0,
    };
  },
  components: {},
  methods: {
    ...mapActions("employees2", [
      "getScorecardObjective",
      "getEmployeeScorecard",
      "getDepartmentScorecardObjective",
      "getDesignationScorecardObjective",
    ]),
    ...mapActions("goals2", ["fetchObjectivesActuals",
     "updateApprovalIndividual", "updatedisApprovalIndividual","updateApprovalDesignation",
      "updateDisapprovalDesignation", "updateApprovalDepartment","updateDissapprovalDepartment",
      "updateApprovalOrganizational", "employeeActuals", 'updateDissapprovalOrganizational']),
     ...mapActions('employees2',['getOne','getEmployees'
     ,'getScorecardObjective','getDepartmentScorecardObjective'
     ,'getDesignationScorecardObjective']),
    // ...mapActions('designations2',['getAllDesignations']),
    calculateTotal() {
      return (this.total = this.foo);
    },
    approve(approved_id, applies_to, actual){
      console.log("Approve id", approved_id);
      


      let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
      console.log( "actual :",toRaw(actual));
      let arrayActual =toRaw(actual)
        if(arrayActual.length == 0){
          console.log("Cant approve something that doesnt exists");
          return ;

        }
      if(applies_to.toLowerCase() == 'individual'){

        this.updateApprovalIndividual(approved_id).then(()=>{
            // this.employeeActual(this.employee.id)
            this.getScorecardObjective(this.employee.id);
            let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        this.getDepartmentScorecardObjective(this.employee.department_id);
        this.getDesignationScorecardObjective(this.employee.designation_id)
        })

      }else if( applies_to.toLowerCase()  =='position'){
        this.updateApprovalDesignation(approved_id).then(()=>{
          this.getScorecardObjective(this.employee.id);
          let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        })
        

      }else if( applies_to.toLowerCase()  =='department'){
        this.updateApprovalDepartment(approved_id).then(()=>{
          this.getScorecardObjective(this.employee.id);
          let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        })
        

      }else if( applies_to.toLowerCase()  =='organizational'){
        this.updateApprovalOrganizational(approved_id).then(()=>{
          this.getScorecardObjective(this.employee.id);
          let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        })


      }

    }
    ,
    disApprove(approved_id, applies_to, actual){
     
      console.log( "actual :",toRaw(actual));
      let arrayActual =toRaw(actual)
        if(arrayActual.length == 0){
          console.log("Cant approve something that doesnt exists");
          return ;

        }
      if(applies_to.toLowerCase() == 'individual'){

        this.updatedisApprovalIndividual(approved_id).then(()=>{
            // this.employeeActual(this.employee.id)
            this.getScorecardObjective(this.employee.id);
            // this.employeeActuals(this.employee.id);

            
        let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        this.getDepartmentScorecardObjective(this.employee.department_id);
        this.getDesignationScorecardObjective(this.employee.designation_id)
        })

      }else if( applies_to.toLowerCase()  =='position'){
        this.updateDisapprovalDesignation(approved_id).then(()=>{
          this.getScorecardObjective(this.employee.id);
            
          
        let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        })
        

      }else if( applies_to.toLowerCase()  =='department'){
        this.updateDissapprovalDepartment(approved_id).then(()=>{
          this.getScorecardObjective(this.employee.id);
          
        let obj ={employeeId : this.employee.id, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        })
        

      }else if( applies_to.toLowerCase()  =='organizational'){
        this.updateDissapprovalOrganizational(approved_id).then(()=>{
          this.getScorecardObjective(this.employee.id);
          
        let obj ={employeeId : employeeId, performance_period_id : this.activePeriod.id };
      
      this.employeeActuals(obj);
        })


      }

    }
  },
  computed: {
    ...mapGetters("goals2", ["allActuals"]),
    ...mapGetters('PerformancePeriod',['allPerformancePeriods', 'activePerformancePeriods']),

    getScorecards() {
      let scoreCardObj = Object.assign({}, this.scoreCards);
      return scoreCardObj;
    },
    getDepartmentGoals() {
      let departmentObj = Object.assign({}, this.departmentGoals);
      return departmentObj;
    },
    getDesignationGoals() {
      let designationGoalsObj = Object.assign({}, this.designationGoals);
      return designationGoalsObj;
    },
    getActuals() {
      let actualsObj = Object.assign({}, this.allActuals);
      return actualsObj;
    },
    activePeriod(){
    let performancePeriod = Object.assign([],this.activePerformancePeriods);
    return  performancePeriod ;
  },
    
  },

  Mounted() {
    // this.getScorecardObjective();
    this.getDepartmentScorecardObjective();
    this.getDesignationScorecardObjective();
    // this.employeeActuals();
  },
};
</script>
