*
<template>
    <div>
        <section class="review-section professional-excellence">
            <div class="row">
                <div class="col-md-12">
                    <table
                        class="table table-bordered review-table mb-0 datatable"
                        id="strategytable"
                    >
                        <thead>
                            <tr>
                                <th>Perspective</th>
                                <th>Strategic Goal/Objective</th>
                                <th>Applies To</th>
                                <th>KPI/Measure</th>
                                <th
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Reporting Frequency"
                                >
                                    RF
                                </th>
                                <th
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Target Period"
                                >
                                    TP
                                </th>
                                <th
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Baseline Target"
                                >
                                    BT
                                </th>
                                <th
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Stretch Target"
                                >
                                    ST
                                </th>
                                <th
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Allocated Weight"
                                >
                                    U_O_M
                                </th>
                                <th colspan="2">Actual</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <template v-for="(data, d) in allActuals" :key="data"> -->
                            <tr
                                v-for="(itemActuals, index) in getActuals"
                                :key="index"
                            >
                                <td>{{ itemActuals.perspective }}</td>
                                <td>{{ itemActuals.goal }}</td>
                                <td>{{ itemActuals.applies_to }}</td>
                                <td>{{ itemActuals.kpi }}</td>
                                <td>{{ itemActuals.reporting_frequency }}</td>
                                <td>{{ itemActuals.target_period }}</td>
                                <td>{{ itemActuals.baseline_target }}</td>
                                <td>{{ itemActuals.stretch_target }}</td>
                                <td>{{ itemActuals.unit_of_measure }}</td>
                                <td>
                                    <a
                                        class="edit-icon"
                                        @click="addActual(itemActuals)"
                                        ><i class="fa fa-pencil"></i
                                    ></a>
                                </td>
                                <td v-if="itemActuals.goal_actuals[0]">
                                    {{ itemActuals["goal_actuals"][0].actual }}
                                </td>
                                <td v-else class="text-danger">Empty</td>
                                
                                <td  v-if="itemActuals.goal_actuals[0]">
                                    {{
                                        parseFloat(
                                            itemActuals["goal_actuals"][0]
                                                .actual /
                                                itemActuals.baseline_target
                                        ).toFixed(2)
                                    }}
                                </td>
                                <td v-else>Empty</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { ref, toRaw } from "vue";
import { mapActions, mapGetters } from "vuex";
import basiEmployeecInfo from "./reviewcomponents/employeereviewbasicInfo.vue";
export default {
    props: ["employees", "employeeScoreCards", "foo"],
    data() {
        return {
            total: 0,
        };
    },
    components: {
        basiEmployeecInfo,
    },
    methods: {
        ...mapActions("employees2", ["getEmployeeScorecard"]),
        // ...mapActions('designations2',['getAllDesignations']),
        ...mapActions("goals2", ["fetchObjectivesActuals"]),
        ...mapActions("PerformancePeriod", ["getPerformers"]),
        calculateTotal() {
            return (this.total = this.foo);
        },
        addActual(data) {
            console.log("inner coponent", data);
            let obj = toRaw(data);
            this.$emit("add_actual_modal", obj);
        },
    },
    computed: {
        // ...mapGetters('employees2',["scoreCards"]),
        ...mapGetters("goals2", ["allActuals"]),
       
        ...mapGetters('PerformancePeriod',['allPerformancePeriods', 'activePerformancePeriods']),

        getEmployeeScorecards() {
            let employeescoreCardObj = Object.assign(
                {},
                this.employeeScoreCards
            );
            return employeescoreCardObj;
        },

        getActuals() {
            let actualsObj = Object.assign({}, this.allActuals);
            return actualsObj;
        },

        getActivePerformancePeriod(){
            let performancePeriod = Object.assign([],this.allPerformancePeriods);
            let activePeriod ;
            activePeriod = performancePeriod.find(obj =>{return  obj.status === 'Active' })
        
            return activePeriod ;
        },

      

       
    },

 
};
</script>
