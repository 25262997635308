import Axios from 'axios';

const MAIN_NAMESPACE = "api/employee-experience"
const MAIN_NAMESPACE_2 = "api/get-employee-experience"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const MAIN_API_URL_2 = `/${MAIN_NAMESPACE_2}`


class EmployeeExperienceService {
    async addEmployeeExperience(expObject){
        return await Axios.post(MAIN_API_URL, expObject).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    
    }


    async getEmployeeExperience(obj){

        return await Axios.post(MAIN_API_URL_2, obj).then(
            (response)=>{
                return response.data
            }  
        ).catch((error) => {
            return error
        })

    }

}

export default new EmployeeExperienceService