<template>
  <div v-show="modalActive" id="personal_info_modal" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Personal Information</h5>
          <button type="button" class="close" @click="$.emit('close')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>ID No</label>
                  <input type="text" v-model="getPersonalInfo.passport_no" class="form-control" />
                </div>
              </div>
              <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label>Passport Expiry Date</label>
                        
                          <input type="date"  class="picker"  min="1997-01-01" max="2030-12-31" v-model="getPersonalInfo.passport_expry_date"  />

                   
                      </div>
              </div>-->
              <div class="col-md-6">
                <div class="form-group">
                  <label>Tel</label>
                  <input class="form-control" v-model="getPersonalInfo.tel" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Nationality
                    <span class="text-danger">*</span>
                  </label>
                  <input class="form-control" v-model="getPersonalInfo.nationality" type="text" />
                </div>
              </div>
              <!-- <div class="col-md-6">
                      <div class="form-group">
                        <label>Religion</label>
                        <div class="cal-icon">
                          <input class="form-control" type="text">
                        </div>
                      </div>
              </div>-->
              <div class="col-md-6">
                <div class="form-group">
                  <label>
                    Marital status
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    id="basic_info_marital_stat"
                    v-model="getPersonalInfo.martital_status"
                    class="select form-control"
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </select>
                </div>
              </div>
              <!-- <div class="col-md-6"> -->
                <!-- <div class="form-group"> -->
                  <!-- <label>Employment of spouse</label> -->
                  <!-- <input
                    class="form-control"
                    v-model="getPersonalInfo.employment_of_spouse"
                    type="text"
                  />
                </div> -->
              <!-- </div> -->
              <div class="col-md-6">
                <div class="form-group">
                  <label>No. of children</label>
                  <input class="form-control" v-model="getPersonalInfo.no_of_children" type="text" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>National ID :</label>
                  <input
                    ref="fileUploadId"
                    @change="onFileSelected($event)"
                    type="file"
                    class="form-control"
                  />
                  <!-- <label class="focus-label">Highest Level of Education Achieved</label> -->
                </div>
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="submitPersonalInfo2($event)">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["modalActive", "profile", "employeeId"],
  data() {
    return {
      action: "add",
      nationalDocument: null
    };
  },

  methods: {
    ...mapActions("employees2", [
      "addEmployeePersonalInfo",
      "addEmployeePersonalInfoWithoutDocument",
      "updateEmployeePersonalInfo"
    ]),

    submitPersonalInfo(event) {
      event.preventDefault();
      // console.log("Proposed Solution", this.personalInfo, this.profile);

      const personalInfoObject = {
        employee_id: this.employeeId,
        passport_no: this.getPersonalInfo.passport_no,
        // "passport_expry_date" : this.getPersonalInfo.passport_expry_date ,
        nationality: this.getPersonalInfo.nationality,
        martital_status: this.getPersonalInfo.martital_status,
        employment_of_spouse: "N/A",
        no_of_children: this.getPersonalInfo.no_of_children,
        tel: this.getPersonalInfo.tel
      };

      // console.log(personalInfoObject)

      this.addEmployeePersonalInfo(personalInfoObject); //In the backend check if the shit exists

      //
      // }else if( this.action =='update'){
      // this.updateEmployeePersonalInfo(personalInfoObject)
      // }

      // $("#personal_info_modal").modal("hide")
      this.close();
    },
    submitPersonalInfo2(event) {
      event.preventDefault();
      const fd = new FormData();
      fd.append("employee_id", this.employeeId);
    
   
        fd.append("passport_no", this.getPersonalInfo.passport_no);
    
      fd.append("nationality", this.getPersonalInfo.nationality);
      fd.append("martital_status", this.getPersonalInfo.martital_status);
      fd.append(
        "employment_of_spouse",
        this.getPersonalInfo.employment_of_spouse
      );
      fd.append("no_of_children", this.getPersonalInfo.no_of_children);
      fd.append("tel", this.getPersonalInfo.tel);

      if (this.nationalDocument !== null) {
        fd.append(
          "document_path",
          this.nationalDocument,
          this.nationalDocument.name
        );
        this.addEmployeePersonalInfo(fd).then(() => {
        this.getEmployeeEducation({ employeeId: this.employeeId });
        $("#personal_info_modal").modal("hide");
        // this.education_info_1.institution = null;
        // this.education_info_1.subject = null;
        // this.education_info_1.start_date = null;
        // this.education_info_1.end_date = null;
        // this.education_info_1.qualification = null;
        // this.education_info_1.grade = null;
        this.$refs.fileUploadId.value = null;
      });


      }else{

        this.addEmployeePersonalInfoWithoutDocument(fd).then(() => {
        // this.getEmployeeEducation({ employeeId: this.employeeId });
        $("#personal_info_modal").modal("hide");
        // this.education_info_1.institution = null;
        // this.education_info_1.subject = null;
        // this.education_info_1.start_date = null;
        // this.education_info_1.end_date = null;
        // this.education_info_1.qualification = null;
        // this.education_info_1.grade = null;
        // this.$refs.fileUploadId.value = null;
      });

      }
     

 


      this.$.emit('close');
      
    },

    maritalStatus(val) {
      console.log("Select Box", val);
      this.$nextTick(() => {
        // console.log("The val",val)
      });
    },
    onFileSelected(event) {
      this.nationalDocument = event.target.files[0];
    }
  },
  computed: {
    ...mapGetters("employees2", ["personalInfo"]),
    getPersonalInfo() {
      let personalInfoObject = Object.assign({}, this.personalInfo);
      return personalInfoObject;
    },

    getProfileInfo() {
      let profile = Object.assign({}, this.profile);
      return profile;
    }
  },
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };

    return { close };
  },
  mounted() {
    $("#basic_info_marital_stat").change(
      function() {
        this.getPersonalInfo.martital_status = $(
          "#basic_info_marital_stat"
        ).val();
      }.bind(this)
    ); // To set the user selected value to the data property
  },

  created() {},
  watch :{
    modalActive:function(){
      // if(this.getPersonalInfo.passport_no !== "" ){
      //   // fd.append("passport_no", this.getPersonalInfo.passport_no);
      //   this.getPersonalInfo.passport_no = this.getPersonalInfo.passport_no;
      // }else{
      //   this.getPersonalInfo.passport_no = this.getProfileInfo.national_ID;
      // }

        console.log("This must change");
      const IsPersonalInfoEpty = "passport_no" in this.getPersonalInfo;
      if(!IsPersonalInfoEpty){
        this.getPersonalInfo.passport_no =  this.getProfileInfo.national_ID;
      }
    }
  }
};
</script>