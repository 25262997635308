import Axios from 'axios';
const MAIN_NAMESPACE = "api/department"
const SUB_NAMESPACE = "api/department/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_API_URL}`

class DepartmentService {
    async getAll() {
        return await Axios.get(MAIN_API_URL, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async getOneById(id){
        return await axios.get(SUB_API_URL + `show/${id}`, {

        }).then((response) =>{
            return response.data
        }).catch((error) => {
            return error
        });
    }
    async createOne(departmentObj) {
        return Axios.post(API_URL + 'store', departmentObj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async updateOne(departmentObj){
        console.log("THE SERVICE ", departmentObj)
        return Axios.put(SUB_NAMESPACE + departmentObj.id, {description :departmentObj.description}, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }
    
    async create(departmentObj) {
        return Axios.post(MAIN_NAMESPACE, departmentObj, {

        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }


}
export default new DepartmentService