import PerformersListService from "../../services/perfomers.service";

const state = {
    performancers: [],
    error: null,
    loading: false,
}


const mutations = {
    SET_PERFORMANCE_PERIOD(state, list){
        state.performancers = list;
    }, 
    SET_ERROR(state, error){
        state.error = error;
    }, 
    SET_LOADING(state, status){
        state.loading = status;
    },


  

}

const actions = {
    getPerformers({commit}){
        commit("SET_LOADING", true);

        return PerformersListService.getPerformers().then(
            async (performanceObj) => {
                const res = await Promise.resolve(performanceObj);
                commit("SET_LOADING", false);
                commit("SET_PERFORMANCE_PERIOD", res);
            },
            async (error) => {
                const err = await Promise.reject(error);
                commit("SET_LOADING", false);
                commit("SET_ERROR", err);
            }

        )
     }
} 

const getters = { 
    allPerformers(state) {
        return state.performancers;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};