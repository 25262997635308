<template>
  <div>
    <!-- Add Employee Modal -->
    <div id="add_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" data-bs-backdrop="static" role="dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Employee</h5>
            <button
              type="button"
              class="close"
            
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createEmployee">
              <div class="row">
                <div class="col-sm-6">
                  
                  <div class="form-group">
                    <label class="col-form-label"
                      >Employee Number </label
                    >
                    <input class="form-control" type="number" v-model="employee.employee_number" min="0" />
                  </div>
                </div>
                <div class="col-sm-6">
                  
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" v-model="employee.name" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name  <span class="text-danger">*</span></label>
                    <input class="form-control" type="text" v-model="employee.surname" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="email" v-model="employee.email" />
                  </div>
                </div>
              
                <!-- <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">Confirm Password</label>
                  <input class="form-control" type="password" />
                </div>
              </div> -->
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon custompicker">
                      <datepicker
                        v-model="employee.created_at"
                        class="picker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" type="text" v-model="employee.phone" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Position <span class="text-danger">*</span></label>
                    <select class="form-control" v-model="employee.designation_id">
                      <option value="">Select Position</option>
                      <option
                        v-for="item in designations"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.description }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="col-sm-6">
                <div class="form-group">
                  <label class="col-form-label">National ID <span class="text-danger">*</span></label>
                  <input class="form-control" type="number" v-model="employee.national_ID" />
                </div>
              </div>
              </div>
              <div class="submit-section">

             
                <button type="submit" class="btn btn-primary">
                  <div class="spinner-border spinner-border-sm text-light" v-if="loading == true" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                  Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Employee Modal -->

    <!-- Edit Employee Modal -->
    <!-- <div id="edit_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Employee</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Last Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input class="form-control" type="email" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon custompicker">
                      <datepicker class="picker" :editable="true" :clearable="false" />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="col-form-label">Phone </label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Position <span class="text-danger">*</span></label>
                    <select class="form-control">
                      <option value="">Select Position</option>
                      <option
                        v-for="item in designations"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.description }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="submit-section">
                <button type="submit" class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Edit Employee Modal -->

    <!-- Delete Employee Modal -->
    <!-- <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Employee</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a href="javascript:void(0);" class="btn btn-primary continue-btn"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- /Delete Employee Modal -->
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loading : false,
      designations: [],
      employee: {},
      permissions: {
        holidays: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "Holidays",
        },
        leaves: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "Leaves",
        },
        clients: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "clients",
        },
        projects: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "projects",
        },
        tasks: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "tasks",
        },
        chats: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "chats",
        },
        assets: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "assets",
        },
        timesheet: {
          read: 0,
          write: 0,
          create: 0,
          delete: 0,
          import: 0,
          export: 0,
          module_permision: "timesheet",
        },
      },
    };
  },
  created() {
    axios.get("api/designation/").then((response) => {
      this.designations = response.data;
    });
  },
  // mounted() {
  //   // Select 2
  //   if ($(".select").length > 0) {
  //     $(".select").select2({
  //       minimumResultsForSearch: -1,
  //       width: "100%",
  //     });
  //   }
  // },
  methods: {
    ...mapActions('employees2',['getEmployees']), 
    createEmployee() {
      this.loading = true;
      let theObj = Object.assign(this.employee, { permissions: this.permissions });
      axios
        .post("api/employee/user-permissions", theObj)
        .then((response) => {
            this.loading = false

            this.employee = {};
            this.getEmployees();
          $("#add_employee").modal("hide");
        })
        .catch((err) => $("#add_employee").modal("hide"))
        .finally(() => {this.loading = false
          this.employee = {} 
        });
      // axios
      //   .post("https://projects4ai.com/api/employee", this.employee)
      //   .then((response) => this.$router.push({ name: "employee" }))
      //   .catch((err) => console.log(err))
      //   .finally(() => (this.loading = false));
      console.log("The permisions", this.permissions);
      console.log("The employee", this.employee);

      console.log("The final Obj", theObj);
    },
  },
};
</script>
