import Axios from 'axios';
const MAIN_NAMESPACE = "api/competency"
const SUB_NAMESPACE = "api/competency/"
const MAIN_API_URL = `/${MAIN_NAMESPACE}`
const SUB_API_URL = `/${SUB_NAMESPACE}`

class CompetenciesService{

    async getAll() {
        return await Axios.get(MAIN_API_URL, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });
    }

    async createOne(competencyObj){
        return Axios.post(MAIN_API_URL, competencyObj, {
        }).then((response) => {
            return response.data
        }).catch((error) => {
            return error
        });

    }

    async deleteOne(competencyObj){
        return Axios.delete(SUB_API_URL + competencyObj.id,null,{

        }).then((response)=>{
            return response.data
        }).catch((error)=>{
            return error
        })
    }

    async updateOne(competencyObj){
        return Axios.put(SUB_API_URL + competencyObj.id,competencyObj,{

        }).then((response)=>{
            return response.data
        }).catch((error)=>{
            return error
        })
    }

}

export default new CompetenciesService

